import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faUser, faLock, faUsers, faFileLines } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "../../components/AuthContext";
import { useTranslation } from "react-i18next";


export default function PartnerIndex(){
const {t} = useTranslation(["common", "partner"]);
const navigate = useNavigate();
const { partnerGroupName, partnerName, isSuperUser } = useAuth();

function handleNavigate(path){
    navigate(path)
}

    return(
        <div className="partnerindexpage">
            <Helmet>
                <title>{t("partner:myAccount")} | Disko Line {t("greenland")}</title>
            </Helmet>
            <div className="header">
            <p className="headline-1">{t("partner:myAccount")}</p>
            <p className="headline-2">{partnerName}</p>
            <p className="headline-3">{partnerGroupName}</p>
            </div>
            <div className="options">
            <div className="box" onClick={()=>handleNavigate("/partner/account-info")}><FontAwesomeIcon icon={faUser} />{t("partner:accountInformation")}</div>
            <div className="box" onClick={()=>handleNavigate("/partner/change-password")}> <FontAwesomeIcon icon={faLock} />{t("partner:password")}</div>
           {isSuperUser ? <div className="box" onClick={()=>handleNavigate("/partner/user-list")}><FontAwesomeIcon icon={faUsers} />{t("partner:userList")}</div>: ""}
            <div className="box" onClick={()=>handleNavigate("/partner/order-list")}><FontAwesomeIcon icon={faFileLines} /> {t("partner:orderList")}</div>
            </div>
        </div>
    )
}