import Cookies from "js-cookie";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../components/AuthContext";
import config from '../../configs/config';

export default function PartnerChangePassword(){
const {t} = useTranslation(["common", "partner", "toastmesssages"]);
const navigate = useNavigate();
const { checkTokenExpiry } = useAuth();
const [newPassword, setNewPassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const apiUrl = config.default.apiPath;
    const handleSubmit = async (event) => {
        checkTokenExpiry();
        const authToken = Cookies.get('authToken');
        event.preventDefault();
        const shortPassword = t("partner:passwordTooShort")
        const noMatchPassword = t("partner:passwordsDontMatch")
        if (newPassword.length < 5) {
            toast.error(shortPassword);
            return;
          }

        //Both Password Input Must Match
        if (newPassword !== confirmPassword) {
            toast.error(noMatchPassword);
            return;
          }
          const currentSelectedPartner = JSON.parse(Cookies.get("selectedPartnerGroup"));
          const partnerUserID = currentSelectedPartner.partnerUserID;
          const headers = new Headers();
          headers.append('Authorization', `Bearer ${authToken}`);
          const requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
          };
          
          let partnerGroupID, partnerName, partnerTel, partnerStayTel, partnerAccEmail, partnerIsSuper;
          
          try {
            const response = await fetch(`${apiUrl}/Partner/GetPartnerUserByPartnerUserID/${partnerUserID}`, requestOptions);
            
            if (response.ok) {
              const partnerInfo = await response.json();
              partnerGroupID = partnerInfo.partnerGroupID;
              partnerName = partnerInfo.partnerName;
              partnerTel = partnerInfo.partnerTel;
              partnerStayTel = partnerInfo.partnerStayTel;
              partnerAccEmail = partnerInfo?.partnerAccEmail;
              partnerIsSuper = partnerInfo.isSuper;
              
            } else {
              console.error('Error fetching partner information:', response.status);
            }
          } catch (error) {
            console.error('Error fetching partner information:', error);
          }
          
          const partnerEmail = Cookies.get("userEmail");
          const enterNewPassword = newPassword;
          const encryptedPassword = await fetch(`${apiUrl}/User/CreateNewPassword/${enterNewPassword}`).then(result => result.text());
          
          // Check if partnerInfo variables are defined before using them to create the requestBody
          if (partnerGroupID !== undefined && partnerName !== undefined && partnerTel !== undefined && partnerIsSuper !== undefined) {
            const requestBody = {
              id: partnerUserID,
              partnerName: partnerName,
              partnerEmail: partnerEmail,
              partnerPassword: encryptedPassword,
              partnerAccEmail: partnerAccEmail,
              partnerTel: partnerTel,
              partnerStayTel: partnerStayTel,
              partnerGroupID: partnerGroupID,
              isSuper: partnerIsSuper
            };
          
            try {
              const updateResponse = await fetch(`${apiUrl}/Partner/UpdatePartnerUser`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(requestBody)
              });
          
              if (updateResponse.ok) {
                navigate('/partner'); 
                const passSaved = t("toastmessages:passwordSaved");
                toast.success(passSaved);
              } else {
                const changesNotSaved = t("toastmessages:errorSavingChanges");
                toast.error(changesNotSaved);
              }
            } catch (error) {
              console.error("An error occurred while saving changes:", error);
              const errorOccured = t("toastmessages:errorTryAgain");
              toast.error(errorOccured);
            }
          } else {
            console.error("Partner information is incomplete or undefined.");
            const errorFetchPartnerInfo = t("toastmessages:failFetchPartnerInfo");
            toast.error(errorFetchPartnerInfo);
          }
};

    return(
        <div className="partnerchangepassword">
            <Helmet>
                <title>{t("partner:change")} {t("partner:password")} - Partner | Disko Line {t("greenland")}</title>
            </Helmet>
        <h1>{t("partner:change")} {t("partner:password")}</h1>
        <form className="change-password-form">
        <label>
        <div className="label-and-input">
            {t("partner:new")} {t("partner:password")}:
            <input
            required
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
        </label>
        <label>
          <div className="label-and-input">
            {t("partner:confirm")} {t("partner:new")} {t("partner:password")}:
            <input
            required
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </label>
        </form>
        <div className="buttons">
            <button onClick={()=> navigate("/partner")} className="btn-secondary">{t("partner:backToMyAccount")}</button>
            <button onClick={handleSubmit} className="btn">{t("partner:save")}</button>
            </div>
        </div>
    )
}