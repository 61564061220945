import { useTranslation } from "react-i18next";
import logo from "../images/Logo_Disko_Line.png";
import { useEffect, useState } from "react";
import config from '../configs/config';
import { useNavigate } from "react-router-dom";

export default function Footer(){
const [companyName, setCompanyName] = useState("");
const [companyName2, setCompanyName2] = useState("");
const [companyAddress, setCompanyAddress] = useState("");
const [companyAddress2, setCompanyAddress2] = useState("");
const [companyZipCode, setCompanyZipCode] = useState("");
const [companyCity, setCompanyCity] = useState("");
const [companyEmail, setCompanyEmail] = useState("");
const [companyEmail2, setCompanyEmail2] = useState("");
const [companyPhone, setCompanyPhone] = useState("");
const [companyPhone2, setCompanyPhone2] = useState("");
const { t } = useTranslation(["common"]);
const apiUrl = config.default.apiPath;
const navigate = useNavigate();

useEffect(() => {
    fetch(`${apiUrl}/System/GetCompanyInfo`)
        .then(res => res.json())
        .then(data => {
            setCompanyName(data.companyName);
            setCompanyName2(data.companyName2);
            setCompanyAddress(data.companyAddress);
            setCompanyAddress2(data.companyAddress2);
            setCompanyZipCode(data.companyZipCode);
            setCompanyCity(data.companyCity);
            setCompanyEmail(data.companyEmail);
            setCompanyEmail2(data.companyEmail2);
            setCompanyPhone(data.companyPhone);
            setCompanyPhone2(data.companyPhone2);
        });
        // Test comment
}, [apiUrl]);

const handleNavigate = (link) => {
    navigate(link);
  };


    return(
        <div className="footer no-print">
            <footer>
                <div className="footer-logo">
                <img src={logo} alt="Logo"/>
                <p>{t("aPartOf")} <span onClick={() => handleNavigate("/topas-explorer-group")} className="link-text">Topas Explorer Group</span></p>
                </div>
                <div className="contact-info">
                    <div className="contact-info-boxes">
                        <div className="contact-us">
                            <h3>{companyName}</h3>
                            <p>Postbox 305 {companyAddress}</p>
                            <p>{companyZipCode} {companyCity} - {t("greenland")}</p>
                            <p>{t("email")}<a href={"mailto:" + companyEmail}>{companyEmail}</a></p>
                            <p>{t("phone")} <a href={"tel:" + companyPhone}>{companyPhone}</a></p>
                        </div>
                        <div className="contact-us">
                            <h3>{companyName2}</h3>
                            <p>{companyAddress2}</p>
                            <p>{companyZipCode} {companyCity} - {t("greenland")}</p>
                            <p>{t("email")}<a href={"mailto:" + companyEmail2}>{companyEmail2}</a></p>
                            <p>{t("phone")} <a href={"tel:" + companyPhone2}>{companyPhone2}</a></p>
                        </div>
                    </div>
                </div>
                <div className="opening-hours">
                    <h2>{t("openingHours")}</h2>
                    <div className="office-hours">
                    <p>{t("officeHours")}</p>
                    <p>{t("mondayFriday")} 10:00 - 15:30</p>
                    </div>
                    <div className="phone-hours">
                     <p>{t("telephoneHours")}</p>
                     <p>{t("mondayFriday")} 10:00 - 16:00</p>   
                    </div>
                    <p className="link-text"><span onClick={() => handleNavigate("/cookie-policy")}>Cookies</span></p>
                </div>
                <div className="emergency-phone">
                    <h2> {t("emergencyPhone")}</h2>
                    <div className="disclaimer">
                        <p>{t("emergencyPhoneDisclaimer")}</p>
                    </div>
                    <div className="phone-number">
                        <p>{t("phone")} <a href="tel:+299945300">+299 94 53 00</a></p>
                    </div>
                    <div className="emergency-phone-hours">
                    <p>{t("mondayFriday")} 07:00-08:00</p>
                    <p>{t("mondayFriday")} 16:00-19:00</p>
                    <p>{t("saturday")} 07:00-15:00</p>
                    <p>{t("sunday")} 07:00-13:00</p>
                    </div>
                </div>    
            </footer>
        </div>
    )
}