import { Helmet } from "react-helmet";
import headerImage from "../images/headers/Targa.jpg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SystemInfoMessage from "../components/SystemInfoMessage";
//Images
import sectionImage1 from "../images/frontpage/DiskoLine.jpg";
import sectionImage2 from "../images//frontpage/sailingTrips.jpg";
import sectionImage3 from "../images//frontpage/hikingTrips.jpg";
import video from "../videoes/dle-tours/icefiord-cruise.mp4";
import sectionImage4 from "../images//frontpage/icefiord.jpg";
import { useLayoutEffect} from "react";

export default function HomePage() {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "homepage"]);
  const headerStyle = {
    backgroundImage: `url(${headerImage})`,
  };
  useLayoutEffect(() => {
    // Scroll to the top on load
    window.scrollTo(0, 0);
  }, []);
  function handleNavigateToBuyTicket() {
    navigate("/buy-ticket");
  }
  function handleReadMore(activityType) {
    navigate(`/diskolineexplorer/${activityType}`);
  }
  function handleGreenlandbyTopas(){
    window.open("https://www.greenlandbytopas.com", "_blank");
  }

  return (
    <div className="homepage">
      <Helmet>
        <title>Disko Line</title>
      </Helmet>
      <SystemInfoMessage />
      <div className="header" style={headerStyle}>
        <div className="header-content">
          <div className="header-tag">
            <h2>{t("homepage:passengerTrafficIn")}</h2>
            <h2 className="bolder">{t("greenland")}</h2>
          </div>
          <button className="btn" onClick={handleNavigateToBuyTicket}>
            {t("buyTicket")}
          </button>
        </div>
      </div>
      <div className="section-1 homepage-section">
        <div className="section-text">
          <p className="section-tag">{t("homepage:section1header")}</p>
          <h1>{t("diskoLine")}</h1>
          <p>{t("homepage:section1text")}</p>
          <button className="btn" onClick={handleNavigateToBuyTicket}>
            {t("buyTicket")}
          </button>
        </div>
        <div className="section-image">
          <img src={sectionImage1} alt="About Disko Line" />
        </div>
      </div>
      <div className="section-2 homepage-section">
        <div className="center-text">
          <p className="section-tag">{t("diskoLineExplorer")}</p>
          <h2>{t("homepage:guidedTours")}</h2>
        </div>
        <div className="sub-section">
          <div className="section-image">
            <img src={sectionImage2} alt="Sailing Trips" />
          </div>
          <div className="section-text">
            <p className="section-tag">{t("diskoLineExplorer")}</p>
            <h3>{t("homepage:sailingTrips")}</h3>
            <p>{t("homepage:sailingTripsText")}</p>
            <button
              className="btn"
              onClick={() => handleReadMore("sailing-trips")}
            >
              {t("readMore")}
            </button>
          </div>
        </div>
        <div className="sub-section">
          <div className="section-image">
            <img src={sectionImage3} alt="Hiking" />
          </div>
          <div className="section-text">
            <p className="section-tag">{t("diskoLineExplorer")}</p>
            <h3>{t("homepage:hiking")}</h3>
            <p>{t("homepage:hikingText")}</p>
            <button
              className="btn"
              onClick={() => handleReadMore("hiking-trips")}
            >
              {t("readMore")}
            </button>
          </div>
        </div>
      </div>
      <div className="section-3 homepage-section">
        <video autoPlay loop>
          <source src={video} type="video/mp4"></source>
        </video>
      </div>
      <div className="section-4 homepage-section">
        <div className="section-text">
          <p className="section-tag">Greenland by Topas</p>
          <h2>{t("homepage:roundTripsInGreenland")}</h2>
          <p>{t("homepage:roundTripsInGreenlandText")}</p>
          <button className="btn" onClick={handleGreenlandbyTopas}>{t("readMore")}</button>
        </div>
        <div className="section-image">
          <img src={sectionImage4} alt="Greenland By Topas" />
        </div>
      </div>
    </div>
  );
}
