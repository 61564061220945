import { createContext, useContext, useState } from "react";
import Cookies from 'js-cookie';
import config from '../configs/config';

const CartContext = createContext();

export function CartProvider({ children }) {
  const [cartItems, setCartItems] = useState(0);
  const [ticketQuantities, setTicketQuantities] = useState({});
  const apiUrl = config.default.apiPath;
 
  async function fetchCartItems() {
    const sessionID = Cookies.get('sessionID');
    const response = await fetch(
      `${apiUrl}/Cart/GetCartItems?sessionID=${sessionID}`
    );
    const data = await response.json();
    const updatedTicketQuantities = {}; // Temporary object to calculate quantities

    data.cartItems.forEach((item) => {
      item.ticketTypes.forEach((ticketType) => {
        if (updatedTicketQuantities[ticketType.ticketTypeName]) {
          updatedTicketQuantities[ticketType.ticketTypeName] +=
            ticketType.quantity;
        } else {
          updatedTicketQuantities[ticketType.ticketTypeName] =
            ticketType.quantity;
        }
      });
    });

    setTicketQuantities(updatedTicketQuantities); // Update the ticket quantities

    // Calculate the total quantity of all items
    const totalQuantity = data.cartItems.reduce(
      (total, item) => total + item.ticketTypes.reduce((subtotal, ticketType) => subtotal + ticketType.quantity, 0),
      0
    );

    setCartItems(totalQuantity);
  }
  

  return (
    <CartContext.Provider value={{ cartItems, fetchCartItems, ticketQuantities }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}
