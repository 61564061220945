import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from '../../configs/config';
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import utilities from '../../utilities/Utilities'
import enums from '../../commons/Enums'
import Cookies from "js-cookie";
import { useAuth } from "../../components/AuthContext";
import { faFileCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function PublicTimetableAreaSelected() {
  const [t, i18n] = useTranslation(["common", "practicalinfo"]);
  const navigate = useNavigate();
  const [publicTimetable, setPublicTimetable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [area, setArea] = useState({});
  const apiUrl = config.default.apiPath;
  const { languagesToIndex, languagesToIndexCalendar } = enums;

  let today = new Date();
  const params = useParams();
  const areaSlug = params.id;
  const { checkTokenExpiry } = useAuth();

  async function getPartnerOrderList() {

    setIsLoading(true);
    const partner = Cookies.get("selectedPartnerGroup");
    const authToken = Cookies.get("authToken");

    let partnerGroupID = 0;
    if (partner != null) {
      const selectedPartnerGroup = JSON.parse(partner);
      partnerGroupID = selectedPartnerGroup.partnerGroupID;
      await checkTokenExpiry();

      if (!authToken) {
        throw new Error("Authentication token not found in cookies");
      }
    }

    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json", // Set the content type to JSON
    };

    const requestBody = JSON.stringify({
      partnerGroupID: partnerGroupID,
      areaID: areaSlug,
      date: selectedDate,
    });
    const response = await fetch(`${apiUrl}/Timetable/GetPublicTimetable`, {
      method: "POST", // Use POST method to send data
      headers,
      body: requestBody
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
    const data = await response.json();
    // Format orderDate for each order to display only the date portion
    const formattedData = data.map((order) => ({
      ...order,
      departureDate: utilities.formatDate(order.departureDate, 'dd-MM-yyyy'),
      departureTime: utilities.formatDate(order.departureTime, 'HH:mm'),
      arrivalTime: utilities.formatDate(order.arrivalTime, 'HH:mm'),
    }));
    setPublicTimetable(formattedData);
    setIsLoading(false);
  }

  useEffect(() => {
    getPartnerOrderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/Area/GetArea/${areaSlug}`)
      .then((response) => response.json())
      .then((data) => {
        setArea(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    getPartnerOrderList();
  };
  const handleGoBack = () => {
    navigate("/practical-information/timetable");
  }
  const handleChangeDate = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className="timetablepage partnerorderlist">
      <Helmet>
        <title>{t("publicTimetable")} | Disko Line Greenland</title>
      </Helmet>
      <div className="select-area-header">
        <h1>{t("publicTimetable")}</h1>
        <h3 className="text-center">{area.areaTranslations && area.areaTranslations[languagesToIndex[i18n.language]]?.areaName}</h3>
        <p className="select-area-description">{t("practicalinfo:selectAreaDescription")}</p>
      </div>
      <div className="filter-form">
        <div className="label-and-input">
          <label htmlFor="date">{t("practicalinfo:date")}:</label>
          <DatePicker
            id="date"
            name="departureDate"
            autoComplete="off"
            selected={selectedDate}
            onChange={handleChangeDate}
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            locale={languagesToIndexCalendar[i18n.language]}
            calendarStartDay={1}
            minDate={today}
          />

        </div>
        <button className="btn" onClick={handleSearch}>{t("search")}</button>
      </div>

      { !isLoading ? (
        <>
        <table className="order-table">
        <thead>
          <tr>
            <th>
              <p>{t("practicalinfo:from")}</p>
            </th>
            <th>
              <p>{t('practicalinfo:to')}</p>
            </th>
            <th>
              <p>{t("practicalinfo:departureDate")}</p>
            </th>
            <th>
              <p>{t("practicalinfo:departureTime")}</p>
            </th>
            <th>
              <p>{t("practicalinfo:arrivalTime")}</p>
            </th>
            <th>
              <p>{t("practicalinfo:ticketAvailable")}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {publicTimetable.map((activeDeparture, index) => (
            <tr key={index}>
              <td>{activeDeparture.departureStopName}</td>
              <td>{activeDeparture.arrivalStopName}</td>
              <td class="text-center">{activeDeparture.departureDate}</td>
              <td class="text-center">{activeDeparture.departureTime}</td>
              <td class="text-center">{activeDeparture.arrivalTime}</td>
              <td class="text-center">{activeDeparture.ticketAvailable}</td>
            </tr>
          ))}
        </tbody>
      </table>

      { publicTimetable.length === 0 && (
        <div>
          <div className="text-center">
            <FontAwesomeIcon className="no-data-icon" icon={faFileCircleExclamation}></FontAwesomeIcon>
          </div>
          <h3 className="no-data-text">{t("noData")}</h3>
        </div>
      ) }
      </>
      ) : (
        <h3 className="text-center"> Loading ...</h3>
      )}

      <div className="button-center">
        <button className="btn-secondary" onClick={handleGoBack}>{t("practicalinfo:backToAreas")}</button>
      </div>
    </div>
  )
}