import { useEffect, useState } from "react"
import { useNavigate } from "react-router";
import BuyTicketTabs from "../../components/BuyTicketTabs";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import { useAuth } from "../../components/AuthContext";
import { useLayoutEffect } from "react";
import config from '../../configs/config';
import enums from '../../commons/Enums'

export default function CustomerCartSummary(){
    const {t, i18n} = useTranslation(["common", "buyticket", "toastmessages"]);
    const [billingInformation, setBillingInformation] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const [itemFees, setitemFees] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [hasInvoice, setHasInvoice] = useState(false);
    const [clicked, setClicked] = useState(false);
    const {authenticated} = useAuth();
    const [orderReferenceCode, setOrderReferenceCode] = useState("");
    const navigate = useNavigate();
    const apiUrl = config.default.apiPath;
    const { languagesToIndex } = enums;
    useLayoutEffect(() => {
      // Scroll to the top on load
      window.scrollTo(0, 0); 
    }, []);
    
    useEffect(() => {
      async function GetCartSummary(){
        const sessionID = Cookies.get('sessionID');
        const response = await fetch(
            `${apiUrl}/Cart/GetCartSummary?sessionID=${sessionID}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'text/plain',
                'LanguageID': languagesToIndex[i18n.language],
              },
            }
          );
          const data = await response.json();
          setBillingInformation(data.billingInformation);
          setCartItems(data.cartItems);
          setitemFees(data.fees);
          setTotalPrice(data.totalPrice);
          
        }
        GetCartSummary();
    }, [i18n.language, languagesToIndex, apiUrl]);

    function handleGoBack(){
    navigate("/buy-ticket/informations")
    }
    const formatNumber = (number) => {
      // Convert number to string and split into integer and decimal parts
      const parts = number.toFixed(2).toString().split('.');
      // Replace dot with comma as decimal separator
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      // Return formatted number
      return parts.join(',');
    };
   async function getPartnerHasInvoice(){
    if(authenticated){
      const authToken = Cookies.get("authToken");
      if (!authToken) {
        throw new Error("Authentication token not found in cookies");
      }
      const selectedPartnerGroup = JSON.parse(Cookies.get("selectedPartnerGroup"));
      const partnerGroupID = selectedPartnerGroup.partnerGroupID;
      const headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(`${apiUrl}/Partner/GetPartnerGroupByPartnerGroupID/${partnerGroupID}`, {
        method: "GET",
        headers,
      });
      const data = await response.json();
      setHasInvoice(data.partnerInvoiceAllowed);
    }
   }
   useEffect(() => {
     getPartnerHasInvoice();
   })
    async function updateOrderReferenceCode () {
   
        // Make the API call to update the reference code for the order
        const authToken = Cookies.get("authToken");
        const selectedPartnerGroup = JSON.parse(Cookies.get("selectedPartnerGroup"));
        const partnerGroupID = selectedPartnerGroup.partnerGroupID;
        if (!authToken) {
          throw new Error("Authentication token not found in cookies");
        }
    
        const headers = {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        };
    
        const requestBody = {
          orderID: billingInformation.id, 
          referenceCode: orderReferenceCode,
          partnerGroupID: partnerGroupID,
        };
        const response = await fetch(`${apiUrl}/Partner/UpdateOrderReferenceCode`, {
          method: "POST",
          headers,
          body: JSON.stringify(requestBody),
        });
    
        if (!response.ok) {
          throw new Error(`Failed to update reference code: ${response.statusText}`);
        }
      };

    


//GENERATE PAYMENT LINK
async function generatePaymentLink(){
const orderID = billingInformation.id
const response = await fetch(`${apiUrl}/Cart/CreatePaymentLink?orderID=${orderID}`)
const data = await response.text();
return data;
}

async function handleContinue(){
  const sessionID = Cookies.get('sessionID');
  //CHECK SALE VALID
  const response = await fetch(
    `${apiUrl}/Cart/CheckSaleValid?sessionID=${sessionID}`
  )
  const data = await response.json()
  if(data.success === true){
    const paymentlink = await generatePaymentLink();
    window.location.href = paymentlink;
  } else {
    navigate("/my-cart");
    const saleValidError = t("toastmessages:checkSaleValid");
    toast.error(saleValidError);
  }
}
async function handleNavInvoice(){
  if (clicked) {
    return;
  }
  setClicked(true); 
  const authToken = Cookies.get("authToken");
  const selectedPartnerGroup = JSON.parse(Cookies.get("selectedPartnerGroup"));
  const partnerGroupID = selectedPartnerGroup.partnerGroupID;
  
  try
  {
    const requestBody = {
      orderID: billingInformation.id, 
      partnerGroupID: partnerGroupID,
    };
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${apiUrl}/Cart/PaymentWithInvoice`, {
    method: "POST",
    headers,
    body: JSON.stringify(requestBody),
  });
  const data = await response.json();
  console.log(data);
  if(data.success === true){
    const verifyCode = data.data.verifyCode;
    navigate(`/buy-ticket/payment?success=True&errorMsg=&orderID=${billingInformation.id}&verifyCode=${verifyCode}`);
  }
  else {
    navigate(`/buy-ticket/payment?success=false&errorMsg=${data.errorMessage}`);
  }
  }
  catch (error) {
    console.log(error.message || t("errorMessages.genericError"));
  }
  finally {
    setClicked(false);
  }
}

    return(
        <div className="customercartsummarypage">
          <Helmet>
            <title>{t("buyticket:summary")} | Disko Line {t("greenland")}</title>
          </Helmet>
            <BuyTicketTabs activeTab={4} />
            <div className="summaryform">
            <h2>{t("buyticket:summary")}</h2>
            <div className="billing-information">
            <div className="display-inline">
            <h3 className="billing-address-title">{t("buyticket:billingAddress")}</h3>
            <p>{billingInformation.orderCustomerName}</p>
            <p>{billingInformation.orderCustomerAddress}</p>
            <p>{billingInformation.orderCustomerZip} {billingInformation.orderCustomerCity}</p>
            <p>{billingInformation.orderCustomerCountry}</p>
            <div className="email-and-phone">
            <p>{billingInformation.orderCustomerEmail}</p>
            <p>{billingInformation.orderCustomerPhone}</p>
            </div>
            </div>
            </div>
            <hr></hr>
            <div className="order">
                <h3>{t("order")}</h3>
                {cartItems.map((item)=>(
                    <div className="order-container">
                      <div className="order-journey-header">
                    <p className="date"> {new Date(item.departureDate).toLocaleDateString("da-DK", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                              </p>
                              <div className="departure-arrival-names">
                              <p className="departure-stop-name">
                              {item.departureStopName}
                              </p>
                {item.isActivity ? (
                  null
                  ) : (
                    <>
                    <p className="divider">-</p>
                    <p className="arrival-stop-name">
            {item.arrivalStopName}
          </p>
                    </>
        )}
        </div>
        <div className="departure-and-arrival-times">
                              <p className="departure-time">
                            {item.departureTime.slice(11, 16)}
                              </p>
               <p className="divider">-</p>
                <p className="arrival-time">
                {item.arrivalTime.slice(11,16)}
                </p>
                </div>
                <p className="price">
                  {formatNumber(item.totalPrice)} DKK
                </p>
                </div>
                {item.ticketTypes.map((ticketType)=>(
                  <div className="ticket-types">
                    <p>{ticketType.ticketTypeName}</p>
                    <p>{ticketType.participantName}</p>
                  </div>
                ))}
                    </div>
                ))}
               {itemFees.map((fee) => (
                fee.price > 0 && (
                <div className="fees" key={fee.name}>
                <p className="fee-name">{fee.name}</p>
                <p className="fee-price">{formatNumber(fee.price)} DKK</p>
                  </div>
                      )
                  ))}
            </div>
            <div className="total-price">
            <p className="total-price-text">{t("buyticket:priceToPayNow")}:</p>
             <p className="total-price-number">{formatNumber(totalPrice)} DKK</p>
             </div>
             <div className="price-includes-fees">
             <p >{t("buyticket:priceIncludesFees")}</p>
             </div>
              <div className="terms-and-conditions">
            <div className="terms-and-conditions-checkbox">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <p>
           {t("buyticket:iHaveReadAndAccepted")}{" "}
            <a
              href="/practical-information/befordringsbestemmelser"
              target="_blank"
              className="terms-and-condition-link"
            >
              {t("buyticket:termsOfTradeAndDelivery")}
            </a>
          </p>
        </div>
        </div>
        {authenticated && (
          <div className="order-reference-code">
            <div className="label-and-input">
            <label htmlFor="orderReferenceCode">{t("buyticket:orderReferenceCode")}</label>
            <input
              type="text"
              id="orderReferenceCode"
              value={orderReferenceCode}
              onChange={(e) => setOrderReferenceCode(e.target.value)}
              onBlur={updateOrderReferenceCode} // Attach onBlur event handler
            />
            </div>
          </div>
        )}
        <div className="buttons">
          {/* BUTTONS CONDITIONS */}
            {hasInvoice ? (
              <div className="buttons-invoice">
            <button onClick={handleGoBack} className="btn">{t('buyticket:back')}</button>
            <div className="payment-buttons">
          <button onClick={handleNavInvoice} disabled={!isChecked} className="btn continue invoice-button">
            {t("buyticket:invoice")}
          </button>
          <button onClick={handleContinue} disabled={!isChecked} className="btn continue">{t('buyticket:cardPayment')}</button>
              </div>
              </div>
        ) : 
        <div className="buttons-no-invoice">
        <button onClick={handleGoBack} className="btn back">{t('buyticket:back')}</button>
        <button onClick={handleContinue} disabled={!isChecked} className="btn continue">{t('buyticket:continue')}</button>
          </div>}
            
        </div>
            </div>
        </div>
    )
}