import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";
import config from '../../configs/config';
import { useLocation } from 'react-router-dom';
import MyJourneyDescription from "../../components/MyJourneyDescriptions";

import enums from '../../commons/Enums'

export default function MyTicket() {
  const { t, i18n } = useTranslation(['common', 'buyticket']);
  const [orderID, setOrderID] = useState("");
  const [ticketID, setTicketID] = useState("");
  const [ticketData, setTicketData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const apiUrl = config.default.apiPath;
  const { languagesToIndex } = enums;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    async function checkLoadTickets() {
      let orderID = queryParams.get("OrderID");
      let verifyCode = queryParams.get("VerifyCode");
      if (orderID != null && verifyCode != null) {
        loadTickets(orderID, null, verifyCode);
      }
    }
    checkLoadTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function loadTickets(orderID, ticketID, verifyCode) {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(
        `${apiUrl}/Cart/SearchActiveTicket?OrderID=${orderID}&TicketID=${ticketID ?? 0}&VerifyCode=${verifyCode ?? ''}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'LanguageID': languagesToIndex[i18n.language]
        },
      }
      );

      if (!response.ok) {
        // If the response status is not ok, handle the error
        throw new Error(t("errorMessages.genericError"));
      }

      const data = await response.json();

      if (data.length === 0) {
        // If the response is an empty array, display an error message
        setError(t("errorMessages.ticketNotFound"));
        setTicketData(null); // Clear any previous ticket data
      } else {
        // If data is found, set it to the state
        setTicketData(data);
      }
    } catch (error) {
      // Handle any network errors or other issues here
      setError(error.message || t("errorMessages.genericError"));
    } finally {
      setLoading(false);
    }

  }
  const handleSearch = async (e) => {
    e.preventDefault();
    loadTickets(orderID, ticketID, null);
  };
  function handleNavigateToFrontPage() {
    navigate("/");
  }
  const handleNumericInputChange = (e, setter) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    setter(numericValue);
  };

  const renderForm = () => {
    let orderID = queryParams.get("OrderID");
    if (!ticketData && orderID == null && !loading) {
      return (
        <form onSubmit={handleSearch}>
          <div>
            <label>
              <div className="label-and-input">
                {t("orderID")}:
                <input
                  type="text"
                  value={orderID}
                  onChange={(e) => handleNumericInputChange(e, setOrderID)}
                />
              </div>
            </label>
          </div>
          <div>
            <label>
              <div className="label-and-input">
                {t("ticketID")}:
                <input
                  type="text"
                  value={ticketID}
                  onChange={(e) => handleNumericInputChange(e, setTicketID)}
                />
              </div>
            </label>
          </div>
          <div className="button-center">
            <button className="btn search" type="submit">{t("search")}</button>
          </div>
        </form>
      );
    }
  };

  return (
    <div className="myticketpage">
      <Helmet>
        <title>{t("myTicket")} | Disko Line {t("greenland")}</title>
      </Helmet>
      <h1>{t("myTicket")}</h1>
        <MyJourneyDescription Id="1" />
      {renderForm()}
      {loading && <Spinner width="20%" />}
      {error && <p className="error">{error}</p>}
      {ticketData && ticketData.length > 0 && (
        <div className="journey-info">
          <h2>{t("journey")}</h2>
          <table>
            <thead>
              <tr>
                <th className="description">{t('ticketID')}</th>
                <th className="description">{t('date')}</th>
                <th className="description">{t('time')}</th>
                <th className="description">{t('route')} - {t('activity')}</th>
              </tr>
            </thead>
            <tbody>
              {ticketData.map((ticketData) => (
                <tr>
                  <th><p>{ticketData.id}</p></th>
                  <th><p>{new Date(ticketData.departureDate).toLocaleDateString("da-DK", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}</p></th>
                  <th> <p>{ticketData.departureTime.slice(11, 16)} - {ticketData.arrivalTime.slice(11, 16)}</p></th>
                  {ticketData.isActivity ? (
                    <th><p>{ticketData.departureStopName}</p></th>) : (
                    <th><p>{ticketData.departureStopName}  - {ticketData.arrivalStopName} </p></th>
                  )
                  }
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={handleNavigateToFrontPage} className="btn go-back">{t("buyticket:successGoToFrontPage")}</button>
        </div>
      )
      }
    </div>
  );
}
