import { createContext, useContext, useState } from "react";


const FormContext = createContext({
  formRequest: {
    departureStopID: 0,
    arrivalStopID: 0,
    departureDate: "",
    numberTicketTypes: [],
    page: 0,
    isTransfer: null,
    isSearchEarlier: false,
    isSearchActivity: false,
  },
  setFormRequest: () => {},
});


export function FormProvider({ children }) {
  const [formRequest, setFormRequest] = useState({
    departureStopID: 0,
    arrivalStopID: 0,
    departureDate: "",
    numberTicketTypes: [],
    page: 0,
    isTransfer: null,
    isSearchEarlier: false,
    isSearchActivity: false,
  });


  return (
    <FormContext.Provider value={{ formRequest, setFormRequest }}>
      {children}
    </FormContext.Provider>
  );
}


export function useForm() {
  return useContext(FormContext);
}
