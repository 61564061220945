  import { Link, NavLink, useNavigate } from "react-router-dom";
  import logo from "../images/Logo_Disko_Line neg.png";
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import LanguageSelector from "./LanguageSelector";
  import { faCartShopping } from '@fortawesome/free-solid-svg-icons'
  import { useEffect, useRef, useState} from "react";
  import { useCart } from "./CartContext";
  import { useAuth } from "./AuthContext";
  import { useTranslation } from "react-i18next";
  import Cookies from "js-cookie";
  import config from '../configs/config';
  // import { mapLanguageCodeToId } from './LanguageUtils';


  export default function Navigation({ name }){
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["common", "areas"]);
  const { authenticated, setAuthenticated, partnerGroupName } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(authenticated);
  const { cartItems, fetchCartItems } = useCart();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 810);
  const [aboutDropdownVisible, setAboutDropdownVisible] = useState(false);
  const [practicalDropdownVisible, setPracticalDropdownVisible] = useState(false);
  const [journeyDropdownVisible, setJourneyDropdownVisible] = useState(false);
  const [diskoLineExplorerDropdownVisible, setDiskoLineExplorerDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const isGreenlandic = i18n.language === 'gl';
  const hoverlineClass = isGreenlandic ? 'hoverline greenlandic' : 'hoverline';
  const cartClass = isGreenlandic ? 'cart greenlandic' : 'cart';
  const apiUrl = config.default.apiPath;
  // const [data, setData] = useState([]);
  // const [, setLoading] = useState(true);
  // const [, setError] = useState(null);
  function toggleDropdown() {
    setDropdownVisible(!dropdownVisible);
  }
    const handlePartnerOptions = () => {
    if (authenticated) {
      setDropdownVisible(!dropdownVisible);
    } else {
      navigate('/partner');
    }
  };
  useEffect(() => {
    setIsLoggedIn(authenticated);
  }, [authenticated]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  function toggleDiskoLineExplorerDropdown() {
  setDiskoLineExplorerDropdownVisible(!diskoLineExplorerDropdownVisible);
}
  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }
  function closeMenu(){
    setMenuOpen(false);
  }
  const recalculateCustomerCart = async () => {
    try {
      const sessionID = Cookies.get('sessionID');
      const response = await fetch(
        `${apiUrl}/Cart/RecalculateCustomerCart?sessionID=${sessionID}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        // Handle success if needed
        console.log('Customer cart recalculated successfully after sign out.');
      } else {
        // Handle error if needed
        console.error('Failed to recalculate customer cart after sign out.');
      }
    } catch (error) {
      console.error('An error occurred while recalculating customer cart after sign out:', error);
    }
  };

  const fetchUser = async () => {
    const selectedPartnerGroup = JSON.parse(Cookies.get('selectedPartnerGroup'));
    const partnerGroupID = selectedPartnerGroup.partnerGroupID;
    const email = Cookies.get("userEmail");
    const authToken = Cookies.get('authToken');

    const response = await fetch(`${apiUrl}/Partner/GetPartnerUserByEmailAndPartnerGroupID/${email}/${partnerGroupID}`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${authToken}`,
      },
    });
  
    if (!response.ok) {
      throw new Error(`Error fetching partner user: ${response.statusText}`);
    }
  
    return await response.json();
  };

  const logUserActivity = async (partnerUserID) => {
    const authToken = Cookies.get('authToken');
    const newLogEntry = {
      PartnerUserID: partnerUserID,
      LogType: 2,
      LogDescription: "Logging for Event PartnerUser logout",
      CreatedDate: new Date().toISOString(),
    }
    const response = await fetch(`${apiUrl}/Partner/AddPartnerUserLog`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authToken}`,
      },
      body: JSON.stringify(newLogEntry),
    });
  
    if (!response.ok) {
      throw new Error(`Error logging partner user activity: ${response.statusText}`);
    }
  
    return await response.json();
  };

  const handleActivityLogging = async () => {

    try {
      console.log("Fetching user...");
      const userData = await fetchUser();
      console.log("User fetched:", userData);

      if (userData && userData[0].id) {
        console.log("Logging user activity...");
        const partnerUserID = userData[0].id;
        const logData = await logUserActivity(partnerUserID);
        console.log("Partner Log successfully updated", logData);
      } else {
        console.log("No partner user found to log activity");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };


 async function handleSignOut() {
    await recalculateCustomerCart();
    await handleActivityLogging();
    setAuthenticated(false); 
    Cookies.remove("authToken");
    Cookies.remove("userEmail");
    Cookies.remove("selectedPartnerGroup");
    Cookies.remove("tokenExpiry");
    Cookies.remove("refreshToken");
    navigate("/partner/login");
    window.location.reload(); 
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 810);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    async function getCartItems(){
      await fetchCartItems();
    }
    getCartItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function handleBuyTicket(){
    closeMenu();
    navigate("/buy-ticket");
  }

  function handleGoToCart(){
    closeMenu();
    navigate("/my-cart");
  }

  function handleNavLinkClick() {
    if (isMobile) {
      closeMenu();
    }
  }

  // useEffect(() => {
  //   const fetchPage = async () => {
  //     try {
  //       const response = await fetch(
  //         `${apiUrl}/Umbraco/GetUmbracoPages`
  //       );
  //       if (!response.ok) {
  //         throw new Error(`Failed to fetch data: ${response.statusText}`);
  //       }
  //       const jsonData = await response.json();

  //       const filteredItems = [
  //         ...new Map(jsonData.items.map((item) => [item.id, item])).values(),
  //       ]; 

  //       setData(filteredItems);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchPage();
  // }, [apiUrl]);

  // const languageCode = mapLanguageCodeToId(i18n.language);
  // const languagePath = languageCode === 2 ? 'en' : languageCode === 3 ? 'gl' : '';



      return(
        <div className={`navigation-menu ${menuOpen ? "menu-open" : ""} no-print`}>
        <nav>
        {(isMobile) ? (
            <div className="mobile-nav">
            <div className="mobile-cart">
                <button className={cartClass} onClick={handleGoToCart}>
                  <FontAwesomeIcon icon={faCartShopping} />
                  ({cartItems})
                </button>
                </div>
              <div className="mobile-buy-ticket">
                  <button className="btn" onClick={handleBuyTicket}>
                  {t("buyTicket")}
                </button>
                </div>
            <div className="burger-menu" onClick={toggleMenu}>
              &#9776; 
              </div>

      </div>
            ) : null
            }
          <div
            className={`navigation-lower ${menuOpen ? "menu-open" : ""}`}
          >
            
            <div className="flex-left">
              <Link to="/" className="linktohomepage" onClick={handleNavLinkClick}>
                <img className="logo" src={logo} alt="Startside" />{" "}
              </Link>
            </div>
            <div className="flex-right">
            {!isMobile && (
              <>
              <div
                className={`dropdown ${
                  aboutDropdownVisible ? "active" : ""
                }`}
              >
                <div
                  className="dropbtn"
                  onClick={() =>
                    setAboutDropdownVisible(!aboutDropdownVisible)
                  }
                >
                  <button className="dropdownarrow">
                    <span className={hoverlineClass}>
                      {t("aboutDiskoLine")}
                    </span>
                    <span className="nav-arrow">▼</span>
                  </button>
                  <div className="dropdown-content">
                    <NavLink to="/om-disko-line/om-os" onClick={handleNavLinkClick}>
                      {t("aboutUs")}
                    </NavLink>
                    <NavLink to="/om-disko-line/flaaden" onClick={handleNavLinkClick}>
                      {t("fleet")}
                    </NavLink>
                    <NavLink to="/om-disko-line/charter" onClick={handleNavLinkClick}>
                      {t("charter")}
                    </NavLink>
                    <NavLink to="/om-disko-line/csr-politik" onClick={handleNavLinkClick}>
                      {t("csrPolicy")}
                    </NavLink>
                    <NavLink to="/om-disko-line/ledige-stillinger" onClick={handleNavLinkClick}>
                      {t("availablePositions")}
                    </NavLink>
                  </div>
                </div>
              </div>
                            {/* DISKOLINE EXPLORER */}
                            <div className={`dropdown ${diskoLineExplorerDropdownVisible ? 'active' : ''}`}>
  <div className="dropbtn" onClick={toggleDiskoLineExplorerDropdown}>
    <button className="dropdownarrow">
      <span className={hoverlineClass}>
        {t("diskoLineExplorer")}
      </span>
      <span className="nav-arrow">▼</span>
    </button>
  </div>

  {/* The content inside the dropdown */}
  <div className="dropdown-content">
    <div className="sub-menu">
      <ul>
        {/* First sub-menu item */}
        <li className="sub-menu-item">
          <NavLink to="diskolineexplorer/disko-bay" onClick={handleNavLinkClick}>
            {t("theArea")} <span className="nav-arrow">{isMobile ? '' : '▶'}</span>
          </NavLink>
          <div className="right-sided-submenu">
            <ul>
              {/* {data.map((item) => (
                <li key={item.id}>
                  <NavLink to={`diskolineexplorer${item.route.path}${languagePath}`}>
                    {item.name}
                  </NavLink>
                </li>
              ))} */}

              {/* Additional static sub-menu items */}
              <li>
                <NavLink to="diskolineexplorer/Ilulissat" onClick={handleNavLinkClick}>
                  {t("Ilulissat")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/Ilimanaq" onClick={handleNavLinkClick}>
                  {t("Ilimanaq")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/EquipSermia" onClick={handleNavLinkClick}>
                  {t("Eqip Sermia")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/Qeqertarsuaq" onClick={handleNavLinkClick}>
                  {t("Qeqertarsuaq")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/Qasigiannguit" onClick={handleNavLinkClick}>
                  {t("Qasigiannguit")}
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/* Second sub-menu item */}
        <li className="sub-menu-item">
          <NavLink to="diskolineexplorer/sailing-trips" onClick={handleNavLinkClick}>
            {t("sailingTrips")} <span className="nav-arrow">{isMobile ? '' : '▶'}</span>
          </NavLink>
          <div className="right-sided-submenu">
            <ul>
              <li>
                <NavLink to="diskolineexplorer/whale-safari" onClick={handleNavLinkClick}>
                  {t("whaleSafari")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/daytrip-to-eqip-sermia" onClick={handleNavLinkClick}>
                  {t("dayTripToEqipSermia")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/fishing-trip" onClick={handleNavLinkClick}>
                  {t("fishingTrip")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/icefiord-cruise" onClick={handleNavLinkClick}>
                  {t("icefiordCruise")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/northern-lights" onClick={handleNavLinkClick}>
                  {t("northernLights")}
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/* Third sub-menu item */}
        <li className="sub-menu-item">
          <NavLink to="diskolineexplorer/hiking-trips" onClick={handleNavLinkClick}>
            {t("hikingTrips")} <span className="nav-arrow">{isMobile ? '' : '▶'}</span>
          </NavLink>
          <div className="right-sided-submenu">
            <ul>
              <li>
                <NavLink to="diskolineexplorer/citywalk-in-ilulissat" onClick={handleNavLinkClick}>
                  {t("cityWalkInIlulissat")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/sermermiut-hike" onClick={handleNavLinkClick}>
                  {t("sermermiutHike")}
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
              {/* Practical Information */}
              <div
                className={`dropdown ${
                  practicalDropdownVisible ? "active" : ""
                }`}
              >
                <div
                  className="dropbtn"
                  onClick={() =>
                    setPracticalDropdownVisible(!practicalDropdownVisible)
                  }
                >
                  <button className="dropdownarrow">
                    <span className={hoverlineClass}>
                      {t("practicalInformation")}
                    </span>
                    <span className="nav-arrow">▼</span>
                  </button>
                  <div className="dropdown-content">
                    <NavLink to="/practical-information/raad-og-vejledning" onClick={handleNavLinkClick}>
                      {t("adviceAndGuidance")}
                    </NavLink>
                    <NavLink to="/practical-information/befordringsbestemmelser" onClick={handleNavLinkClick}>
                      {t("carriageRegulations")}
                    </NavLink>
                    <NavLink to="/practical-information/irregularitet" onClick={handleNavLinkClick}>
                      {t("irregularity")}
                    </NavLink>
                    <NavLink to="/practical-information/praise-and-criticism" onClick={handleNavLinkClick}>
                      {t("praiseAndCriticism")}
                    </NavLink>
                    <NavLink to="/practical-information/timetable" onClick={handleNavLinkClick}>
                      {t("publicTimetable")}
                    </NavLink>
                  </div>
                </div>
              </div> 
                {/* Min Rejse */}
                <div
                className={`dropdown ${
                  journeyDropdownVisible ? "active" : ""
                }`}
              >
                <div
                  className="dropbtn"
                  onClick={() =>
                    setJourneyDropdownVisible(!journeyDropdownVisible)
                  }
                >
                  <button className="dropdownarrow">
                    <span className={hoverlineClass}>
                      {t("myJourney")}
                    </span>
                    <span className="nav-arrow">▼</span>
                  </button>
                  <div className="dropdown-content">
                    <NavLink to="/my-journey/my-ticket" onClick={handleNavLinkClick}>
                      {t("myTicket")}
                    </NavLink>
                    <NavLink to="/my-journey/cancel-ticket" onClick={handleNavLinkClick}>
                      {t("cancelTicket")}
                    </NavLink>
                    <NavLink to="/my-journey/change-phonenr" onClick={handleNavLinkClick}>
                      {t("changePhoneNo")}
                    </NavLink>
                  </div>
                </div>
              </div>
              <NavLink to="/contact" className="dropdownarrow" onClick={handleNavLinkClick}>
                <span className={hoverlineClass}>{t("contact")}</span>
              </NavLink>
                <button className={cartClass} onClick={handleGoToCart}>
                 <span className="cart-icon"> <FontAwesomeIcon icon={faCartShopping} /></span>
                  {t("cart")}<span className="cart-items">({cartItems})</span>
                </button>
                <button className="btn" onClick={handleBuyTicket}>
                  {t("buyTicket")}
                </button>
                {/* Partner-DESKTOP */}
                <div className="login-and-lang">
            {!isMobile && (  
              <div className={`partner-section ${authenticated ? 'logged-in' : 'logged-out'} ${dropdownVisible ? 'open' : ''}`} ref={dropdownRef}>
        {isLoggedIn ?  (
          <div className={`partner-logged ${dropdownVisible ? 'arrow-down' : ''}`}>
            <div onClick={handlePartnerOptions} className="partner-login-name">
              {partnerGroupName}
            </div>
            {authenticated && (
              <span onClick={toggleDropdown}>
                <span className="arrow">{dropdownVisible ? '▲' : '▼'}</span>
              </span>
            )}
            {dropdownVisible && (
              <ul className="partner-dropdown">
                <li onClick={() => navigate('/partner')}>{t('partner:myAccount')}</li>
                <li onClick={handleSignOut}>{t('partner:signOut')}</li>
              </ul>
            )}
          </div>
        ) : (
          <NavLink className="partner-login" to="/partner" onClick={handleNavLinkClick}>
           <p className="text">Login</p>
          </NavLink>
        )}
      </div>
            )}
            {!isMobile && (
            <LanguageSelector />
            )}
            </div>
          </>
            )}

            {isMobile && (
            <div className={`mobile-dropdown ${menuOpen ? "menu-open" : ""}`}>
      <div
                className={`dropdown ${
                  aboutDropdownVisible ? "active" : ""
                }`}
              >
                <div
                  className="dropbtn"
                  onClick={() =>
                    setAboutDropdownVisible(!aboutDropdownVisible)
                  }
                >
                  <button className="dropdownarrow">
                    <span className={hoverlineClass}>
                      {t("aboutDiskoLine")}
                    </span>
                    <span className="nav-arrow">▼</span>
                  </button>
                  <div className="dropdown-content">
                    <NavLink to="/om-disko-line/om-os" onClick={handleNavLinkClick}>
                      {t("aboutUs")}
                    </NavLink>
                    <NavLink to="/om-disko-line/flaaden" onClick={handleNavLinkClick}>
                      {t("fleet")}
                    </NavLink>
                    <NavLink to="/om-disko-line/charter" onClick={handleNavLinkClick}>
                      {t("charter")}
                    </NavLink>
                    <NavLink to="/om-disko-line/csr-politik" onClick={handleNavLinkClick}>
                      {t("csrPolicy")}
                    </NavLink>
                    <NavLink to="/om-disko-line/ledige-stillinger" onClick={handleNavLinkClick}>
                      {t("availablePositions")}
                    </NavLink>
                  </div>
                </div>
              </div>
                            {/* DISKOLINE EXPLORER */}
              <div className={`dropdown ${diskoLineExplorerDropdownVisible ? 'active' : ''}`}>
  <div className="dropbtn" onClick={toggleDiskoLineExplorerDropdown}>
    <button className="dropdownarrow">
      <span className={hoverlineClass}>
        {t("diskoLineExplorer")}
      </span>
      <span className="nav-arrow">▼</span>
    </button>
  </div>

  {/* The content inside the dropdown */}
  <div className="dropdown-content">
    <div className="sub-menu">
      <ul>
        {/* First sub-menu item */}
        <li className="sub-menu-item">
          <NavLink to="diskolineexplorer/disko-bay" onClick={handleNavLinkClick}>
            {t("theArea")} <span className="nav-arrow">{isMobile ? '' : '▶'}</span>
          </NavLink>
          <div className="right-sided-submenu">
            <ul>
              {/* {data.map((item) => (
                <li key={item.id}>
                  <NavLink to={`diskolineexplorer${item.route.path}${languagePath}`}>
                    {item.name}
                  </NavLink>
                </li>
              ))} */}

              {/* Additional static sub-menu items */}
              <li>
                <NavLink to="diskolineexplorer/Ilulissat" onClick={handleNavLinkClick}>
                  {t("Ilulissat")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/Ilimanaq" onClick={handleNavLinkClick}>
                  {t("Ilimanaq")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/EquipSermia" onClick={handleNavLinkClick}>
                  {t("Eqip Sermia")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/Qeqertarsuaq" onClick={handleNavLinkClick}>
                  {t("Qeqertarsuaq")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/Qasigiannguit" onClick={handleNavLinkClick}>
                  {t("Qasigiannguit")}
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/* Second sub-menu item */}
        <li className="sub-menu-item">
          <NavLink to="diskolineexplorer/sailing-trips" onClick={handleNavLinkClick}>
            {t("sailingTrips")} <span className="nav-arrow">{isMobile ? '' : '▶'}</span>
          </NavLink>
          <div className="right-sided-submenu">
            <ul>
              <li>
                <NavLink to="diskolineexplorer/whale-safari" onClick={handleNavLinkClick}>
                  {t("whaleSafari")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/daytrip-to-eqip-sermia" onClick={handleNavLinkClick}>
                  {t("dayTripToEqipSermia")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/fishing-trip" onClick={handleNavLinkClick}>
                  {t("fishingTrip")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/icefiord-cruise" onClick={handleNavLinkClick}>
                  {t("icefiordCruise")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/northern-lights" onClick={handleNavLinkClick}>
                  {t("northernLights")}
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/* Third sub-menu item */}
        <li className="sub-menu-item">
          <NavLink to="diskolineexplorer/hiking-trips" onClick={handleNavLinkClick}>
            {t("hikingTrips")} <span className="nav-arrow">{isMobile ? '' : '▶'}</span>
          </NavLink>
          <div className="right-sided-submenu">
            <ul>
              <li>
                <NavLink to="diskolineexplorer/citywalk-in-ilulissat" onClick={handleNavLinkClick}>
                  {t("cityWalkInIlulissat")}
                </NavLink>
              </li>
              <li>
                <NavLink to="diskolineexplorer/sermermiut-hike" onClick={handleNavLinkClick}>
                  {t("sermermiutHike")}
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
              {/* Practical Information */}
              <div
                className={`dropdown ${
                  practicalDropdownVisible ? "active" : ""
                }`}
              >
                <div
                  className="dropbtn"
                  onClick={() =>
                    setPracticalDropdownVisible(!practicalDropdownVisible)
                  }
                >
                  <button className="dropdownarrow">
                    <span className={hoverlineClass}>
                      {t("practicalInformation")}
                    </span>
                    <span className="nav-arrow">▼</span>
                  </button>
                  <div className="dropdown-content">
                    <NavLink to="/practicial-information/raad-og-vejledning" onClick={handleNavLinkClick}>
                      {t("adviceAndGuidance")}
                    </NavLink>
                    <NavLink to="/practicial-information/befordringsbestemmelser" onClick={handleNavLinkClick}>
                      {t("carriageRegulations")}
                    </NavLink>
                    <NavLink to="/practicial-information/irregularitet" onClick={handleNavLinkClick}>
                      {t("irregularity")}
                    </NavLink>
                    <NavLink to="/practicial-information/praise-and-criticism" onClick={handleNavLinkClick}>
                      {t("praiseAndCriticism")}
                    </NavLink>
                    <NavLink to="/practicial-information/public-timetable" onClick={handleNavLinkClick}>
                      {t("publicTimetable")}
                    </NavLink>
                  </div>
                </div>
              </div> 
                {/* Min Rejse */}
                <div
                className={`dropdown ${
                  journeyDropdownVisible ? "active" : ""
                }`}
              >
                <div
                  className="dropbtn"
                  onClick={() =>
                    setJourneyDropdownVisible(!journeyDropdownVisible)
                  }
                >
                  <button className="dropdownarrow">
                    <span className={hoverlineClass}>
                      {t("myJourney")}
                    </span>
                    <span className="nav-arrow">▼</span>
                  </button>
                  <div className="dropdown-content">
                    <NavLink to="/my-journey/my-ticket" onClick={handleNavLinkClick}>
                      {t("myTicket")}
                    </NavLink>
                    <NavLink to="/my-journey/cancel-ticket" onClick={handleNavLinkClick}>
                      {t("cancelTicket")}
                    </NavLink>
                    <NavLink to="/my-journey/change-phonenr" onClick={handleNavLinkClick}>
                      {t("changePhoneNo")}
                    </NavLink>
                  </div>
                </div>
              </div>
              <NavLink to="/contact" className="dropdownarrow" onClick={handleNavLinkClick}>
                <span className={hoverlineClass}>{t("contact")}</span>
              </NavLink>
              {/* Partner mobile */}
              <div className={`partner-section ${authenticated ? 'logged-in' : 'logged-out'} ${dropdownVisible ? 'open' : ''}`}>
        {isLoggedIn ? (
          <div className={`partner-logged ${dropdownVisible ? 'arrow-down' : ''}`}>
            <div className="partner-and-arrow">
            <div onClick={handlePartnerOptions} className="partner-login-name">
              {partnerGroupName}
            </div>
            {authenticated && (
              <span onClick={toggleDropdown}>
                <span className="arrow">{dropdownVisible ? '▲' : '▼'}</span>
              </span>
            )}
            </div>
            {dropdownVisible && (
              <ul className="partner-dropdown">
                <li onClick={() => navigate('/partner')}>{t('partner:myAccount')}</li>
                <li onClick={handleSignOut}>{t('partner:signOut')}</li>
              </ul>
            )}
          </div>
        ) : (
          <NavLink className="partner-login" to="/partner" onClick={handleNavLinkClick}>
           <p className="text">Login</p>
          </NavLink>
        )}
      </div>
      <div className="lang-select">
              <LanguageSelector />
              </div>
      </div>
      )}
                  </div>
              </div>
        </nav>
      </div>
    );
  }