import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-US';

const languagesToIndexCalendar = {
  dk: daLocale,
  en: enLocale,
  gl: daLocale
}
const languagesToIndex = {
  dk: 0,
  en: 1,
  gl: 2
}
var constants = { languagesToIndex, languagesToIndexCalendar };
export default constants;