import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useCookieContext } from './CookieContext';



const CookieConsentBar = () => {
  const {t} = useTranslation(["common", "toastmessages"])
  const [consent, setConsent] = useState(Cookies.get('cookieConsent'));
 const {showSettings, setShowSettings} = useCookieContext();
  const viewedCookiePolicy = Cookies.get('viewed_cookie_policy');
  const [selectedCookie, setSelectedCookie] = useState(null);
  const viewed_cookie_policy = Cookies.get('viewed_cookie_policy');
  const googleAnalyticsChecked = Cookies.get('cookielawinfo-checkbox-google-analytics') === 'yes';
  const googleTagManagerChecked = Cookies.get('cookielawinfo-checkbox-google-tag-manager') === 'yes';

const [checkboxValues, setCheckboxValues] = useState({
  necessary: true,
  googleAnalytics: googleAnalyticsChecked,
      googleTagManager: googleTagManagerChecked
});

  const cookieDescriptions = {
    necessary: t('toastmessages:aboutNecessary'),
    googleAnalytics: t('toastmessages:aboutAnalytics'),
    googleTagManager: t('toastmessages:aboutTagManager'),
  };

  useEffect(() => {
    if (!viewedCookiePolicy) {
      showCookieConsentBar();
    }
  }, [viewedCookiePolicy,consent]);

  const showCookieConsentBar = () => {
    const viewed_cookie_policy = Cookies.get('viewed_cookie_policy');
    if (viewed_cookie_policy) {
      return;
    }
    // Check if all necessary options are selected
    const necessaryOption = Cookies.get('cookielawinfo-checkbox-necessary') === 'yes';
    const analyticsOption = Cookies.get('cookielawinfo-checkbox-google-analytics') === 'yes';
    const tagManagerOption = Cookies.get('cookielawinfo-checkbox-google-tag-manager') === 'yes';
  
    // If all necessary options are selected, hide the cookie bar
    if (necessaryOption && analyticsOption && tagManagerOption) {
      setConsent('custom'); // Set custom consent to hide the component
    }
  };

  const handleAcceptAll = () => {
    Cookies.set('cookieConsent', 'all', { expires: 365 });
    Cookies.set('viewed_cookie_policy', 'yes', { expires: 365 });
    setConsent('all');
  };

  const handleDecline = () => {
    Cookies.set('cookieConsent', 'none', { expires: 365 });
    setConsent('none');
    Cookies.set('viewed_cookie_policy', 'yes', { expires: 365 });
    Cookies.set('cookielawinfo-checkbox-necessary', checkboxValues.necessary ? 'yes' : 'yes', { expires: 365 });
  };

  const handleCookieSettings = () => {
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handleReadMore = (cookieName, event) => {
    // Check if the click event originated from the input element
    if (event.target.tagName.toLowerCase() === 'input') {
      return;
    }
  
    setSelectedCookie(selectedCookie === cookieName ? null : cookieName);
  };
  const handleSaveSettings = () => {
    // Check if all necessary options are selected
    let consent, cookieConsent;
    if (checkboxValues.necessary && checkboxValues.googleAnalytics && checkboxValues.googleTagManager) {
      consent = cookieConsent = 'all';
    } else if (!checkboxValues.googleAnalytics && !checkboxValues.googleTagManager) {
      consent = cookieConsent = 'none';
    } else {
      consent = cookieConsent = 'custom';
    }
    const savedNecessary = Cookies.get('cookielawinfo-checkbox-necessary');
  const initialNecessary = savedNecessary === undefined ? 'yes' : savedNecessary;
  const savedAnalytics = Cookies.get('cookielawinfo-checkbox-google-analytics');
  const initialAnalytics = savedAnalytics === undefined ? 'yes' : savedAnalytics;
  const savedTagManager = Cookies.get('cookielawinfo-checkbox-google-tag-manager');
  const initialTagManager = savedTagManager === undefined ? 'yes' : savedTagManager;
  setCheckboxValues({
    necessary: initialNecessary === 'yes',
    googleAnalytics: initialAnalytics === 'yes',
    googleTagManager: initialTagManager === 'yes',
  });
    // Set cookies based on consent and checkbox values
    Cookies.set('cookieConsent', cookieConsent, { expires: 365 });
    Cookies.set('cookielawinfo-checkbox-necessary', 'yes', { expires: 365 }); // Always enabled
    Cookies.set('cookielawinfo-checkbox-google-analytics', checkboxValues.googleAnalytics ? 'yes' : 'no', { expires: 365 });
    Cookies.set('cookielawinfo-checkbox-google-tag-manager', checkboxValues.googleTagManager ? 'yes' : 'no', { expires: 365 });
    Cookies.set('viewed_cookie_policy', 'yes', { expires: 365 }); // Update based on your requirements
  
    setShowSettings(false);
    setConsent(consent);
  };

  const handleCheckboxChange = (checkboxName, event) => {
    // Prevent checkbox change when clicking on the label
    if (event.target.tagName.toLowerCase() !== 'input') {
      event.preventDefault();
      return;
    }
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [checkboxName]: !prevValues[checkboxName],
    }));
  
    if (checkboxName === 'necessary') {
      return;
    }
  };

  if (viewed_cookie_policy) {
    return null; 
  }

  return (
    <div  className={`cookie-consent-popup`}>
      {!showSettings ? (
        <div className="cookie-consent-bar">
          <p className='cookies-consent-info'>{t('toastmessages:cookiesConsent')}</p>
          <div className='cookies-buttons'>
            <button className="btn" onClick={handleAcceptAll}>{t('toastmessages:acceptAll')}</button>
            <button className='btn' onClick={handleDecline}>{t('toastmessages:decline')}</button>
            <p className='cookie-settings-link' onClick={handleCookieSettings}>{t('toastmessages:cookieSettings')}</p>
          </div>
        </div>
      ) : (
          <div className="cookie-settings">
            <p className='cookie-settings-header'>{t('toastmessages:cookiesAboutHeader')}</p>
            <p className='cookie-settings-about'>{t('toastmessages:cookiesAbout')}</p>
            {Object.entries(checkboxValues).map(([cookieName, isChecked]) => (
              <div key={cookieName} className={`cookie-checkbox ${selectedCookie === cookieName ? 'active' : ''}`} onClick={(event) => handleReadMore(cookieName, event)}>
                <div className="left-container">
                  <span className={selectedCookie === cookieName ? "arrow-down" : "arrow-right"}>
                    {selectedCookie === cookieName ? String.fromCharCode(9660) : String.fromCharCode(9656)}
                  </span>
                  {cookieName === 'necessary' ? (
                    <div className='always-enabled'>
                      <div className='cookie-name'>
                        {t('toastmessages:necessary')}
                      </div>
                    </div>
                  ) : (
                    <span className="cookie-name">
                      {cookieName === 'googleAnalytics' ? 'Google Analytics' : 'Google Tag Manager'}
                    </span>
                  )}
                </div>
                <div className="right-container">
                  {cookieName === 'necessary' && (
                    <div className='always-enabled'>
                      <div>
                        {t('toastmessages:alwaysEnabled')}
                      </div>
                    </div>
                  )}

                  {cookieName !== 'necessary' && (
                    <div className='switch-container'>
                      <span className="status-text">{isChecked ? t('toastmessages:enabled') : t('toastmessages:disabled')}</span>
                      <label className='switch'>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={(event) => handleCheckboxChange(cookieName, event)}
                          className="pill-checkbox"
                          disabled={cookieName === 'necessary'}
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                  )}
                </div>
                {selectedCookie === cookieName && (
                <div className="cookie-description-container">
                <div className="cookie-description">
                  <p>{cookieDescriptions[cookieName]}</p>
                </div>
              </div>
                )}
              </div>
            ))}
            <div className='button-container'>
            <button className='btn' onClick={handleSaveSettings}>{t('toastmessages:saveSettings')}</button>
            </div>
            <p onClick={handleCloseSettings} className='close-cookie-settings'><span>&times;</span></p>
          </div>
        )}
      </div>
    );
  };
  

export default CookieConsentBar;