import Cookies from "js-cookie";
import React, { createContext, useContext, useState, useEffect } from "react";
import config from '../configs/config';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(
    Cookies.get("authToken") != null
  );
  const [partnerGroupName, setPartnerGroupName] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [isSuperUser, setIsSuperUser] = useState(false);
  const apiUrl = config.default.apiPath;

  // Check if selectedPartnerGroup data exists in Cookies
  useEffect(() => {
    const selectedGroupData = Cookies.get("selectedPartnerGroup");
    if (selectedGroupData) {
      const selectedGroup = JSON.parse(selectedGroupData);
      setPartnerGroupName(selectedGroup.partnerGroupName);
      setPartnerName(selectedGroup.partnerName);
      setIsSuperUser(selectedGroup.isSuper);
    }
  }, []);

  async function checkTokenExpiry() {
    const authToken = Cookies.get('authToken');
    const tokenExpiry = parseInt(Cookies.get('tokenExpiry'), 10);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  
    if (!authToken || !tokenExpiry || currentTime >= tokenExpiry - 120) {
      // Access token has expired or doesn't exist, try to refresh it
      const refreshToken = Cookies.get('refreshToken');
  
      if (refreshToken) {
        try {
          const url = `${apiUrl}/Authentication/GetPartnerTokenByRefreshToken?refreshToken=${refreshToken}`;
          const refreshResponse = await fetch(url);
  
          if (refreshResponse.status === 200) {
            const refreshData = await refreshResponse.json();
  
            if (refreshData.success === true) {
              // Update tokens
              Cookies.set('authToken', refreshData.token, { sameSite: 'Lax', secure: true });
              Cookies.set('refreshToken', refreshData.refreshToken, { sameSite: 'Lax', secure: true });
              Cookies.set('tokenExpiry', refreshData.tokenExpired, { sameSite: 'Lax', secure: true });
              return true; // Token refreshed successfully
            }
          } else {
            console.error('Error refreshing token: Non-success response status');
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      }

      if(authenticated && Cookies.get("authToken") == null){
        window.location.href = "/partner/login";
      }
      return false; // If refresh fails or there's no refreshToken, user needs to log in again
    }
    return true; // Access token is still valid
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        partnerGroupName,
        setPartnerGroupName,
        partnerName,
        setPartnerName,
        isSuperUser,
        setIsSuperUser,
        checkTokenExpiry,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
