import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";


// Images
import QeqertarsuaqHeader from "../../images/area/QeqertarsuaqHeader.jpg"
import Qeqertarsuaq1 from "../../images/area/Qeqertarsuaq1.jpg";
import Qeqertarsuaq2 from "../../images/area/Qeqertarsuaq2.jpg";
import Qeqertarsuaq3 from "../../images/area/Qeqertarsuaq3.jpg";
import Qeqertarsuaqsektion2 from "../../images/area/Qeqertarsuaqsektion2.jpg";
import Qeqertarsuaq1sektion3 from "../../images/area/Qeqertarsuaq1sektion3.jpg";
import Qeqertarsuaq2sektion3 from "../../images/area/Qeqertarsuaq2sektion3.jpg";


export default function Qeqertarsuaq() {
    const { t } = useTranslation(["common", "areas"]);
    const headerStyle = {
      backgroundImage: `url(${QeqertarsuaqHeader})`,
      position: 'relative',
    };

    const overlayStyle = {
      content: "",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0.3)', 
    };
    useLayoutEffect(() => {
      // Scroll to the top on load
      window.scrollTo(0, 0);
    }, []);

return(
    <div className="area-pages">
        <Helmet>
          <title>Qeqertarsuaq | Disko Line {t("greenland")}</title>
        </Helmet>
        <div className="header" style={headerStyle}>
        <div style={overlayStyle}></div>
        <div className="header-content">
          <div className="header-tag">
            <h1 className="bolder">{t('areas:Qeqertarsuaq')}</h1>
          </div>
        </div>
      </div>

      <div className="section1">
        <h2>{t('areas:section1Qeqertarsuaq')}</h2>
        <p>{t('areas:firstpSektion1Qeqertarsuaq')}</p>
        <p>{t('areas:secondpSektion1Qeqertarsuaq')}</p>
        <p>{t('areas:thirdpSektion1Qeqertarsuaq')}</p>
        <p>{t('areas:fourthpSektion1Qeqertarsuaq')}</p>
        <div className="img-container-sektion1">
        <img className="Qeqertarsuaq1" src={Qeqertarsuaq1} alt="Qeqertarsuaq" />
        <img src={Qeqertarsuaq2} alt="Qeqertarsuaq" />
        <img src={Qeqertarsuaq3} alt="Qeqertarsuaq" />
        </div>
      </div>

      <div className="section2">
        <h2>{t('areas:section2Qeqertarsuaq')}</h2>
        <p>{t('areas:firstpSektion2Qeqertarsuaq')}</p>
        <p>{t('areas:secondpSektion2Qeqertarsuaq')}</p>
        <p>{t('areas:thirdpSektion2Qeqertarsuaq')}</p>
        <p>{t('areas:fourthpSektion2Qeqertarsuaq')}</p>
        <div className="img-container-sektion2">
        <img src={Qeqertarsuaqsektion2} alt="Qeqertarsuaq" />
        </div>
      </div>

      <div className="section3">
        <h2>{t('areas:section3Qeqertarsuaq')}</h2>
        <p>{t('areas:firstpSektion3Qeqertarsuaq')}</p>
        <p>{t('areas:secondpSektion3Qeqertarsuaq')}</p>
        <p>{t('areas:thirdpSektion3Qeqertarsuaq')}</p>
        <p>{t('areas:fourthpSektion3Qeqertarsuaq')}</p>
        <p>{t('areas:fifthpSektion3Qeqertarsuaq')}</p>
        <div className="img-container-sektion3">
        <img src={Qeqertarsuaq1sektion3} alt="Qeqertarsuaq" />
        <img src={Qeqertarsuaq2sektion3} alt="Qeqertarsuaq" />
        </div>
      </div>

      </div>
)
}