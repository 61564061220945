import { useCart } from "../../components/CartContext";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import BuyTicketTabs from "../../components/BuyTicketTabs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import config from "../../configs/config";

export default function HandlePayment() {
  const { fetchCartItems } = useCart();
  const [success, setSuccess] = useState(false); // Track payment success
  const [error, setError] = useState(null); // Track payment error
  const [customerName, setCustomerName] = useState("");
  const [orderID, setOrderID] = useState("");
  const { t } = useTranslation(['common', 'buyticket']);
  const apiUrl = config.default.apiPath;
  const navigate = useNavigate();
const sessionIDCookie = Cookies.get("sessionID");



function generateSessionID() {
  const timestamp = Date.now().toString(36);
  const randomStr = Math.random().toString(36).substr(2, 5);
  return `${timestamp}-${randomStr}`;
}
  async function setSessionIDWithExpiry() {
    const response =  await fetch(`${apiUrl}/System/GetFrontEndSetup`);
    const data = await response.json();
    const sessionTime = data[0].sessionTime;
    const SESSION_TIMEOUT =  sessionTime * 60 * 1000;
    const sessionID = generateSessionID();
    const now = new Date().getTime();
    Cookies.set('sessionID', sessionID, { sameSite: 'Lax' });
    Cookies.set('sessionExpiry', now + SESSION_TIMEOUT, { sameSite: 'Lax' });
  }
  async function getOrderInformation(){
    const urlParams = new URLSearchParams(window.location.search);
    const paramOrderID = urlParams.get("orderID");
    const verifyCode = urlParams.get("verifyCode");
    if (!paramOrderID || !verifyCode) {
      return;
    }
    const response = await fetch(`${apiUrl}/Cart/GetOrderDetails?orderID=${paramOrderID}&verifyCode=${verifyCode}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    setOrderID(data.data.orderId);
    setCustomerName(data.data.orderCustomerName);
  }
  useEffect(() => {
    // Parse URL parameters to determine success status and error message
    const urlParams = new URLSearchParams(window.location.search);
    const isSuccess = urlParams.get("success") === "True";
    const errorMessage = urlParams.get("errorMsg");
    // Update state based on the parsed values
    setSuccess(isSuccess);
    setError(isSuccess ? null : errorMessage);
    if(isSuccess){
      setSessionIDWithExpiry();
      getOrderInformation();
    }
    // Fetch cart items
    fetchCartItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionIDCookie]);
function handleGoToFrontPage(){
  navigate("/");
}

  return (
    <div className="handlepayment">
      <BuyTicketTabs activeTab={5} />
      <div className="handlepayment-content">
        {!success && !error && <Spinner />}
        {success && (
          <div className="message">
            <h2>{t('buyticket:successMessageHeader')}</h2>
            <h3>{t('buyticket:successMessage')} <span className="customer">{customerName ? ` ${customerName}` : ""}!</span></h3>
            {orderID ? <p>{t('buyticket:successOrderID')} {orderID}</p> : null}
            <p>{t('buyticket:successYouWillGetEmailSoon')}</p>
            <p>{t('buyticket:successHaveANiceTrip')}</p>
            <div className="button-center">
            <button onClick={handleGoToFrontPage} className="btn">{t('buyticket:successGoToFrontPage')}</button>
            </div>
          </div>
        )}
        {error && (
          <div className="message">
            <h2>{t('buyticket:errorMessageHeader')}</h2>
            {t('buyticket:errorCode')}{error}
          </div>
        )}
      </div>
    </div>
  );
}