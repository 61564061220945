import { Helmet } from "react-helmet";
import headerImage from "../../images/headers/DiskoLineTarga.jpg";
import DLboatImage from "../../images/diskoline.JPG";
import { useTranslation } from "react-i18next";
export default function AboutUsPage(){
    const {t} = useTranslation(["common", "about"])
    const headerStyle = {
        backgroundImage: `url(${headerImage})`
    }
    return(
        <div className="aboutuspage" >
            <Helmet>
                <title>{t('aboutUs')} | Disko Line Greenland</title>
            </Helmet>
            <div className="header" style={headerStyle}>
                <div className="header-content">
                    <p>Disko Line</p>
                    <h1>{t('aboutUs')}</h1>
                </div>
            </div>
            {/*Body*/}
            <div className="content">
            <div className="grid">
            <div className="section">
            <h2>{t('aboutDiskoLine')}</h2>
            <p>{t('about:aboutSection1')}</p>
            <br></br>
            <p>{t('about:aboutSection2')}</p><p><a href="https://www.diskolineexplorer.com" target="_blank" rel="noreferrer">www.diskolineexplorer.com</a>.</p>
            <p>{t('about:aboutSection3')}</p>
            <br></br>
            <p>{t('about:aboutSection4')}</p>
            </div>
            <div className="section">
            <img src={DLboatImage} alt="Disko Line boat" className="fleet-img" />
            </div>
            </div>

            <div class="center-section">
            <h3>{t('about:aboutVision')}</h3>
            <p>{t('about:aboutVision1')}</p>
            <h3>{t('about:aboutPriorities')}</h3>
            <p>{t('about:aboutPriorities1')}</p>
            <p>{t('about:aboutPriorities2')}</p>
            <p>{t('about:aboutPriorities3')}</p>
            <p>{t('about:aboutPriorities4')}</p>
            <h3>{t('about:aboutValues')}</h3>
            <p>{t('about:aboutValues1')}</p>
            <p>{t('about:aboutValues2')}</p>
            <p>{t('about:aboutValues3')}</p>
            <p>{t('about:aboutValues4')}</p>
            <p>{t('about:aboutValues5')}</p>

            </div>
        </div>
    </div>
    )
}
