import { Helmet } from "react-helmet";
import headerImage from "../../images/headers/icefiord.jpg";
import IrregularitetPhotoDK from "../../images/irregularity/irregularityDK.jpg";
import IrregularitetPhotoENG from "../../images/irregularity/irregularityENG.jpg";
import IrregularitetPhotoGL from "../../images/irregularity/irregularityGL.jpg";
import { useTranslation } from "react-i18next";
  

export default function Irregularity(){
    const {t, i18n} = useTranslation(["common", "practicalinfo"]);
    const headerStyle = {
        backgroundImage: `url(${headerImage})`
    }
    
    let irregularityPhoto;
    switch (i18n.language) {
        case "dk":
            irregularityPhoto = IrregularitetPhotoDK;
            break;
        case "eng":
            irregularityPhoto = IrregularitetPhotoENG;
            break;
        case "gl":
            irregularityPhoto = IrregularitetPhotoGL;
            break;
        default:
            irregularityPhoto = IrregularitetPhotoENG; // Default to English
            break;
    }
    return(
        <div className="irregularitypage">
            <Helmet>
                <title>{t('practicalinfo:irregularity')}  | Disko Line Greenland</title>
            </Helmet>
            <header style={headerStyle}>
                <div className="header-text">
                    <p>Disko Line</p>
                    <h1>{t('practicalinfo:irregularity')}</h1>
                </div>
            </header>
            <div className="content">
                <div className="grid">
                    <div className="section">
                    <p className="diskoline-header">Disko Line</p>
                    <h2>{t('practicalinfo:irregularity')}</h2>
                    <p>{t('practicalinfo:irregularity1')}</p>
                    <p>{t('practicalinfo:irregularity2')}</p>
                    </div>
                    <div className="section">
                    <img src={irregularityPhoto} alt="Flowchart over Irregularitet"/>
                    </div>
                </div>
            </div>
    </div>
    )
}