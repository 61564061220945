import "./style/style.css";
import {  Navigate, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
// import { mapLanguageCodeToId } from './components/LanguageUtils';

//Translation Component
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import './locales/en/common.json';
import './locales/dk/common.json';
import './locales/gl/common.json';
import './locales/en/homepage.json';
import './locales/dk/homepage.json';
import './locales/gl/homepage.json';
import './locales/en/buyticket.json';
import './locales/dk/buyticket.json';
import './locales/gl/buyticket.json';
import './locales/en/partner.json';
import './locales/dk/partner.json';
import './locales/gl/partner.json';
import './locales/en/practicalinfo.json';
import './locales/dk/practicalinfo.json';
import './locales/gl/practicalinfo.json';
import './locales/en/about.json';
import './locales/dk/about.json';
import './locales/gl/about.json';
import './locales/en/contact.json';
import './locales/dk/contact.json';
import './locales/gl/contact.json';
import './locales/en/diskolineexplorer.json';
import './locales/dk/diskolineexplorer.json';
import './locales/gl/diskolineexplorer.json';
import './locales/dk/topasexplorergroup.json';
import './locales/en/topasexplorergroup.json';
import './locales/gl/topasexplorergroup.json';
import './locales/dk/toastmessages.json'
import './locales/en/toastmessages.json'
import './locales/gl/toastmessages.json'
import './locales/dk/areas.json';
import './locales/en/areas.json';  

//React Components
//Components Import
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
/*Pages Import*/
import HomePage from "./pages/HomePage";
import TopasExplorerGroup from "./pages/TopasExplorerGroup";
//ABOUT DISKO LINE
import AboutUsPage from "./pages/about/AboutUsPage";
import Fleet from "./pages/about/Fleet";
import Charter from "./pages/about/Charter";
import CSRPolicy from "./pages/about/CSRPolicy";
import AvailableJobs from "./pages/about/AvailableJobs";
import CookiePolicy from "./pages/about/CookiePolicy";
//Practical Info
import AdviceAndGuidance from "./pages/practical-info/AdviceAndGuidance";
import TrafficInfo from "./pages/practical-info/TrafficInfo";
import CarriageRegulations from "./pages/practical-info/CarriageRegulations";
import Irregularity from "./pages/practical-info/Irregularity";
import PraiseAndCriticism from "./pages/practical-info/PraiseAndCriticism";
import PublicTimetable from "./pages/practical-info/PublicTimetable";
import PublicTimetableAreaSelected from "./pages/practical-info/PublicTimetableAreaSelected";
//MY JOURNEY
import MyTicket from "./pages/myjourney/MyTicket";
import CancelTicket from "./pages/myjourney/CancelTicket";
import ChangePhoneNumber from "./pages/myjourney/ChangePhoneNumber";
//CONTACT
import Contact from "./pages/Contact";
//BUY TICKET
import { CartProvider } from "./components/CartContext";
import BuyTicketIndex from "./pages/buyticket/BuyTicketIndex";
import BuyTicketAreaSelected from "./pages/buyticket/BuyTicketAreaSelected";
import CustomerCart from "./pages/buyticket/CustomerCart";
import CustomerInformation from "./pages/buyticket/CustomerInformation";
import CustomerCartSummary from "./pages/buyticket/CustomerCartSummary";
import HandlePayment from "./pages/buyticket/HandlePayment";
//PARTNER
import PartnerLogin from "./pages/partner/PartnerLogin";
import PartnerIndex from "./pages/partner/PartnerIndex";
import PartnerAccountInformation from "./pages/partner/PartnerAccountInformation";
import PartnerChangePassword from "./pages/partner/PartnerChangePassword";
import PartnerUserList from "./pages/partner/PartnerUserList";
import PartnerOrderList from "./pages/partner/PartnerOrderList";
import CreatePartnerUser from "./pages/partner/CreatePartnerUser";
import PartnerOrderDetail from "./pages/partner/PartnerOrderDetail";
import { Route } from "react-router-dom/dist";
import { AuthProvider } from "./components/AuthContext";
//DISKO LINE EXPLORER
import DiskoBay from "./pages/area/DiskoBay";
import Ilulissat from "./pages/area/Ilulissat";
import Ilimanaq from "./pages/area/Ilimanaq";
import EquipSermia from "./pages/area/EquipSermia";
import Qeqertarsuaq from "./pages/area/Qeqertarsuaq";
import Qasigiannguit from "./pages/area/Qasigiannguit"
import SailingTrips from "./pages/diskolineexplorer/sailing-trips/SailingTrips";
import HikingTrips from "./pages/diskolineexplorer/hiking-trips/HikingTrips";
import NorthernLights from "./pages/diskolineexplorer/sailing-trips/NorthernLights";
import WhaleSafari from "./pages/diskolineexplorer/sailing-trips/WhaleSafari";
import IcefiordCruise from "./pages/diskolineexplorer/sailing-trips/IcefiordCruise";
import FishingTrip from "./pages/diskolineexplorer/sailing-trips/FishingTrip";
import DayTripToEqipSermia from "./pages/diskolineexplorer/sailing-trips/DayTripToEqipSermia";
import CityWalkInIlulissat from "./pages/diskolineexplorer/hiking-trips/CityWalkInIlulissat";
import SermermiutHike from "./pages/diskolineexplorer/hiking-trips/SermermiutHike";
import CookieConsentBar from "./components/CookieConsent";
import { CookieProvider } from "./components/CookieContext";
import { FormProvider } from "./components/FormContext";
import { SearchProvider } from "./components/SearchContext";
import config from "./configs/config";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorPage from "./components/ErrorPage";
// import CallUmbraco from "./components/CallUmbraco";



const apiUrl = config.default.apiPath;

async function fetchSessionTimeOut() {
  const response = await fetch(`${apiUrl}/System/GetFrontEndSetup`);
  const data = await response.json();
  const sessionTime = data[0].sessionTime;
  return sessionTime;
}

const SESSION_TIMEOUT = await fetchSessionTimeOut() * 60 * 1000;

function generateSessionID() {
  const timestamp = Date.now().toString(36);
  const randomStr = Math.random().toString(36).substr(2, 5);
  return `${timestamp}-${randomStr}`;
}
function getSessionIDWithExpiry() {
  const sessionID = Cookies.get('sessionID');
  const expiryTime = parseInt(Cookies.get('sessionExpiry'), 10);
  const now = new Date().getTime();
  if (sessionID && expiryTime && now < expiryTime) {
    // Update the cookie expiry time whenever there's user activity
    Cookies.set('sessionExpiry', now + SESSION_TIMEOUT,{ sameSite: 'Lax' });
    return sessionID;
  } else {
    // Remove the cookie if it's expired or doesn't exist
    Cookies.remove('sessionID');
    Cookies.remove('sessionExpiry');
    return null;
  }
}

function setSessionIDWithExpiry() {
  const sessionID = generateSessionID();
  const now = new Date().getTime();
  Cookies.set('sessionID', sessionID, { sameSite: 'Lax' });
  Cookies.set('sessionExpiry', now + SESSION_TIMEOUT, { sameSite: 'Lax' });
}
function handleUserActivity(setAuthenticated) {
  const now = new Date().getTime();
  Cookies.set('sessionExpiry', now + SESSION_TIMEOUT, { sameSite: 'Lax' });
  const lastActivityTime = parseInt(Cookies.get('lastActivityTime'), 10) || now;
  const timeSinceLastActivity = now - lastActivityTime;
  if (timeSinceLastActivity >= SESSION_TIMEOUT) {
    Cookies.remove('authToken');
    Cookies.remove('tokenExpiry');
    Cookies.remove('lastActivityTime');
    Cookies.remove("selectedPartnerGroup");
    Cookies.remove("refreshToken");
    Cookies.remove("userEmail");

    // Set authenticated state to false when the session expires
    setAuthenticated(false);
    // window.location.reload(); // Testing window reload on session timeout
  } else {
    // Update the last activity time
    Cookies.set('lastActivityTime', now, { sameSite: 'Lax' });
  }
}


function App() {
  const [authenticated, setAuthenticated] = useState();
  // const [data, setData] = useState([]);
  // const [, setLoading] = useState(true);
  // const [, setError] = useState(null);
  const checkAuthentication = () => {
    const isAuthenticated = Cookies.get("authToken") != null;
    setAuthenticated(isAuthenticated);
  };
  useEffect(() => {
    checkAuthentication();
  }, []);
  let sessionID = getSessionIDWithExpiry();
  if (!sessionID) {
    setSessionIDWithExpiry();
    sessionID = getSessionIDWithExpiry();
  }
  //Language Module
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        common: require('./locales/en/common.json'),
        homepage: require('./locales/en/homepage.json'),
        buyticket: require('./locales/en/buyticket.json'),
        partner: require('./locales/en/partner.json'),
        practicalinfo: require('./locales/en/practicalinfo.json'),
        about: require('./locales/en/about.json'),
        contactpage: require('./locales/en/contact.json'),
        dle: require('./locales/en/diskolineexplorer.json'),
        toastmessages: require('./locales/en/toastmessages.json'),
        areas: require('./locales/en/areas.json'),
        topasexplorergroup: require('./locales/en/topasexplorergroup.json'),

      },
      dk: {
        common: require('./locales/dk/common.json'),
        homepage: require('./locales/dk/homepage.json'),
        buyticket: require('./locales/dk/buyticket.json'),
        partner: require('./locales/dk/partner.json'),
        practicalinfo: require('./locales/dk/practicalinfo.json'),
        about: require('./locales/dk/about.json'),
        contactpage: require('./locales/dk/contact.json'),
        dle: require('./locales/dk/diskolineexplorer.json'),
        toastmessages: require('./locales/dk/toastmessages.json'),
        areas: require('./locales/dk/areas.json'),
        topasexplorergroup: require('./locales/dk/topasexplorergroup.json'),
      },
      gl: {
        common: require('./locales/gl/common.json'),
        homepage: require('./locales/gl/homepage.json'),
        buyticket: require('./locales/gl/buyticket.json'),
        partner: require('./locales/gl/partner.json'),
        practicalinfo: require('./locales/gl/practicalinfo.json'),
        about: require('./locales/gl/about.json'),
        contactpage: require('./locales/gl/contact.json'),
        dle: require('./locales/gl/diskolineexplorer.json'),
        toastmessages: require('./locales/gl/toastmessages.json'),
        areas: require('./locales/gl/areas.json'),
        topasexplorergroup: require('./locales/gl/topasexplorergroup.json'),
      }
    },
    lng: Cookies.get('selectedLanguage'), //Default language
    fallbackLng: 'dk',
    defaultNS: 'common', // Default Namespace, won't require namespaces.
    interpolation: {
      escapeValue: false,
    },
  });

  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [buttonColor, setButtonColor] = useState('');
    useEffect(() => {
      async function fetchColors() {
      try {
        const response = await fetch(`${apiUrl}/System/GetFrontEndSetup`);
        const data = await response.json();
        let priColor = data[0].primaryColor;
        let secColor = data[0].secondaryColor;
        let btnColor = data[0].buttonColor;
        setPrimaryColor(priColor);
        setSecondaryColor(secColor);
        setButtonColor(btnColor);
      } catch (error) {
        console.error('Error fetching colors:', error);
      }
    }

      fetchColors();
    }, []);
    
    useEffect(() => {
      // Dynamically update the CSS variables
      document.documentElement.style.setProperty('--primary-color', primaryColor);
      document.documentElement.style.setProperty('--secondary-color', secondaryColor);
      document.documentElement.style.setProperty('--button-color', buttonColor);
    }, [primaryColor, secondaryColor, buttonColor]);
    console.log("DL Colors:", primaryColor, secondaryColor, buttonColor);

  // useEffect(() => {
  //   const fetchPage = async () => {
  //     try {
  //       const response = await fetch(
  //         `${apiUrl}/Umbraco/GetUmbracoPages`
  //       );
  //       if (!response.ok) {
  //         throw new Error(`Failed to fetch data: ${response.statusText}`);
  //       }
  //       const jsonData = await response.json();


  //       const filteredItems = [
  //         ...new Map(jsonData.items.map((item) => [item.id, item])).values(),
  //       ]; 

  //       setData(filteredItems);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchPage();
  // }, []);

  // const languageCode = mapLanguageCodeToId(i18n.language);
  // const languagePath = languageCode === 2 ? 'en' : languageCode === 3 ? 'gl' : '';


  // Event listeners that monitor users activity and updates the session's expiry time.
  useEffect(() => {
    function handleActivity() {
      handleUserActivity(setAuthenticated);
    }
    window.addEventListener('click', handleActivity);
    window.addEventListener('keypress', handleActivity);
    return () => {
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);
  const routes = [
    {path:"/", element: <HomePage />},
    { path: "*", element: <Navigate to="/" /> },
    {path:"/contact", element: <Contact />},
    {path: "/topas-explorer-group", element: <TopasExplorerGroup/>},
    {path:"/om-disko-line/om-os", element :<AboutUsPage/>},
    {path:"/om-disko-line/flaaden", element:<Fleet/>},
    {path:"/om-disko-line/charter", element: <Charter/> },
    {path:"/om-disko-line/csr-politik",element:<CSRPolicy/>},
    {path:"/om-disko-line/ledige-stillinger", element:<AvailableJobs/>},
    {path:"/cookie-policy", element:<CookiePolicy/>},
    {path:"/practical-information/raad-og-vejledning" , element:<AdviceAndGuidance/>},
    {path:"/practical-information/trafik-info", element:<TrafficInfo/>},
    {path:"/practical-information/befordringsbestemmelser", element:<CarriageRegulations/>},
    {path:"/practical-information/irregularitet", element:<Irregularity/> },
    {path:"/practical-information/praise-and-criticism", element:<PraiseAndCriticism/>},
    {path:"/practical-information/timetable", element:<PublicTimetable/>},
    {path:"/practical-information/timetable/:id", element:<PublicTimetableAreaSelected/>},
    {path:"/my-journey/my-ticket", element:<MyTicket/> },
    {path:"/my-journey/cancel-ticket", element:<CancelTicket/> },
    {path:"/my-journey/change-phonenr", element:<ChangePhoneNumber/> },
    {path:"/buy-ticket", element:<BuyTicketIndex/>},
    {path:"/error", element:<ErrorPage/>},
    {path:"/buy-ticket/:id", element:<BuyTicketAreaSelected/>},
    {path:"/buy-ticket/informations", element:<CustomerInformation/> },
    {path:"/my-cart", element:<CustomerCart /> },
    {path:"/buy-ticket/summary", element:<CustomerCartSummary /> },
    {path:"/buy-ticket/payment" , element:<HandlePayment/> },
    {path: "/diskolineexplorer/*", element: <DiskoLineExplorerRoutes />},
    {path: "/partner/login", element:<PartnerLogin/>},
    {
      path: "/partner/*",
      element: authenticated ? (
        <PartnerRoutes />
      ) : (
        <PartnerLogin />
      ),
    },
  ];
  function PartnerRoutes() {
    return (
      <Routes>
            <Route path="/" element={<PartnerIndex />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/order-list" element={<PartnerOrderList />} />
            <Route path="/account-info" element={<PartnerAccountInformation />} />
            <Route path="/user-list" element={<PartnerUserList />}/>
            <Route path="/change-password" element={<PartnerChangePassword/>}/>
            <Route path="/create-user" element={<CreatePartnerUser/>}/>
            <Route path="/orderdetail" element={<PartnerOrderDetail/>}/>
      </Routes>
    );
  }


  function DiskoLineExplorerRoutes(){

    return (
      <Routes>
         {/* {data.map((item) => (
          <Route
            key={item.id}
            path={`${item.route.path}${languagePath}`}
            element={<CallUmbraco path={item.route.path} />}
          />
        ))} */}
        <Route path="/disko-bay" element={<DiskoBay/>} />
        <Route path="/ilulissat" element={<Ilulissat/>} />
        <Route path="/ilimanaq" element={<Ilimanaq/>} />
        <Route path="/equipSermia" element={<EquipSermia/>} />
        <Route path="/qeqertarsuaq" element={<Qeqertarsuaq/>} />
        <Route path="/qasigiannguit" element={<Qasigiannguit/>} />
        <Route path="/sailing-trips" element={<SailingTrips/>} />
        <Route path="/whale-safari" element={<WhaleSafari/>} />
        <Route path="/northern-lights" element={<NorthernLights/>} />
        <Route path="/icefiord-cruise" element={<IcefiordCruise/>} />
        <Route path="/Fishing-trip" element={<FishingTrip/>} />
        <Route path="/daytrip-to-eqip-sermia" element={<DayTripToEqipSermia/>} />
        <Route path="/hiking-trips" element={<HikingTrips/>} />
        <Route path="/citywalk-in-ilulissat" element={<CityWalkInIlulissat/>} />
        <Route path="/sermermiut-hike" element={<SermermiutHike/>} />
      </Routes>
    )
  }
  return (
      <CookieProvider>
            <AuthProvider>
            <FormProvider>
            <SearchProvider>
            <CartProvider>
  <Navigation />
  <ErrorBoundary>
  <Routes>
  {routes.map((route) => (
    <Route key={route.path} path={route.path} element={route.element} />
    ))}
</Routes>
</ErrorBoundary>
<CookieConsentBar />
<Footer/>
<ToastContainer />
            </CartProvider>
            </SearchProvider>
            </FormProvider>
            </AuthProvider>
            </CookieProvider>

  );
}

export default App;
