import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import enums from '../commons/Enums'


const InfoQuestion = ({ allQuestions, onNextClick, onNotRelevantClick, onCloseAllQuestions, currentJourneyInfo }) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const [currentpopupIndex, setcurrentpopupIndex] = useState(0);
  const currentquestions = allQuestions[currentpopupIndex];
  const [dynamicFields, setDynamicFields] = useState([]);
  const [lastQuestion, setLastQuestion] = useState(allQuestions.length === 1 ? true : false);
  const { t, i18n } = useTranslation(["common", "buyticket"]);
  const { languagesToIndexCalendar } = enums;
  const handleOptionSelect = async (option, index) => {
    // Pass the index as an argument
    setSelectedOption(option);
    // Add the index to each field in the data
    const dataWithIndex = option.infoTransportQuestions.map((field) => ({
      ...field,
      infoTransportID: option.infoTransportID,
      infoQuestionID: currentquestions.infoQuestionID,
      index: index,
    }));

    setDynamicFields(dataWithIndex); // Update dynamicFields state with data that includes the index
  };

  function handleCurrentPopupIndexChange() {
    if (currentpopupIndex === allQuestions.length - 1) {
      return;
    }
    let indexNumber = currentpopupIndex + 1;
    setcurrentpopupIndex(indexNumber);

    if (indexNumber === allQuestions.length - 1) {
      setLastQuestion(true);
    }
  }
  //Find out if its Arrival or Departure Question
  const getTypeSpecificHeading = () => {
    if (currentquestions.type === 1) {
      return t("buyticket:yourArrival");
    } else if (currentquestions.type === 2) {
      return t("buyticket:yourDeparture");
    }
    // Default heading if no questions or unknown type
    return "Question";
  };
  const getTypeSpecificHeadingType = () => {
    return currentquestions.type;
  };
  useEffect(() => {
    if (currentquestions.infoTransports.length === 0) {
      const dataWithIndex = currentquestions.infoTransportQuestions.map((field) => ({
        ...field,
        infoTransportID: null,
        infoQuestionID: currentquestions.infoQuestionID,
        index: currentquestions.Index,
      }));
      setDynamicFields(dataWithIndex);
    }

  }, [currentquestions]);

  useEffect(() => {
  }, [lastQuestion]);

  const handleNextClick = () => {
    // Call the onNextClick prop with the dynamicFields data
    handleCurrentPopupIndexChange();
    onNextClick({ dynamicFields, lastQuestion, type: getTypeSpecificHeadingType() });
    // setcurrentpopupIndex((previndex) => previndex + 1);
    setSelectedOption(null); // Reset selected option for the next popup
    setDynamicFields([]); // Reset dynamicFields for the next popup
  };
  const handleNotRelevantClick = () => {
    handleCurrentPopupIndexChange();
    onNotRelevantClick({ dynamicFields, lastQuestion });
    setSelectedOption(null); // Reset selected option for the next popup
    setDynamicFields([]);
  };

  const handleCloseAll = () => {
    onCloseAllQuestions();
  };
  const handleFieldChange = (index, newValue) => {
    const updatedFields = dynamicFields.map((field, i) => {
      if (i === index) {
        var stringValue = field.inputType === "date" ? format(newValue, "yyyy-MM-dd") : newValue;
        return { ...field, value: newValue, stringValue: stringValue }; // Update the value of the field that changed
      }
      return field;
    });
    setDynamicFields(updatedFields);
  };

  //For Datepicker
  let today = new Date();
  return (
    <div className="info-questions">
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </head>
      {currentquestions && (
        <div className="pop-up-container">
          <div className="popup-content">
            <div className="header">
              <h2>{getTypeSpecificHeading()}</h2>
              <div className="info-questions-journey-info">
                <div className="date-and-times">
                  <p className="date"> {new Date(
                    currentJourneyInfo.departureDate
                  ).toLocaleDateString("da-DK", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}</p>
                  <div className="stop-times">
                    <p> {currentJourneyInfo.departureTime.slice(11, 16)}</p>
                    <p className="divider">-</p>
                    <p>{currentJourneyInfo.arrivalTime.slice(11, 16)}</p>
                  </div>
                </div>

                {currentJourneyInfo.departureStopName === currentJourneyInfo.arrivalStopName ?
                  (
                    <div className="stop-names">
                      <p>{currentJourneyInfo.departureStopName}</p>
                    </div>
                  ) : (
                    <div className="stop-names">
                      <p>{currentJourneyInfo.departureStopName}</p>
                      <p className="divider">-</p>
                      <p>{currentJourneyInfo.arrivalStopName}</p>
                    </div>
                  )
                }
              </div>
              {
                allQuestions.length > 1 && (
                  <h4>
                    {t("buyticket:question")} {currentpopupIndex + 1}/{allQuestions.length}
                  </h4>
                )
              }
              <span className="close-button" onClick={handleCloseAll}>
                &times;
              </span>
            </div>
            <div className="questions-container">
              <p className="about-info-questions">{currentquestions.infoQuestionDesc}</p>
              {currentquestions.infoTransports.map((n, index) => (
                <div className="option" key={index}>
                  <label>
                    <input
                      type="radio"
                      name="options"
                      checked={selectedOption === n}
                      onChange={() => handleOptionSelect(n, currentquestions.Index)} // Pass the index to handleOptionSelect
                    />
                    <p>{n.question}</p>
                  </label>
                </div>
              ))}
            </div>
            <div className="follow-up-container">
              {dynamicFields.map((field, index) => (
                <div className="follow-up-input" key={index}>
                  <label>{field.infoTransportQuestionTranslation === null
                    ? t("common:other")
                    : field.infoTransportQuestionTranslation
                  }</label>

                  {field.inputType === "date" && (
                    <ReactDatePicker
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      locale={languagesToIndexCalendar[i18n.language]}
                      minDate={today}
                      calendarStartDay={1}
                      selected={field.value ? new Date(field.value) : null}
                      onChange={(date) => handleFieldChange(index, date)}
                    />
                  )}

                  {field.inputType !== "date" && (
                    <input
                      type={field.inputType}
                      value={field.value || ""}
                      onChange={(e) => handleFieldChange(index, e.target.value)}
                    />
                  )}

                </div>
              ))}
            </div>
            <div className="info-buttons">
              <button className="btn-secondary" onClick={handleNotRelevantClick}>{t("buyticket:notRelevant")}</button>
              <button className="btn" onClick={handleNextClick}>{t("buyticket:continue")}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoQuestion;
