import React from "react";
import { useTranslation } from 'react-i18next';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import {  mapLanguageCodeToCountryCode } from "../components/LanguageUtils"; 
export const ParticipantForm = ({ 
  name, 
  onNameChange, 
  phone, 
  onPhoneChange, 
}) => {
  const {t, i18n} = useTranslation(["common", "buyticket"]);
  const handleNameInputChange = (value) => {
/// If name is empty, replicate
/// onNameChange={(value, isManualChange, isPhoneManualChange)
    if (value === "")
    {
      onNameChange(value, false, false); 
    }
/// If name input changed by user input and not state replicated
/// onNameChange={(value, isManualChange, isPhoneManualChange)
    else {
    onNameChange(value, true, false); 
    }
  };
  const handlePhoneInputChange = (value) => {
    const defaultCountryCode = `+${mapLanguageCodeToCountryCode(i18n.language)}`;
/// If Phone is not the default language code (empty), replicate
/// onPhoneChange={(value, isManualChange, isPhoneManualChange)
    if (!value || value === defaultCountryCode)
    {
    onPhoneChange(value, false, false); 
    }
/// If Phone input changed by user input and not state replicated
/// onPhoneChange={(value, isManualChange, isPhoneManualChange)
    else {
    onPhoneChange(value, false, true); 
  }
  };
  return (
    <div className='participant-inputs'>
      <div  className='participant-name'>
      <label className='participant-name-input'>
        {t('buyticket:fullName')}: 
        <input
          type="text"
          value={name}
          placeholder={t('buyticket:fullName')}
          onChange={(e) => handleNameInputChange(e.target.value)}
          />
      </label>
          </div>
      <div className='participant-phone'>
      <label className='participant-phone-input'>
        {t('phoneNumber')}:
        <PhoneInputWithCountrySelect 
            value={phone}
            onChange={handlePhoneInputChange}
            defaultCountry="GL"
            countryOptionsOrder={['GL', 'DK', 'US', '|', '...']}
            />
      </label>
            </div>
    </div>
  );
};
// Checks if all participants have a name
export const validateParticipants = (arrayOfParticipantForms) => {
  for (let order of arrayOfParticipantForms) {
    for (let ticketType of order) {
      for (let participant of ticketType) {
        if (!participant.name ) { 
          return false;
        }
      }
    }
  }
  return true;
};
// Initializes participant forms and adds infortransportAnswer array
export const initializeArrayOfParticipantForms = (cartItems) => {
  return cartItems.map(order => 
    order.ticketTypes.map(ticketType => 
      Array(ticketType.quantity).fill().map(() => ({
        name: '',
        phone: '',
        CustomerCartID: ticketType.customerCartID,
        infoTransportAnswers: [],
      }))
    )
  );
}
