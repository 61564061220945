import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom"
import { useState } from 'react';
//Components
import SearchTicketRoute from "../../components/SearchTicketRoute";
import SearchTicketActivity from "../../components/SearchTicketActivity";
import SearchResultsActivity from "../../components/SearchResultsActivity";
import SearchResultsRoute from "../../components/SearchResultsRoute";
import { Helmet } from "react-helmet";
import BuyTicketTabs from "../../components/BuyTicketTabs";
import { useTranslation } from "react-i18next";
import config from '../../configs/config';
import { useSearchData } from "../../components/SearchContext";
import { useForm } from "../../components/FormContext";
import enums from '../../commons/Enums'

export default function BuyTicketAreaSelected(){
  const {t, i18n} = useTranslation();
  const params = useParams();
  const areaSlug = params.id;
  const [area, setArea] = useState([]);
  const [isActivity, setIsActivity] = useState(false);
  const {searchData, setSearchData} = useSearchData();
  const {setFormRequest} = useForm();
  const routeResultsRef = useRef(null);
  const [initalSearch, setInitialSearch] = useState(false);
  const activityResultsRef = useRef(null);
  const apiUrl = config.default.apiPath;
  const { languagesToIndex } = enums;


    useEffect(() => {
      setFormRequest({    departureStopID: 0,
        arrivalStopID: 0,
        departureDate: "",
        numberTicketTypes: [],
        page: 0,
        isTransfer: null,
        isSearchEarlier: false,
        isSearchActivity: false,});
        setSearchData(null);  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        async function getArea() {
            const response = await fetch(`${apiUrl}/Area/GetAreas`);
            const data = await response.json();
            const filterArea = data.filter((area) =>
              areaSlug.includes(area.id)
            );
            filterArea.map((area) => {
                if(area.isActivity === true){
                    return setIsActivity(true);
                }
               return setIsActivity(false);
            })
            setArea(filterArea)
        }
        getArea();
    },[areaSlug, apiUrl]);


    useEffect(() => {
      if(initalSearch){
        if(isActivity){
          scrollActivityResultsIntoView();
        }else{
          scrollRouteResultsIntoView();
        }
      }
    },[searchData, isActivity, initalSearch]);
    const handleSearch = (data) => {
      setInitialSearch(true);
      setFormRequest(prevFormRequest => ({
        ...prevFormRequest,
        page: 0, 
      }));
      console.log("Data received in handleSearch:", data);
      setSearchData(data);
    };
    const handleSearchMoreRoutes = (data) => {
      setInitialSearch(false);
      setSearchData((prevSearchData) => {
        return {
          ...prevSearchData,
          journeyWithoutTransfers: [...prevSearchData.journeyWithoutTransfers, ...data.journeyWithoutTransfers],
          journeyWithTransfers: [...prevSearchData.journeyWithTransfers, ...data.journeyWithTransfers],
        };
      });
    };
    const handleSearchMoreActivities = (data) => {
      setInitialSearch(false);
      setSearchData((prevSearchData) => {
        console.log("PrevData", prevSearchData);
        return prevSearchData.concat(data);
      });
    };

  const scrollRouteResultsIntoView = () => {
    if (routeResultsRef.current) {
      routeResultsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollActivityResultsIntoView = () => {
    if (activityResultsRef.current) {
      activityResultsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
 
    return(
        <div className="buy-ticket-areaselectedpage">
          <BuyTicketTabs activeTab={1} />
        {area.map((area) => (
  <div key={area.id} className="container-search-form">
          <Helmet>
            <title>{area.areaTranslations[languagesToIndex[i18n.language]]?.areaName} | Disko Line {t("greenland")}</title>
          </Helmet>
    {isActivity ? 
      <div className="search-form-activity">
        <SearchTicketActivity areaName={area.areaTranslations[languagesToIndex[i18n.language]]?.areaName} onSearch={handleSearch} />
        <div key={area.id} className="search-results">
        <div ref={activityResultsRef} className="search-results">
          {searchData && <SearchResultsActivity searchData={searchData}  onSearchMoreActivites={handleSearchMoreActivities} />}
          </div>
        </div>
      </div>
      :
      <div className="search-form-route"> 
        <SearchTicketRoute areaName={area.areaTranslations[languagesToIndex[i18n.language]]?.areaName} onSearch={handleSearch} />
        <div key={area.id} className="search-results">
        <div ref={routeResultsRef} className="search-results">
          {searchData && <SearchResultsRoute searchData={searchData} onSearchMoreRoutes={handleSearchMoreRoutes}  />}
          </div>
        </div>
      </div>
    }
  </div>
))}       
                  
        </div>
    )
}