import { Helmet } from "react-helmet";
import headerImage from "../../../images/dle-tours/city-walk/city-walk-header.jpg";
import inclusionImage from "../../../images/dle-tours/city-walk/Photo-by-Aningaaq-R-Carlsen-Visit-Greenland-Hovedbillede-scaled.jpg";
import wideImage from "../../../images/dle-tours/city-walk/citywalk-wideimage.jpg";
import programImage from "../../../images/dle-tours/city-walk/Photo-by-Mads-Pihl-Visit-Greenland-scaled.jpg";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function CityWalkInIlulissat(){
    const {t} = useTranslation(["common", "diskolineexplorer"]);
    const headerStyle = {
        backgroundImage: `url(${headerImage})`
    }
    const navigate = useNavigate();
    useLayoutEffect(() => {
        // Scroll to the top on load
        window.scrollTo(0, 0); 
      }, []);
      function handleGoBack(){
        navigate("/diskolineexplorer/hiking-trips");
      }
      function handleGoToExplorerArea(){
        navigate("/buy-ticket/93?selectedActivity=176")
      }
    return(
       <div className="diskoline-explorer-tour">
        {/*HEAD*/}
        <Helmet><title>{t('cityWalkInIlulissat')}| {t('diskoLineExplorer')}</title></Helmet>
        <div className="header header-shadow" style={headerStyle}>
                    <div className="header-content">
                        <p className="tagline">Disko Line Explorer</p>
                        <h1>{t('cityWalkInIlulissat')}</h1>
                    </div>
        </div>
        <div className="content">
                <div className="central_row">
                <div className="breadcrumb"><p><span className="breadcrumb-1" onClick={handleGoBack}>{t('hikingTrips')}</span>/<span className="breadcrumb-2">{t('cityWalkInIlulissat')}</span></p></div>
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('cityWalkInIlulissat')}</h2>
                    <p><b>{t('dle:cityWalkSubHeader')}</b></p>
                    <p>{t('dle:cityWalkDescription')}</p>
                    <br></br>
                    <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>
                    </div>
                <div className="row">
                    <div className="section">
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dle:tripIncludes')}</h2>
                    <ul>
                        <li>{t('dle:cityWalkInclusion1')}</li>
                        <li>{t('dle:cityWalkInclusion2')}</li>
                        <li>{t('dle:cityWalkInclusion3')}</li>
                        <li>{t('dle:cityWalkInclusion4')}</li>
                        <li>{t('dle:cityWalkInclusion5')}</li>
                        <li>{t('dle:cityWalkInclusion6')}</li>
                    </ul>
                    <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>

                    </div>
                    <div className="section">
                        <img src={inclusionImage} alt="Disko Line Explorer" className="img"/>
                        <p>{t('dle:cityWalkImage1')}</p>

                    </div>
                </div>
        </div>
        <div >
            <img src={wideImage} alt="Ilulissat" className="img video-div"/>
            </div>
        <div className="content">
                <div className="row">
                    <div className="section">
                        <img src={programImage} alt="Disko Line Boat" className="img"/>
                        <p>{t('dle:cityWalkImage2')}</p>
                    </div>
                    <div className="section">
                        <p className="tagline">Disko Line Explorer</p>
                        <h2>{t('dle:cityWalkHeader2')}</h2>
                        <p>{t('dle:cityWalkDescription2.1')}</p>
                        <br></br>
                        <p>{t('dle:cityWalkDescription2.2')}</p>
                        <br></br>
                        <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>
                    </div>
                </div>
                <div className="practical-info">
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dle:practical')} </h2>
                    <br></br>
                    <p><span className="bold">{t('dle:duration')}</span> {t('dle:cityWalkDuration')}</p>
                    <p><span className="bold">{t('dle:season')}</span> {t('dle:cityWalkSeason')}</p>
                    <p><span className="bold">{t('dle:note')}</span> {t('dle:cityWalkNote')} </p>
                    <br>
                    </br><button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')}</button>
        
                </div>
        </div>
       </div>
    )
}