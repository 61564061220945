import { useEffect, useState } from "react";
import config from "../configs/config";
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';

export default function MyJourneyDescription({ Id }) {
  const apiUrl = config.default.apiPath;
  const [description, setDescription] = useState('');
  const { i18n } = useTranslation();
  const languagesToIndex = {
    dk: 1,
    en: 2,
    gl: 3
  }

  useEffect(() => {
    async function fetchDescription(Id) {
      const response = await fetch( `${apiUrl}/System/GetFrontEndTexts/${Id}`, {
        headers: {
          'LanguageID': languagesToIndex[i18n.language]
        }
      });
      const data = await response.json();
      if(data.success && data.data.isActive){
        setDescription(data.data.frontEndText);
      }
    }
    fetchDescription(Id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl, i18n.language]);

  const sanitizedHtml = (htmlString) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  return (
    <div className="my-journey-desc">
      {description ? (<div dangerouslySetInnerHTML={sanitizedHtml(description)} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}