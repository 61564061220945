import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router"
import { toast } from "react-toastify";
import { useAuth } from "../../components/AuthContext";
import config from '../../configs/config';


export default function  PartnerUserList(){
const {t} = useTranslation(["common", "partner", "toastmessages"]);
const navigate = useNavigate();
const [partnerUsers, setPartnerUsers] = useState([]);
const [currentlyLoggedInUser, setCurrentlyLoggedInUser] = useState(0);
const {authenticated} = useAuth();
const [hasAccess, setHasAccess] = useState(false);
const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
const [deletingUserID, setDeletingUserID] = useState(0);
const [deletingUserName, setDeletingUserName] = useState("");
const apiUrl = config.default.apiPath;
async function checkUserHasAccess(){
  if(authenticated)
  {
    const authToken = Cookies.get("authToken");
    const selectedPartnerGroup = JSON.parse(Cookies.get("selectedPartnerGroup"));
    const partnerUserID = selectedPartnerGroup.partnerUserID;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await fetch(`${apiUrl}/Partner/GetPartnerUserByPartnerUserID/${partnerUserID}`,{
      headers,
    });
    const data = await response.json();
    setHasAccess(data.isSuper);
  }
}

async function getCurrentlyLoggedInUser(){
  if(authenticated)
  {
    const selectedPartnerGroup = JSON.parse(Cookies.get("selectedPartnerGroup"));
    const partnerUserID = selectedPartnerGroup.partnerUserID;
    setCurrentlyLoggedInUser(partnerUserID);
  }
}
useEffect(() => {
  checkUserHasAccess();
  getCurrentlyLoggedInUser();
})
async function getCurrentPartnerGroupUsers() {
    const authToken = Cookies.get("authToken");
    if (!authToken) {
      throw new Error("Authentication token not found in cookies");
    }
    const currentSelectedPartner = JSON.parse(Cookies.get("selectedPartnerGroup"));
    const partnerGroupID = currentSelectedPartner.partnerGroupID;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await fetch(`${apiUrl}/Partner/GetAllUsersInPartnerGroup/${partnerGroupID}/`, {
      headers,
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
    const data = await response.json();
    setPartnerUsers(data);
  }
  useEffect(() => {
    getCurrentPartnerGroupUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

async function sendResetPassword(id, partnerEmail, partnerName){
    const randomStr = Math.random().toString(20);
    const urlToken = `/Partner/PartnerUserChangePassword?PartnerUserID=${id}&token=${randomStr}`
    const emailRecordRequest = {
        MailTemplateID: 1,
        SendTo: partnerEmail,
        Token: urlToken,
    }
    try{
        const sendEmailRecord = await fetch(`${apiUrl}/Email/InsertNewSendMailResetPasswordPartnerUser`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(emailRecordRequest),
        }
        )
    
        if(sendEmailRecord.ok){
        const mailTemplate = await fetch(`${apiUrl}/Email/GetMailTemplateByMailTemplateID/1/2`).then(result => result.json());
        const host = await fetch(`${apiUrl}/System/GetGlobalSetup`).then(result => result.json());
        const hostName = host.hostName;
        const emailRequestBody = {
            MailTemplateID: 1,
            SendTo: partnerEmail,
            IsSent: false,
            Params: "subject{@user},body{@user,@linkresetpassword}",
            Values: `subject{${partnerName}},body{ ${partnerName},${hostName}${urlToken}}`,
            Subject: mailTemplate.subject,
            Body: mailTemplate.body,
        }

        const sendForgottenPassword = await fetch(`${apiUrl}/Email/SendMailAtOnce`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(emailRequestBody),
          }
        )
        if(sendForgottenPassword.ok){
            const emailSend = t("toastmessages:emailSendTo");
            toast.success(`${emailSend} ${partnerEmail}`);
        }
    }
} catch (error) {
    console.error("An error occurred while saving changes:", error);
    const errorOccured = t("toastmessages:errorTryAgain");
    toast.error(errorOccured);
  }
};

const handleConfirmDeleteUser = async () => {
  if (deletingUserID) {
    await handleDeletePartnerUser(deletingUserID);
    setConfirmDeleteUser(false);
    getCurrentPartnerGroupUsers();
  }
};
async function handleDeletePartnerUser(){
    const authToken = Cookies.get("authToken");
    if (!authToken) {
      throw new Error("Authentication token not found in cookies");
    }
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await fetch(`${apiUrl}/Partner/DeletePartnerUserFromFrontEnd/${deletingUserID}`, {
      method: "DELETE",
      headers,
    });
    const data = await response.json();
    if (response.ok && data.success !==  false) {
      const deletedUser = t("partner:toastDeletedUser");
      toast.success(`${deletingUserName} ${deletedUser} `);
    } else {
      const errorOccured = t("toastmessages:errorTryAgain");
      toast.error(errorOccured);
    };
  };
return(
    <div className="partneruserlist">
            <Helmet>
                <title>{t("partner:userList")} | Disko Line {t("greenland")}</title>
            </Helmet>
            <h1>{t("partner:userList")}</h1>
            {hasAccess ? (
            <div>
            <table className="user-table">
                <thead>
                    <tr>
                        <th>
                            <p>{t("partner:name")}</p>
                        </th>
                        <th>
                            <p>E-mail</p>
                        </th>
                        <th>
                            <p>{t("partner:role")}</p>
                        </th>
                        <th>
                            <p className="actions">{t("partner:actions")}</p>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {partnerUsers.map((user, index) => (
                    <tr key={index}>
                    <td>{user.partnerName}</td>
                    <td>{user.partnerEmail}</td>
                    <td>{user.isSuper ? "SuperUser" : "User"}</td>
              <td>
                {authenticated && user.id !== currentlyLoggedInUser && (
                  <div className="actions">
                <button className="btn-secondary" onClick={()=>sendResetPassword(user.id, user.partnerEmail, user.partnerName)}>{t("partner:reset")} {t("partner:password")}</button>
                <button className="btn" onClick={() => {setConfirmDeleteUser(true); setDeletingUserID(user.id); setDeletingUserName(user.partnerName)}}>{t("partner:deleteUser")}</button>
                  </div>
                )}
              </td>
            </tr>
          ))}
              </tbody>
            </table>
               {/* Confirmation modal */}
        {confirmDeleteUser && (
        <div className="confirmation-modal">
          <div className="confirmation-modal-content">
          <p>{t("partner:deleteUserConfirmation")} {deletingUserName}?</p>
          <div className="confirmation-modal-buttons">
            <button onClick={handleConfirmDeleteUser} className="btn">{t("yes")}</button>
            <button onClick={() => setConfirmDeleteUser(false)} className="btn">{t("no")}</button>
          </div>
          </div>
        </div>
      )}
            <div className="buttons">
                    <button onClick={()=> navigate("/partner")} className="btn-secondary">{t("partner:backToMyAccount")}</button>
                    <button onClick={()=> navigate("/partner/create-user")} className="btn">{t("partner:createUser")}</button>
            </div>
        </div>
        ):(<div className="buttons">
          <p>{t("partner:noAccess")}</p>
          <button className="btn" onClick={() => navigate(-1)}>{t("partner:back")}</button>
          </div>) }
        </div>
    )
}