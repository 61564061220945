import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket } from '@fortawesome/free-solid-svg-icons'
import config from '../../configs/config';
import enums from '../../commons/Enums'

export default function PartnerOrderDetail() {
  const location = useLocation();
  const orderID = location.state.orderID;
  const selectedPartnerGroup = JSON.parse(Cookies.get("selectedPartnerGroup"));
  const partnerGroupID = selectedPartnerGroup.partnerGroupID;
  const [showActiveTickets, setShowActiveTickets] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const { t, i18n } = useTranslation(["common", "partner"]);
  const navigate = useNavigate();
  const apiUrl = config.default.apiPath;
  const [confirmCancelTicket, setConfirmCancelTicket] = useState(false);
  const [cancellingTicketID, setCancellingTicketID] = useState(null);
  const { languagesToIndex } = enums;
  const [routeCancelTime, setRouteCancelTime] = useState(null);
  const routeCancelTimeMs = routeCancelTime * 60 * 1000;
  const [activityCancelTime, setActivityCancelTime] = useState(null);
  const activityCancelTimeMs = activityCancelTime * 60 * 1000;
  const [serverLocalDate, setServerLocalDate] = useState(null);
  const serverUtcToDate = new Date(serverLocalDate);
  console.log("server date:", serverUtcToDate);
  console.log("Activity Cancel Time:", activityCancelTimeMs);
  console.log("Order details:", orderDetails);

  const [ticketNameModified, setTicketNameModified] = useState(false);
  const [ticketPhoneModified, setTicketPhoneModified] = useState(false);

  const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // Return empty string if dateString is null or undefined
    }
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const formatTime = (dateTimeString) => {
    if (!dateTimeString) {
      return "";
    }
    var date = new Date(dateTimeString);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');
  };

  const formatNumber = (number) => {
    // Convert number to string and split into integer and decimal parts
    const parts = number.toFixed(2).toString().split('.');
    // Replace dot with comma as decimal separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    // Return formatted number
    return parts.join(',');
  };

  //UPDATE TICKET NAME
  const updateTicketName = async (ticketID, newTicketName) => {
    try {
      const authToken = Cookies.get("authToken");
      if (!authToken) {
        throw new Error("Authentication token not found in cookies");
      }
      const headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };
      const requestBody = {
        ticketID: ticketID,
        ticketCustomerName: newTicketName,
        partnerGroupID: partnerGroupID,
      };
      const response = await fetch(`${apiUrl}/Partner/UpdateTicketName`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Failed to update ticketName: ${response.statusText}`);
      }


      // Assuming the API response contains updated ticket information
      const updatedTicket = await response.json();

      // Update the orderDetails state with the updated ticket information
      setOrderDetails((prevOrderDetails) =>
        prevOrderDetails.map((order) =>
          order.ticketID === ticketID
            ? { ...order, ticketCustomerName: updatedTicket.ticketCustomerName }
            : order
        )
      );
    } catch (error) {
      console.error("Error updating ticketName:", error);
    }
  };

  const updateTicketPhoneNo = async (ticketID, newTicketPhoneNo) => {
    try {
      const authToken = Cookies.get("authToken");
      if (!authToken) {
        throw new Error("Authentication token not found in cookies");
      }
      const headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };
      const requestBody = {
        ticketID: ticketID,
        ticketCustomerPhone: newTicketPhoneNo,
        partnerGroupID: partnerGroupID,
      };
      const response = await fetch(`${apiUrl}/Partner/UpdateTicketPhoneNo`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Failed to update Phone Number: ${response.statusText}`);
      }


      // Assuming the API response contains updated ticket information
      const updatedTicket = await response.json();

      // Update the orderDetails state with the updated ticket information
      setOrderDetails((prevOrderDetails) =>
        prevOrderDetails.map((order) =>
          order.ticketID === ticketID
            ? { ...order, ticketCustomerPhone: updatedTicket.ticketCustomerPhone }
            : order
        )
      );
    } catch (error) {
      console.error("Error updating ticketName:", error);
    }
  };
  const handleTicketNameKeyPress = (e, ticketID, currentTicketName) => {
    if (e.key === "Enter") {
      updateTicketName(ticketID, currentTicketName);
    }
  };


  const handleTicketPhoneKeyPress = (e, ticketID, currentTicketPhone) => {
    if (e.key === "Enter") {
      updateTicketPhoneNo(ticketID, currentTicketPhone);
    }
  };

  //GET ORDER DETAIL
  const getOrderDetail = async () => {
    try {
      const authToken = Cookies.get("authToken");
      if (!authToken) {
        throw new Error("Authentication token not found in cookies");
      }
      const headers = {
        Authorization: `Bearer ${authToken}`,
        LanguageID: languagesToIndex[i18n.language],
      };
      const response = await fetch(`${apiUrl}/Partner/GetPartnerOrderDetail?orderID=${orderID}&partnerGroupID=${partnerGroupID}`, {
        headers,
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch order details: ${response.statusText}`);
      }
      const data = await response.json();
      const formattedOrderDetails = data.map((order) => ({
        ...order,
        postedDocumentCreationDate: formatDate(order.postedDocumentCreationDate),
        departureDate: formatDate(order.departureDate),
        departureTime: formatTime(order.departureTime),
        rawDepartureDate: new Date(order.departureDate).toISOString(),
        rawDepartureTime: new Date(order.departureTime),
      }));
      setOrderDetails(formattedOrderDetails);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const getServerLocalTime = async () => {
    try {
      const response = await fetch(`${apiUrl}/System/GetGreenlandServerTime`, {
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      })
      if (!response.ok) {
        throw new Error('Network response for server time was not ok');
      }
      const data = await response.json();
      setServerLocalDate(data.data);

    } catch (error) {
      console.error("Error fetching server's local time:", error);
    }
  };

  const getTicketCancelTime = async () => {
    try {
      const response = await fetch(`${apiUrl}/System/GetCancelTime`, {
        method: "GET",
      })
      if (!response.ok) {
        throw new Error('Network response for ticket cancel time was not ok');
      }
      
        const data = await response.json();
        setRouteCancelTime(data.routeCancelTime);
        setActivityCancelTime(data.activityCancelTime);

    } catch (error) {
      console.error("Error fetching ticket cancel time:", error);
    }
  };

  const handleConfirmCancel = async () => {
    if (cancellingTicketID) {

      await handleCancelTicket(cancellingTicketID);

      getOrderDetail();

      setConfirmCancelTicket(false);
    }
  };

  const handleCancelTicket = async (ticketID) => {
    try {
      // Create the request body
      const requestBody = {
        ticketIDs: [ticketID],
        orderID: orderID,
      };
      // Send the request to cancel ticket
      const response = await fetch(`${apiUrl}/Cart/UserCancelTicket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        if (responseData.success === true) {
          toast.success(t("cancelTicketSuccess"));
        } else {
          toast.error(responseData.errorMessage);
        }
      }
    } catch (error) {
      toast.error(error.message || t("errorMessages.genericError"));
    }
  };

  useEffect(() => {
    getOrderDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderDetails) {
      const sum = orderDetails.reduce((total, order) => total + order.price, 0);
      setTotalPrice(sum);
    }
  }, [orderDetails]);
  
  useEffect(() => {
    getTicketCancelTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getServerLocalTime();
    };
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredOrderDetails = showActiveTickets
    ? orderDetails.filter((order) => order.status === "Aktiv" || order.status === "Active")
    : orderDetails;

  const handleGoBack = () => {
    navigate("/partner/order-list");
  }

  return (
    <div className="partnerorderdetailpage">
      <Helmet>
        <title>Order Detail | Disko Line Greenland</title>
      </Helmet>
      <h1>{t("partner:orderDetail")}</h1>

      {orderDetails ? (
        <div>
          <p className="order-detail-info">{t("partner:orderDetailInfo")} <span className="order-detail-orderID">{orderID}</span></p>
          <div className="center-container">
            <p>{t('partner:showActiveTickets')}</p>
            <div className="pill-box">
              <div className="toggle-switch">
                <label>
                  <input
                    type="checkbox"
                    checked={showActiveTickets}
                    onChange={() => setShowActiveTickets(!showActiveTickets)}
                  />

                  <span className="switch"></span>
                </label>
              </div>
            </div>
          </div>
          <table className="order-table">
            <thead>
              <tr>
                <th>
                  <p>{t("partner:description")}</p>
                </th>
                <th>
                  <p>{t('partner:ticketNumber')}</p>
                </th>
                <th>
                  <p>{t("partner:departureDate")}</p>
                </th>
                <th>
                  <p>{t("partner:from")}</p>
                </th>
                <th>
                  <p>{t("partner:ticketName")}</p>
                </th>
                <th>
                  <p>{t("partner:telephoneNumber")}</p>
                </th>
                <th>
                  <p>{t("partner:postedDocumentDate")}</p>
                </th>
                <th>
                  <p>{t("partner:status")}</p>
                </th>
                <th>
                  <p className="price">{t("partner:price")}</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredOrderDetails.map((order, index) => (

                <tr key={index} className={order.status === 'Aktiv' || order.status === 'Active' ? 'green-row' : order.status === 'Annulleret' || order.status === 'Cancelled' ? 'red-row' : ''}>
                  <td>{order.name}</td>
                  <td>{order.ticketID}</td>
                  <td>{order.departureDate}</td>
                  <td>{order.departureTime}</td>
                  <td>
                    {order.ticketID !== null ? (
                      <>
                        {(order.status === "Aktiv" || order.status === "Active") && order.isEditable === true ? ( // Added isEditable to make passenger name editable up to 15 minutes before departure
                          <input
                            type="text"
                            value={order.ticketCustomerName}
                            onChange={(e) => {
                              const updatedOrderDetails = [...orderDetails];
                              updatedOrderDetails[index].ticketCustomerName = e.target.value;
                              setOrderDetails(updatedOrderDetails);
                              setTicketNameModified(true); // Set modified state to true
                            }}
                            onBlur={() => {
                              if (ticketNameModified) { // Check if modified
                                updateTicketName(order.ticketID, order.ticketCustomerName);
                                setTicketNameModified(false); // Reset modified state
                              }
                            }}
                            onKeyPress={(e) => handleTicketNameKeyPress(e, order.ticketID, order.ticketCustomerName)}

                          />
                        ) : (
                          <span>{order.ticketCustomerName}</span>
                        )}
                      </>
                    ) : (
                      <span></span>
                    )}
                  </td>
                  <td>
                    {order.ticketID !== null ? (
                      <>
                        {(order.status === "Aktiv" || order.status === "Active") ? ( // Removed isTicketNotOverdue & isEditable to make phone number editable at all times
                          <input
                            type="text"
                            value={order.ticketCustomerPhone}
                            onChange={(e) => {
                              const updatedOrderDetails = [...orderDetails];
                              updatedOrderDetails[index].ticketCustomerPhone = e.target.value;
                              setOrderDetails(updatedOrderDetails);
                              setTicketPhoneModified(true); // Set modified state to true
                            }}
                            onBlur={() => {
                              if (ticketPhoneModified) { // Check if modified
                                updateTicketPhoneNo(order.ticketID, order.ticketCustomerPhone);
                                setTicketPhoneModified(false); // Reset modified state
                              }
                            }}
                            onKeyPress={(e) => handleTicketPhoneKeyPress(e, order.ticketID, order.ticketCustomerPhone)}

                          />
                        ) : (
                          <span>{order.ticketCustomerPhone}</span>
                        )}
                      </>
                    ) : (
                      <span></span>
                    )}
                  </td>
                  <td>{order.postedDocumentCreationDate}</td>
                  <td>{order.status}</td>
                  <td ><p className="align-right">{formatNumber(order.price)} DKK</p></td>
                  <td>
                    {(order.isActivity === true) ? (
                      (order.status === "Aktiv" || order.status === "Active") && 
                      (serverUtcToDate.getTime() < (order.rawDepartureTime.getTime() - activityCancelTimeMs)) &&
                      (order.ticketID !== null) ? (
                        <div className="cancel-ticket pointer" title={t("partner:cancelTicket")} onClick={() => {
                          setConfirmCancelTicket(true);
                          setCancellingTicketID(order.ticketID);
                        }}>
                          <FontAwesomeIcon icon={faTicket} />
                        </div>
                      ) : null
                    ) : (order.isActivity === false) ? (
                      (order.status === "Aktiv" || order.status === "Active") && 
                      (serverUtcToDate.getTime() < (order.rawDepartureTime.getTime() - routeCancelTimeMs)) &&
                      (order.ticketID !== null) ? (
                        <div className="cancel-ticket pointer" title={t("partner:cancelTicket")} onClick={() => {
                          setConfirmCancelTicket(true);
                          setCancellingTicketID(order.ticketID);
                        }}>
                          <FontAwesomeIcon icon={faTicket} />
                        </div>
                      ) : null
                    ) : null}
                  </td>
                </tr>
              ))}
              {/* Confirmation modal */}
              {confirmCancelTicket && (
                <div className="confirmation-modal">
                  <div className="confirmation-modal-content">
                    <p>{t("partner:ticketCancelConfirm")}</p>
                    <div className="confirmation-modal-buttons">
                      <button onClick={handleConfirmCancel} className="btn">{t("yes")}</button>
                      <button onClick={() => setConfirmCancelTicket(false)} className="btn">{t("no")}</button>
                    </div>
                  </div>
                </div>
              )}
              <tr>
                <td ><p className="total-amount align-left">{t("partner:totalAmount")}</p></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><p className="align-right bold">{formatNumber(totalPrice)} DKK</p></td>
                <td></td>
              </tr>
              <td></td>
            </tbody>
          </table>

          <div className="button-center">
            <button className="btn" onClick={handleGoBack}>{t("partner:backToOrders")}</button>
          </div>
        </div>
      ) : (
        <p>Loading order details...</p>
      )}
    </div>
  )
}