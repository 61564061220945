// In a new file (e.g., SearchContext.js)
import { createContext, useContext, useState } from "react";

const SearchContext = createContext({
  searchData: null,
  setSearchData: () => {},
});

export function SearchProvider({ children }) {
  const [searchData, setSearchData] = useState(null);

  return (
    <SearchContext.Provider value={{ searchData, setSearchData }}>
      {children}
    </SearchContext.Provider>
  );
}

export function useSearchData() {
  return useContext(SearchContext);
}
