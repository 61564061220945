import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import TopasExplorerGroupLogo from "../images/topasexplorergroup/topas-explorer-group.png";
import TopasTravelLogo from "../images/topasexplorergroup/topastravellogo.png";
import GreenlandByTopas from "../images/topasexplorergroup/greenlandbytopaslogo.png";
import VietnamByTopas from "../images/topasexplorergroup/vietnambytopaslogo.png";
import DiskoLineExplorerLogo from "../images/topasexplorergroup/diskolineexplorerlogo.png";
import BlueIceExplorerLogo from "../images/topasexplorergroup/blueiceexplorerlogo.png";
import HotelDiskoBayLogo from "../images/topasexplorergroup/hoteldiskobaylogo.png";
import HotelDiskoIslandLogo from "../images/topasexplorergroup/hoteldiskoislandlogo.png";
import HotelIcefiordLogo from "../images/topasexplorergroup/hotelicefiordlogo.png";
import NuukWaterTaxiLogo from "../images/topasexplorergroup/watertaxilogo.png";
import VietnamTrailSeriesLogo from "../images/topasexplorergroup/vietnamtrailserieslogo.png";
import TopasEcolodgeLogo from "../images/topasexplorergroup/topasecolodgelogo.png";
import TopasMountainExpressLogo from "../images/topasexplorergroup/topasmountainexpresslogo.png";

export default function TopasExplorerGroup(){

const {t} = useTranslation(["common", "topasexplorergroup"]);
useLayoutEffect(() => {
    // Scroll to the top on load
    window.scrollTo(0, 0); 
  }, []);
    return(
        <div className="topas-explorer-group-page">
            <Helmet>
                <title>Topas Explorer Group | Disko Line {t("greenland")}</title>
            </Helmet>
            <main>
                <h1>Topas Explorer Group</h1>
                <section className="topas-explorer-group">
                <div className="content explorer-group">
                <img src={TopasExplorerGroupLogo} alt="Topas-Explorer-Group" />
                <div className="motto-and-text">
                <p className="topas-motto">We travel not to escape life, but for life not to escape us.</p>
                <p><a href="https://topasexplorergroup.com">Topas Explorer Group</a> {t('topasexplorergroup:topasExplorerGroupText')}</p>
                    </div>
                </div>
                </section>
                <section className="section-odd">
                <div className="section-content">
                <h2>{t('topasexplorergroup:denmark')}</h2>
                <div className="company">
                    <div className="company-logo">
                    <img src={TopasTravelLogo} alt="Topas Travel" />
                    </div>
                    <div className="company-text">
                    <h3>Topas Travel</h3>
                    <p>{t('topasexplorergroup:topasText')}</p>
                    <p className="link"><a href="https://topas.dk">www.topas.dk</a></p>
                    </div>
                </div>
                <div className="company">
                <div className="company-logo">
                <img src={GreenlandByTopas} alt="Greenland By Topas" />
                </div>
                <div className="company-text">
                    <h3>Greenland by Topas</h3>
                    <p>{t('topasexplorergroup:greenlandByTopasText')}</p>
                    <p className="link"><a href="https://greenlandbytopas.dk">www.greenlandbytopas.dk</a><span className="seperator">|</span><a href="https://greenlandbytopas.com">www.greenlandbytopas.com</a></p>
                    </div>
                </div>
                <div className="company">
                <div className="company-logo">
                <img src={VietnamByTopas} alt="Vietnam By Topas" />
                </div>
                <div className="company-text">
                    <h3>Vietnam by Topas</h3>
                    <p>{t('topasexplorergroup:vietnamByTopasText')}</p>
                    <p className="link"><a href="https://vietnambytopas.dk">www.vietnambytopas.dk</a><span className="seperator">|</span><a href="https://vietnambytopas.com">www.vietnambytopas.com</a></p>
                    </div>
                </div>
                </div>
                </section>
                <section className="section-even">
                <div className="section-content">
                 <h2>{t('topasexplorergroup:greenland')}</h2>   
                 <div className="company">
                 <div className="company-logo">
                 <img src={DiskoLineExplorerLogo} alt="Disko Line Explorer" />
                 </div>
                 <div className="company-text">
                 <h3>Disko Line Explorer</h3>
                 <p>{t('topasexplorergroup:diskoLineExplorerText')}</p>
                 <p className="link"><a href="https://diskolineexplorer.com">www.diskolineexplorer.com</a></p>
                 </div>
                 </div>
                 <div className="company">
                 <div className="company-logo">
                 <img src={BlueIceExplorerLogo} alt="Blue Ice Explorer" />
                 </div>
                 <div className="company-text">
                 <h3>Blue Ice Explorer</h3>
                 <p>{t('topasexplorergroup:blueIceExplorerText')}</p>
                 <p className="link"><a href="https://blueiceexplorer.gl">www.blueiceexplorer.gl</a></p>
                 </div>
                 </div>
                 <div className="company">
                 <div className="company-logo">
                 <img src={HotelDiskoBayLogo} alt="Hotel Diskobay" />
                 </div>
                 <div className="company-text">
                 <h3>Hotel Diskobay</h3>
                 <p>{t('topasexplorergroup:hotelDiskobayText')}</p>
                 <p className="link"><a href="https://hoteldiskobay.com">www.hoteldiskobay.com</a></p>
                 </div>
                 </div>
                 <div className="company">
                 <div className="company-logo">
                 <img src={HotelDiskoIslandLogo} alt="Hotel Disko Island" />
                 </div>
                 <div className="company-text">
                 <h3>Hotel Disko Island</h3>
                 <p>{t('topasexplorergroup:hotelDiskoIslandText')}</p>
                 <p className="link"><a href="https://hoteldiskoisland.com">www.hoteldiskoisland.com</a></p>
                 </div>
                 </div>
                 <div className="company">
                 <div className="company-logo">
                 <img src={HotelIcefiordLogo} alt="Hotel Icefiord" />
                 </div>
                 <div className="company-text">
                 <h3>Hotel Icefiord</h3>
                 <p>{t('topasexplorergroup:hotelIcefiordText')}</p>
                 <p className="link"><a href="https://hotelicefiord.com">www.hotelicefiord.com</a></p>
                 </div>
                 </div>
                 <div className="company">
                 <div className="company-logo">
                 <img src={NuukWaterTaxiLogo} alt="Nuuk Water Taxi" />
                 </div>
                 <div className="company-text">
                 <h3>Nuuk Water Taxi</h3>
                 <p>{t('topasexplorergroup:nuukWaterTaxiText')}</p>
                 <p className="link"><a href="https://watertaxi.gl">www.watertaxi.gl</a></p>
                 </div>
                 </div>
                 </div>
                </section>
                <section className="section-odd">
                <div className="section-content">
                <h2>{t('topasexplorergroup:vietnam')}</h2>
                <div className="company">
                <div className="company-logo">
                <img src={TopasTravelLogo} alt="Topas Travel Vietnam" />
                </div>
                <div className="company-text">
                <h3>Topas Travel Vietnam</h3>
                <p>{t('topasexplorergroup:topasTravelVietnamText')}</p>
                <p className="link"><a href="https://topastravel.vn">www.topastravel.vn</a></p>
                </div>
                </div>
                <div className="company">
                <div className="company-logo">
                <img src={VietnamTrailSeriesLogo} alt="Vietnam Trail Series" className="trail-series-logo"/>
                </div>
                <div className="company-text">
                <h3>Vietnam Trail Series</h3>
                <p>{t('topasexplorergroup:vietnamTrailSeriesText')}</p>
                <p className="link"><a href="https://vietnamtrailseries.com">www.vietnamtrailseries.com</a></p>
                </div>
                </div>
                <div className="company">
                <div className="company-logo">
                <img src={TopasEcolodgeLogo} alt="Topas Ecolodge, Sapa" />
                </div>
                <div className="company-text">
                <h3>Topas Ecolodge, Sapa</h3>
                <p>{t('topasexplorergroup:topasEcolodgeSapaText')}</p>
                <p className="link"><a href="https://topasecolodge.com">www.topasecolodge.com</a></p>
                </div>
                </div>
                <div className="company">
                <div className="company-logo">
                <img src={TopasMountainExpressLogo} alt="Topas Mountain Express" />
                </div>
                <div className="company-text">
                <h3>Topas Mountain Express</h3>
                <p>{t('topasexplorergroup:topasMountainExpressText')}</p>
                <p className="link"><a href="https://topasmountainexpress.com">www.topasmountainexpress.com</a></p>
                </div>
                </div>
                </div>
                </section>
            </main>
        </div>
    )
}