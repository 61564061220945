//USED WITH LANGUAGESELECTOR COMPONENT - FOR TRANSLATION ACROSS SITE

//WHERE ID IS NEEDED
export function mapLanguageCodeToId(languageCode) {
    const languageMappings = {
      dk: 1, // Danish
      en: 2, // English
      gl: 3, // Greenlandic
    };
    return languageMappings[languageCode] || 1; // Default to Danish if not found
  }
//WHERE COUNTRY CODE IS NEEDED
  export function mapLanguageCodeToCountryCode(languageCode) {
    const languageMappings = {
      dk: "DK", 
      en: "US", 
      gl: "GL", 
    };
    return languageMappings[languageCode] || "GL"; 
  }