import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "./CartContext";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import { useAuth } from "./AuthContext";
import { useForm } from "./FormContext";
import config from '../configs/config';
import { useSearchData } from "./SearchContext";
import enums from '../commons/Enums'

export default function SearchResultRoute({ onSearchMoreRoutes }) {
  const { t, i18n } = useTranslation(["common", "buyticket"]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [clicked, setClicked] = useState(false);
  const { fetchCartItems } = useCart();
  const { authenticated, checkTokenExpiry } = useAuth();
  const apiUrl = config.default.apiPath;
  const { formRequest, setFormRequest } = useForm();
  const [noMoreResults, setNoMoreResults] = useState(false);
  const [showWithTransfers, setShowWithTransfers] = useState(false);
  const { searchData } = useSearchData();
  const { languagesToIndex } = enums;
  useEffect(() => {
    // Reset noMoreResults to false whenever searchData changes
    setNoMoreResults(false);
    setShowWithTransfers(false);
  }, [searchData]);
  if (!searchData || !searchData.journeyWithoutTransfers || !searchData.journeyWithTransfers) {
    // Journeys data is missing or not in the expected format, display an error message or handle it accordingly
    return <p>Error: Search data is not in the expected format.</p>;
  }
  checkTokenExpiry();
    var selectedPartnerGroupStr = Cookies.get('selectedPartnerGroup')
    const selectedPartnerGroup = authenticated && selectedPartnerGroupStr
    ? JSON.parse(selectedPartnerGroupStr) : null;

  const formatNumber = (number) => {
    // Convert number to string and split into integer and decimal parts
    const parts = number.toFixed(2).toString().split('.');
    // Replace dot with comma as decimal separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    // Return formatted number
    return parts.join(',');
  };


  const { journeyWithoutTransfers, journeyWithTransfers } = searchData;
  const noResultsFound = journeyWithoutTransfers.length === 0 && journeyWithTransfers.length === 0;

  const handleSearchMoreWithoutTransfers = async () => {
    const departureDate = new Date(formRequest.departureDate);
    const year = departureDate.getFullYear();
    const day = String(departureDate.getDate()).padStart(2, '0');
    const month = String(departureDate.getMonth() + 1).padStart(2, '0');
    const formattedDate = `${year}-${day}-${month}`;
    const nextPage = formRequest.page + 1;
    // Update page number
    setFormRequest((prevData) => ({
      ...prevData,
      page: nextPage,
    }));
    const apiURL = `${apiUrl}/Cart/SearchRouteJourney`;
    const requestBody = {
      departureStopID: formRequest.departureStopID,
      arrivalStopID: formRequest.arrivalStopID,
      departureDate: formattedDate,
      numberTicketTypes: formRequest.numberTicketTypes,
      page: nextPage,
      isTransfer: false,
      isSearchEarlier: formRequest.isSearchEarlier,
      isSearchActivity: formRequest.isSearchActivity,
      ...(authenticated && selectedPartnerGroup && { partnerGroupID: selectedPartnerGroup.partnerGroupID }),
    };
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "LanguageID": languagesToIndex[i18n.language],
          ...(authenticated && { Authorization: `Bearer ${Cookies.get('authToken')}` }),
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return Promise.reject(errorData);
      }
      const data = await response.json();
      console.log(data);
      if (data.journeyWithoutTransfers.length === 0) {
        setNoMoreResults(true);
      } else {
        // Update the searchData with the new data
        onSearchMoreRoutes(data);
      }
    } catch (error) {
      console.error('Error fetching more data:', error);
    }
  };




  //More Details About Journey 
  const handleDetailsClick = async (activeDepartureIDs, position) => {
    try {
      const headers = {
        'Content-Type': 'text/plain',
        'LanguageID': languagesToIndex[i18n.language]
      };
      const response = await fetch(
        `${apiUrl}/Cart/GetDetailRouteJourney?activeDepartureIDs=${activeDepartureIDs}`,
        {
          method: 'GET', // GET is the default method, so you can omit it
          headers: headers,
        }
      );
      const data = await response.json();

      setModalContent({ data, position });
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching trip details:", error);
    }
  };



  //ADD TO CART
  async function handleAddToCart(activeDepartureIDs, includePartnerGroupID = false) {
    if (clicked) {
      return;
    }
    setClicked(true);
    try {
      const apiURL = `${apiUrl}/Cart/AddTicketToCart`;
      const sessionId = Cookies.get('sessionID');
      const numberTicketTypes = JSON.parse(localStorage.getItem("numberTicketTypes"));
      const requestBody = {
        sessionId,
        activeDepartureIDs,
        numberTicketTypes,
      };

      // Automatically set includePartnerGroupID to true if the user is authenticated
      if (authenticated) {
        checkTokenExpiry();
        const selectedPartnerGroup = JSON.parse(Cookies.get('selectedPartnerGroup'));
        requestBody.partnerGroupID = selectedPartnerGroup.partnerGroupID;
        includePartnerGroupID = true;
      } else {
        // If not authenticated, make sure includePartnerGroupID is false
        includePartnerGroupID = false;
      }

      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
          // Include Authorization header only if partnerGroupID is included and user is authenticated
          ...(includePartnerGroupID && {
            Authorization: `Bearer ${Cookies.get('authToken')}`,
          }),
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        await fetchCartItems();
        navigate("/my-cart");
      } else {
        console.error('Failed to add to cart.');
      }
    } catch (error) {
      console.error("An error occurred while adding to cart:", error);
    }
    finally {
      setClicked(false);
    }
  }


  //MODAL FUNCTION
  function RouteDetailsModal({ content, onClose }) {
    const { position, data } = content || {};
    const ticketTypePrices = data?.ticketTypePrices || [];
    const itineraries = data?.itineraries || [];
    useEffect(() => {
      const handleClickOutsideModal = (event) => {
        const modalContainer = document.querySelector(".details-modal");
        if (modalContainer && !modalContainer.contains(event.target)) {
          onClose(); // Close the modal when clicked outside
        }
      };

      document.addEventListener("click", handleClickOutsideModal);

      return () => {
        document.removeEventListener("click", handleClickOutsideModal);
      };
    }, [onClose]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 810);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 810);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    // Calculate the modal position based on the button's position
    let modalStyleDesktop = {
      position: "absolute",
      top: position ? position.y + "px" : 0,
      left: position ? 300 + "px" : 0,
      transform: "translateY(10px)",
      backgroundColor: "#fff",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      padding: "10px",
      zIndex: 9999,
      textAlign: "center",
    };
    let modalStyleMobile = {
      position: "absolute",
      top: position ? position.y + "px" : 0,
      left: position ? 100 + "px" : 0,
      transform: "translateY(10px)",
      backgroundColor: "#fff",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      padding: "10px",
      zIndex: 9999,
      textAlign: "center",
    };
    return (
      <div className="details-modal" style={isMobile ? modalStyleMobile : modalStyleDesktop}>
        <div>
          <div className="modal-content">
            {data && (
              <div className="ticket-types">
                <h3>{t('buyticket:ticketPrices')}</h3>
                {ticketTypePrices.length > 0 ? (
                  ticketTypePrices.map((price) => (
                    <div key={price.ticketTypeID} className="ticket-type">
                      <p className="bold">{price.ticketTypeName}: </p>
                      <p>{formatNumber(price.price)} DKK</p>
                    </div>
                  ))
                ) : (
                  <p>No ticket type prices available.</p>
                )}
              </div>
            )}
            <div className="itineraries">
              <h3>{t("buyticket:itinerary")}</h3>
              {itineraries.map((trip, index) => (
                <div key={trip.activeDepartureID} className="itinerary">
                  <p>{trip.departureStopName}</p>
                  <p className="divider">-</p>
                  <p>{trip.arrivalStopName}</p>
                  <p>{trip.departureTime.slice(11, 16)}</p>
                  <p className="divider">-</p>
                  <p>{trip.arrivalTime.slice(11, 16)}</p>
                  {index < itineraries.length - 1 && itineraries[index].isTransfer !== itineraries[index + 1].isTransfer && (
                    <div key={`divider-${index}`} className="transfer-divider">
                      <p>∼{t('buyticket:transfer')}∼</p>
                    </div>
                  )}
                </div>
              ))}

            </div>
          </div>
        </div>
        <button className="modal-close" onClick={onClose}>
          {t("buyticket:closeDetails")}
        </button>
      </div>
    );
  };

  return (
    <div>
      {noResultsFound ? (
        <p className="no-results">{t("noResultFound")}</p>
      ) : (
        <>
          <div className="search-results route">
            <h2>{t("buyticket:selectJourney")}</h2>
            <div className="search-result-list-container">

              {journeyWithTransfers.length > 0 ? (
                <ul className="nav-tabs">
                  <li><span></span></li>
                  <li className={!showWithTransfers ? "active" : ""}><p href="#journeysWithoutTransfer" onClick={() => setShowWithTransfers(false)}>{t("buyticket:journeysWithoutTransfers")}</p></li>
                  <li className={showWithTransfers ? "active" : ""}><p href="#journeysWithTransfer" onClick={() => setShowWithTransfers(true)}>{t("buyticket:journeysWithTransfers")}</p></li>
                </ul>
              ) : <h3 className="transfer-type">{t("buyticket:journeysWithoutTransfers")}</h3>
              }
              <div className="tab-content">
                <div id="journeysWithoutTransfers" className={"tab-pane fade " + (!showWithTransfers ? "in active" : "")}>
                  {journeyWithoutTransfers.length > 0 && (
                    <div className="search-results route">
                      <div className="search-result-list-container">
                        {journeyWithoutTransfers.map((result) => (
                          <div key={result.activeDepartureIDs} className="search-result-list-item">
                            <div className="search-result-lines">
                              <div className="date-and-travel-time">
                                <p className="date-label">
                                  {new Date(result.departureDate).toLocaleDateString("da-DK", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}
                                </p>
                                <p>{t("buyticket:travelTime")}: {result.totalTravelTime}</p>
                                <p>{t("buyticket:availableTickets")}: {result.ticketAvailable}</p>
                              </div>
                              <div className="departure-and-time">
                                <div className="departure-arrival">
                                  <h3 className="departure-stop-name">{result.departureStopName}</h3>
                                  <p className="divider">-</p>
                                  <h3 className="arrival-stop-name">{result.arrivalStopName}</h3>
                                </div>
                                <div className="times">
                                  <p className="departure-time">{t("buyticket:kl")} {result.departureTime.slice(11, 16)}</p>
                                  <p className="divider">-</p>
                                  <p className="arrival-time">{t("buyticket:kl")} {result.arrivalTime.slice(11, 16)}</p>
                                </div>
                              </div>
                              <div className="total-price">
                                <p className="price-label">{t("buyticket:totalPrice")}</p>
                                <p className="price-value">{formatNumber(result.totalPrice)} DKK</p>
                              </div>
                            </div>
                            <div className="buttons-container">
                              <button
                                className="button-details"
                                onClick={(event) => {
                                  const buttonRect = event.currentTarget.getBoundingClientRect();
                                  handleDetailsClick(result.activeDepartureIDs, {
                                    x: buttonRect.left,
                                    y: buttonRect.bottom + window.scrollY,
                                  });
                                }}
                              >
                                {t("buyticket:details")}
                              </button>
                              <button className="button-buy" onClick={() => handleAddToCart(result.activeDepartureIDs)}>{t("buyTicket")}</button>
                            </div>
                          </div>
                        ))}
                      </div>
                      {noMoreResults ? null : (
                        <div className="button-container">
                          <button className="btn-secondary search-more" onClick={handleSearchMoreWithoutTransfers}>{t("buyticket:searchMore")}</button>
                        </div>
                      )}
                      <div>
                        {showModal && (
                          <div>
                            <RouteDetailsModal content={modalContent} onClose={() => setShowModal(false)} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div id="journeysWithTransfers" className={"tab-pane fade " + (showWithTransfers ? "in active" : "")}>
                  {journeyWithTransfers.length > 0 && (
                    <div className="search-results route">
                      <div className="search-result-list-container">
                        {journeyWithTransfers.map((result) => (
                          <div key={result.activeDepartureIDs} className="search-result-list-item">
                            <div className="search-result-lines">
                              <div className="date-and-travel-time">
                                <p className="date-label">
                                  {new Date(result.departureDate).toLocaleDateString("da-DK", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}
                                </p>
                                <p>{t("buyticket:travelTime")}: {result.totalTravelTime}</p>
                                <p>{t("buyticket:availableTickets")}: {result.ticketAvailable}</p>
                              </div>
                              <div className="departure-and-time">
                                <div className="departure-arrival">
                                  <h3 className="departure-stop-name">{result.departureStopName}</h3>
                                  <p className="divider">-</p>
                                  <h3 className="arrival-stop-name">{result.arrivalStopName}</h3>
                                </div>
                                <div className="times">
                                  <p className="departure-time">{t("buyticket:kl")} {result.departureTime.slice(11, 16)}</p>
                                  <p className="divider">-</p>
                                  <p className="arrival-time">{t("buyticket:kl")} {result.arrivalTime.slice(11, 16)}</p>
                                </div>
                              </div>
                              <div className="total-price">
                                <p className="price-label">{t("buyticket:totalPrice")}</p>
                                <p className="price-value">{formatNumber(result.totalPrice)} DKK</p>
                              </div>
                            </div>
                            <div className="buttons-container">
                              <button
                                className="button-details"
                                onClick={(event) => {
                                  const buttonRect = event.currentTarget.getBoundingClientRect();
                                  handleDetailsClick(result.activeDepartureIDs, {
                                    x: buttonRect.left,
                                    y: buttonRect.bottom + window.scrollY,
                                  });
                                }}
                              >
                                {t("buyticket:details")}
                              </button>
                              <button className="button-buy" onClick={() => handleAddToCart(result.activeDepartureIDs)}>{t("buyTicket")}</button>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div>
                        {showModal && (
                          <div>
                            <RouteDetailsModal content={modalContent} onClose={() => setShowModal(false)} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}