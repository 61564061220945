import { Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {  useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import config from '../../configs/config';

export default function CarriageRegulations(){
    const {t} = useTranslation(["common", "practicalinfo"])
  const [openAccordion, setOpenAccordion] = useState(null);
  const apiUrl = config.default.apiPath;


  const handleChange = (panel) => (event, isExpanded) => {
      setOpenAccordion(isExpanded ? panel : null);
  };
  async function downloadPDF(fileName){
    const globalSetup = await fetch(`${apiUrl}/System/GetGlobalSetup`)
    const globalSetupData = await globalSetup.json()
    const APIUrl = globalSetupData.hostNameApi;
    const ticketUrl = `${APIUrl}System/GetSharedFile?fileName=${fileName}`
    window.open(ticketUrl);
}
  function handlePrintClick(){
      window.print();
  }



    return (
        <div className="carriageregulationspage">
            <Helmet>
                <title>{t('carriageRegulations')} | Disko Line {t('greenland')}</title>
            </Helmet>
            <h1>{t('carriageRegulations')}</h1>
            <button onClick={handlePrintClick} className="btn no-print">{t('print')}</button>
            <h2 className="mainTitle">{t('practicalinfo:termsSubHeader')}</h2>
        {/*1. GENERELLE OPLYSNINGER */}
                 <div className="no-print">
            <div className="accordion">
            <Accordion
          expanded={openAccordion === "panel1"}
          onChange={handleChange("panel1")}
        >
                <AccordionSummary >
                <h2>{t('practicalinfo:terms1GeneralInfo')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                <p><span className="bold">{t('practicalinfo:termsLegalNameLabel')}</span> {t('practicalinfo:termsLegalName')}</p>
                <p><span className="bold">{t('practicalinfo:termsNameLabel')}</span> {t('practicalinfo:termsName')}</p>
                <p><span className="bold">{t('practicalinfo:termsCompanyTypeLabel')}</span> {t('practicalinfo:termsCompanyType')}</p>
                <p><span className="bold">{t('practicalinfo:termsRegNoLabel')}</span> A/S337257</p>
                <p><span className="bold">{t('practicalinfo:termsAddressLabel')}</span> P.O. Box 305, Kussangajaannguaq 11, 3952 Ilulissat</p>
                <p><span className="bold">{t('practicalinfo:termsPhoneLabel')}</span> (+299) 94 53 00 </p>
                <p><span className="bold">{t('practicalinfo:termsMailLabel')}</span> <a href="mailto:info@diskoline.gl">info@diskoline.gl</a></p>
                <p><span className="bold">{t('practicalinfo:termsWebsiteLabel')}</span> <a href="https://www.diskoline.dk/da/">www.diskoline.gl</a></p>
                <p>{t('practicalinfo:terms1GeneralInfoText')}</p>
                </AccordionDetails>
                </Accordion>
                </div>
        {/*2. KØB AF SKIBSBILLET */}
            <div className="accordion">
            <Accordion
          expanded={openAccordion === "panel2"}
          onChange={handleChange("panel2")}
        >
                <AccordionSummary>
                <h2>{t('practicalinfo:terms2Purchase')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion className="nested-accordion">
                    <AccordionSummary> <h3>{t('practicalinfo:terms2.1ShipTickets')}</h3></AccordionSummary>
                    <AccordionDetails>
                    <p>{t('practicalinfo:terms2.1ShipTickets1')}</p>
                    <br></br><p>{t('practicalinfo:terms2.1ShipTickets2')}</p>
                    </AccordionDetails>   
                    </Accordion>
                    <Accordion>
                    <AccordionSummary><h3>{t('practicalinfo:terms2.2DLETickets')}</h3></AccordionSummary>
                    <AccordionDetails><p>{t('practicalinfo:terms2.2DLETickets1')}</p>
                    <br></br><p>{t('practicalinfo:terms2.2DLETickets2')} </p>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion>
                    <AccordionSummary><h3>{t('practicalinfo:terms2.3PointsofSale')}</h3></AccordionSummary>
                    <AccordionDetails><p>{t('practicalinfo:terms2.3PointsofSale1')}</p>
                    <br></br><p>{t('practicalinfo:terms2.3PointsofSale2')}</p>
                    <br></br><p>{t('practicalinfo:terms2.3PointsofSale3')}</p>
                    <br></br><p>{t('practicalinfo:terms2.3PointsofSale4')}</p>
                    <br></br><p>{t('practicalinfo:terms2.3PointsofSale5')}</p>
                    </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
                </Accordion>
            </div>
        {/*3. RABATSTRUKTUR, GEBYRER mm. */}
            <div className="accordion">
            <Accordion
          expanded={openAccordion === "panel3"}
          onChange={handleChange("panel3")}
        >
            <AccordionSummary><h2>{t('practicalinfo:terms3Discount')}</h2></AccordionSummary>
            <AccordionDetails>
                <Accordion>
                <AccordionSummary><h3>{t('practicalinfo:terms3.1DiscountatDL')}</h3></AccordionSummary>
                <AccordionDetails><p className="bold">{t('practicalinfo:terms3.1.ADiscount')}</p>
                <ul>
                    <li>{t('practicalinfo:terms3.1.ADiscount1')}</li>
                    <li>{t('practicalinfo:terms3.1.ADiscount2')}</li>
                    <li>{t('practicalinfo:terms3.1.ADiscount3')}</li>
                </ul>
                <br></br>
                <p>{t('practicalinfo:terms3.1.ADiscount4')}</p>
                <p>{t('practicalinfo:terms3.1.ADiscount5')}</p>
                <br></br>
                <p className="bold">{t('practicalinfo:terms3.1.BDiscount')}</p>
                <ul>
                    <li>{t('practicalinfo:terms3.1.BDiscount1')}</li>
                    <li>{t('practicalinfo:terms3.1.BDiscount2')}</li>
                </ul>
                <br></br>
                <p>{t('practicalinfo:terms3.1.BDiscount3')}</p>
                <p>{t('practicalinfo:terms3.1.BDiscount4')}</p>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                 <AccordionSummary><h3>{t('practicalinfo:terms3.2BookingFee')}</h3></AccordionSummary>   
                 <AccordionDetails>
                <p className="bold">{t('practicalinfo:terms3.2.ABookingFee')}</p>
                <p> {t('practicalinfo:terms3.2.ABookingFee1')}</p>
                <ul>
                    <li>{t('practicalinfo:terms3.2.ABookingFee2')}</li>
                    <li>{t('practicalinfo:terms3.2.ABookingFee3')}</li>
                </ul>
                <br></br>
                <p className="bold">{t('practicalinfo:terms3.2.BBookingFee')}</p>
                <p>{t('practicalinfo:terms3.2.BBookingFee1')}</p>
                 </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary><h3>{t('practicalinfo:terms3.3Payment')}</h3></AccordionSummary>
                <AccordionDetails><p>{t('practicalinfo:terms3.3Payment1')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.3Payment2')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.3Payment3')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.3Payment4')}</p>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary><h3>{t('practicalinfo:terms3.4FormofPayment')}</h3></AccordionSummary>
                <AccordionDetails><p>{t('practicalinfo:terms3.4FormofPayment1')}</p> 
                <br></br>
                <p>{t('practicalinfo:terms3.4FormofPayment2')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.4FormofPayment3')}</p>
                <br></br>
                <p className="bold">{t('practicalinfo:terms3.4FormofPayment4')}</p>
                <p>{t('practicalinfo:terms3.4FormofPayment5')}</p>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary><h3>{t('practicalinfo:terms3.5Validity')}</h3></AccordionSummary>
                <AccordionDetails><p>{t('practicalinfo:terms3.5Validity1')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.5Validity2')}</p>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary><h3>{t('practicalinfo:terms3.6Delivery')}</h3></AccordionSummary>
                <AccordionDetails><p>{t('practicalinfo:terms3.6Delivery1')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.6Delivery2')}</p>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary><h3>{t('practicalinfo:terms3.7Refund')}</h3></AccordionSummary>
                <AccordionDetails><p className="bold">{t('practicalinfo:terms3.7.ARefundShip')}</p>
                <p>{t('practicalinfo:terms3.7.ARefundShip1')}</p>
                <p>{t('practicalinfo:terms3.7.ARefundShip2')}</p>
                <p>{t('practicalinfo:terms3.7.ARefundShip3')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.7.ARefundShip4')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.7.ARefundShip5')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.7.ARefundShip6')}</p>
                <p>{t('practicalinfo:terms3.7.ARefundShip7')}</p>
                <br></br>
                <p className="bold">{t('practicalinfo:terms3.7.BRefundDLE')}</p>
                <p>{t('practicalinfo:terms3.7.BRefundDLE1')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.7.BRefundDLE2')} </p>
                <br></br>
                <p>{t('practicalinfo:terms3.7.BRefundDLE3')} </p>
                </AccordionDetails>
                </Accordion>
            </AccordionDetails>
            </Accordion>
            </div>
        {/*4. Andre bestemmelser for personbefordring */}
        <div className="accordion">
            <Accordion
          expanded={openAccordion === "panel4"}
          onChange={handleChange("panel4")}
        >
                <AccordionSummary>
                <h2>{t('practicalinfo:terms4Provisions')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion className="nested-accordion">
                    <AccordionSummary> <h3>{t('practicalinfo:terms4.1General')}</h3></AccordionSummary>
                    <AccordionDetails>
                        <p>{t('practicalinfo:terms4.1General1')}</p>
                        <p>{t('practicalinfo:terms4.1General2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1.AChanges')}</p>
                        <p>{t('practicalinfo:terms4.1.AChanges1')}</p>
                        <p>{t('practicalinfo:terms4.1.AChanges2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1.BSales')}</p>
                        <p>{t('practicalinfo:terms4.1.BSales1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1CWeapons')}</p>
                        <p>{t('practicalinfo:terms4.1CWeapons1')}</p>
                        <p>{t('practicalinfo:terms4.1CWeapons3')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1.DVandalism')}</p>
                        <p>{t('practicalinfo:terms4.1.DVandalism1')}</p>
                        <p>{t('practicalinfo:terms4.1.DVandalism2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1.ETobacco')}</p>
                        <p>{t('practicalinfo:terms4.1.ETobacco1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1.FCrew')}</p>
                        <p>{t('practicalinfo:terms4.1.FCrew1')}</p>
                        <br></br>
                        
                    </AccordionDetails>   
                    </Accordion>
                    <Accordion>
                    <AccordionSummary><h3>{t('practicalinfo:terms4.2Transportaion')}</h3></AccordionSummary>
                    <AccordionDetails>
                        <p>{t('practicalinfo:terms4.2Transportaion1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.2.APregnant')}</p>
                        <p>{t('practicalinfo:terms4.2.APregnant1')}</p>
                        <ul>
                            <li>{t('practicalinfo:terms4.2.APregnant2')}</li>
                            <li>{t('practicalinfo:terms4.2.APregnant3')}</li>
                            <li>{t('practicalinfo:terms4.2.APregnant4')}</li>
                        </ul>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.APregnant5')}</p>
                        <ul>
                            <li>{t('practicalinfo:terms4.2.APregnant6')}</li>
                            <li>{t('practicalinfo:terms4.2.APregnant7')}</li>
                            <li>{t('practicalinfo:terms4.2.APregnant8')}</li>
                        </ul>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.2.BInfection')}</p>
                        <p>{t('practicalinfo:terms4.2.BInfection1')}</p>
                        <ul>
                            <li>{t('practicalinfo:terms4.2.BInfection2')}</li>
                            <li>{t('practicalinfo:terms4.2.BInfection3')}</li>
                            <li>{t('practicalinfo:terms4.2.BInfection4')}</li>
                            <li>{t('practicalinfo:terms4.2.BInfection5')}</li>
                            <li>{t('practicalinfo:terms4.2.BInfection6')}</li>
                            <li>{t('practicalinfo:terms4.2.BInfection7')}</li>
                        </ul>
                        <p>{t('practicalinfo:terms4.2.BInfection8')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.2.CMobility')}</p>
                        <p>{t('practicalinfo:terms4.2.CMobility1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.CMobility2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.CMobility3')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.2.DWheelchair')}</p>
                        <p>{t('practicalinfo:terms4.2.DWheelchair1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.DWheelchair2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.DWheelchair3')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.DWheelchair4')}</p>
                        <br></br>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion>
                    <AccordionSummary><h3>{t('practicalinfo:terms4.3Children')}</h3></AccordionSummary>
                    <AccordionDetails>
                        <p className="bold">{t('practicalinfo:terms4.3.AChildren')}</p>
                        <p>{t('practicalinfo:terms4.3.AChildren1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.3.AChildren2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.3.AChildren3')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.3.AChildren4')}</p>
                        <p className="pdf-link" onClick={() => downloadPDF('diskoline-unaccompanied-minor.pdf')}>{t('practicalinfo:terms4.3.AChildren5')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.3.BChildren')}</p>
                        <p>{t('practicalinfo:terms4.3.BChildren1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.3.BChildren2')}</p>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion>
                    <AccordionSummary><h3>{t('practicalinfo:terms4.4CheckIn')}</h3></AccordionSummary>
                    <AccordionDetails>
                        <p>{t('practicalinfo:terms4.4CheckIn1')} </p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.4CheckIn2')} </p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.4CheckIn3')} </p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.4CheckIn4')} </p>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion>
                    <AccordionSummary><h3>{t('practicalinfo:terms4.5Delay')}</h3></AccordionSummary>
                    <AccordionDetails>
                        <p>{t('practicalinfo:terms4.5Delay1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5Delay2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.ACheckIn')}</p>
                        <p>{t('practicalinfo:terms4.5.ACheckIn1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.ACheckIn2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.BCancel')}</p>
                        <p>{t('practicalinfo:terms4.5.BCancel1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.CConnection')}</p>
                        <p>{t('practicalinfo:terms4.5.CConnection1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.CConnection2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.DRefund')}</p>
                        <p>{t('practicalinfo:terms4.5.DRefund1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.ERefund')}</p>
                        <p>{t('practicalinfo:terms4.5.ERefund1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.FRebook')}</p>
                        <p>{t('practicalinfo:terms4.5.FRebook1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.FRebook2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.FRebook3')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.GInsurance')}</p>
                        <p>{t('practicalinfo:terms4.5.GInsurance1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.GInsurance2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.GInsurance3')}</p>
                        <br></br>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion>
                    <AccordionSummary><h3>{t('practicalinfo:terms4.6Cancellation')}</h3></AccordionSummary>
                    <AccordionDetails>
                        <p className="bold">{t('practicalinfo:terms4.6.AScheduled')}</p>
                        <p>{t('practicalinfo:terms4.6.AScheduled1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.6.AScheduled2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.6.BDLE')}</p>
                        <p>{t('practicalinfo:terms4.6.BDLE1')}</p>
                        <ul>
                            <li>{t('practicalinfo:terms4.6.BDLE2')}</li>
                            <li>{t('practicalinfo:terms4.6.BDLE3')}</li>
                            <li>{t('practicalinfo:terms4.6.BDLE4')}</li>
                            <li>{t('practicalinfo:terms4.6.BDLE5')}</li>
                            <li>{t('practicalinfo:terms4.6.BDLE6')}</li>
                            <li>{t('practicalinfo:terms4.6.BDLE7')}</li>
                        </ul>
                        <br></br>
                        <p>{t('practicalinfo:terms4.6.BDLE8')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.6.BDLE9')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.6.BDLE10')}</p>
                        <br></br>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion>
                    <AccordionSummary><h3>{t('practicalinfo:terms4.7Baggage')}</h3></AccordionSummary>
                    <AccordionDetails>
                        <p className="bold">{t('practicalinfo:terms4.7.AAllowance')}</p>
                        <p>{t('practicalinfo:terms4.7.AAllowance1')}</p>
                        <br></br>
                        <div class="overflow">
                        <table>
                            <tr>
                                <th>{t('practicalinfo:terms4.7.AAllowance2')}</th>
                                <td>2-5kg</td>
                                <td>6-10kg</td>
                                <td>11-20kg</td>
                                <td>21-30kg</td>
                                <td>31-40kg</td>
                                <td>41-50kg</td>
                                <td>51-60kg</td>
                                <td>61-70kg</td>
                                <td>71-80kg</td>
                                <td>81-90kg</td>
                                <td>91-100kg</td>
                            </tr>
                            <tr>
                                <th>{t('practicalinfo:terms4.7.AAllowance3')}</th>
                                <td>100 DKK</td>
                                <td>180 DKK</td>
                                <td>360 DKK</td>
                                <td>540 DKK</td>
                                <td>720 SKK</td>
                                <td>900 SKK</td>
                                <td>1080 DKK</td>
                                <td>1260 DKK</td>
                                <td>1440 DKK</td>
                                <td>1620 DKK</td>
                                <td>1800 DKK</td> 
                            </tr>
                        </table>
                        </div>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.AAllowance4')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.AAllowance5')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.AAllowance6')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.AAllowance7')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.7.BLarge')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.BLarge1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.BLarge2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.7.CFirearms')}</p>
                        <p>{t('practicalinfo:terms4.7.CFirearms1')}</p>
                        <br></br>
                        <p className="pdf-link" onClick={() => downloadPDF('diskoline-firearm-declaration.pdf')}>{t('practicalinfo:terms4.7.CFirearms2')}</p>
                        <br></br><br></br>
                        <p>{t('practicalinfo:terms4.7.CFirearms3')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.7.DDelay')}</p>
                        <p>{t('practicalinfo:terms4.7.DDelay1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.DDelay2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.DDelay3')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.7.EDamaged')}</p>
                        <p>{t('practicalinfo:terms4.7.EDamaged1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged3')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged4')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged5')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged6')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged7')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.7.FLost')}</p>
                        <p>{t('practicalinfo:terms4.7.FLost1')}</p>
                        <br></br>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion>
                    <AccordionSummary><h3>{t('practicalinfo:terms4.8Animals')}</h3></AccordionSummary>
                    <AccordionDetails>
                        <p>{t('practicalinfo:terms4.8Animals1')} </p>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion>
                    <AccordionSummary><h3>{t('practicalinfo:terms4.9DangerousGoods')}</h3></AccordionSummary>
                    <AccordionDetails>
                        <p>{t('practicalinfo:terms4.9DangerousGoods1')} </p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.9DangerousGoods2')} </p>
                        <ul>
                            <li>{t('practicalinfo:terms4.9DangerousGoods3')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods4')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods5')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods6')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods7')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods8')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods9')}</li>
                        </ul>
                    </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
                </Accordion>
            </div>
        {/*5. Reklamationer */}
            <div className="accordion">
            <Accordion
          expanded={openAccordion === "panel5"}
          onChange={handleChange("panel5")}
        >
                <AccordionSummary >
                <h2>{t('practicalinfo:terms5.Complaints')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                <p>{t('practicalinfo:terms5.Complaints1')}</p>
                <br></br>
                <p>{t('practicalinfo:terms5.Complaints2')}</p>
                </AccordionDetails>
                </Accordion>
                </div>
        {/*6. Forbehold */}
            <div className="accordion">
            <Accordion
          expanded={openAccordion === "panel6"}
          onChange={handleChange("panel6")}
        >
                <AccordionSummary >
                <h2>{t('practicalinfo:terms6.Reservations')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                <p>{t('practicalinfo:terms6.Reservations1')}</p>
                </AccordionDetails>
                </Accordion>
                </div>
        {/*7. Ophavsret */}
            <div className="accordion">
            <Accordion
          expanded={openAccordion === "panel7"}
          onChange={handleChange("panel7")}
        >
                <AccordionSummary >
                <h2>{t('practicalinfo:terms7.Copyright')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                <p>{t('practicalinfo:terms7.Copyright1')}</p>
                </AccordionDetails>
                </Accordion>
                </div>
        {/*8. Ansvar */}
            <div className="accordion">
            <Accordion
          expanded={openAccordion === "panel8"}
          onChange={handleChange("panel8")}
        >
                <AccordionSummary >
                <h2>{t('practicalinfo:terms8.Responsibility')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                <p>{t('practicalinfo:terms8.Responsibility1')}</p>
                </AccordionDetails>
                </Accordion>
                </div>
        {/*9. Accept */}
            <div className="accordion">
            <Accordion
          expanded={openAccordion === "panel9"}
          onChange={handleChange("panel9")}
        >
                <AccordionSummary >
                <h2>{t('practicalinfo:terms9.Acceptance')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                <p>{t('practicalinfo:terms9.Acceptance1')}</p>
                </AccordionDetails>
                </Accordion>
                </div>
          </div>
          {/* PRINTED SITE */}
            <div className="print">
            {/*1. GENERELLE OPLYSNINGER */}
                <br></br>
                <h2>{t('practicalinfo:terms1GeneralInfo')}</h2>
                <p><span className="bold">{t('practicalinfo:termsLegalNameLabel')}</span> {t('practicalinfo:termsLegalName')}</p>
                <p><span className="bold">{t('practicalinfo:termsNameLabel')}</span> {t('practicalinfo:termsName')}</p>
                <p><span className="bold">{t('practicalinfo:termsCompanyTypeLabel')}</span> {t('practicalinfo:termsCompanyType')}</p>
                <p><span className="bold">{t('practicalinfo:termsRegNoLabel')}</span> A/S337257</p>
                <p><span className="bold">{t('practicalinfo:termsAddressLabel')}</span> P.O. Box 305, Kussangajaannguaq 11, 3952 Ilulissat</p>
                <p><span className="bold">{t('practicalinfo:termsPhoneLabel')}</span> (+299) 94 53 00 </p>
                <p><span className="bold">{t('practicalinfo:termsMailLabel')}</span> <a href="mailto:info@diskoline.gl">info@diskoline.gl</a></p>
                <p><span className="bold">{t('practicalinfo:termsWebsiteLabel')}</span> <a href="https://www.diskoline.dk/da/">www.diskoline.gl</a></p>
                <p>{t('practicalinfo:terms1GeneralInfoText')}</p>
            {/*2. KØB AF SKIBSBILLET */}
                <br></br><hr></hr><br></br>
                <h2>{t('practicalinfo:terms2Purchase')}</h2>
                 <h3>{t('practicalinfo:terms2.1ShipTickets')}</h3>
                    <p>{t('practicalinfo:terms2.1ShipTickets1')}</p>
                    <br></br><p>{t('practicalinfo:terms2.1ShipTickets2')}</p>
                    <br></br>
                    <h3>{t('practicalinfo:terms2.2DLETickets')}</h3>
                    <p>{t('practicalinfo:terms2.2DLETickets1')}</p>
                    <br></br><p>{t('practicalinfo:terms2.2DLETickets2')} </p>
                    <br></br>
                    <h3>{t('practicalinfo:terms2.3PointsofSale')}</h3>
                    <p>{t('practicalinfo:terms2.3PointsofSale1')}</p>
                    <br></br><p>{t('practicalinfo:terms2.3PointsofSale2')}</p>
                    <br></br><p>{t('practicalinfo:terms2.3PointsofSale3')}</p>
                    <br></br><p>{t('practicalinfo:terms2.3PointsofSale4')}</p>
                    <br></br><p>{t('practicalinfo:terms2.3PointsofSale5')}</p>
        {/*3. RABATSTRUKTUR, GEBYRER mm. */}
                <br></br><hr></hr><br></br>
                <h2>{t('practicalinfo:terms3Discount')}</h2>
                <h3>{t('practicalinfo:terms3.1DiscountatDL')}</h3>
                <p className="bold">{t('practicalinfo:terms3.1.ADiscount')}</p>
                <ul>
                    <li>{t('practicalinfo:terms3.1.ADiscount1')}</li>
                    <li>{t('practicalinfo:terms3.1.ADiscount2')}</li>
                    <li>{t('practicalinfo:terms3.1.ADiscount3')}</li>
                </ul>
                <br></br>
                <p>{t('practicalinfo:terms3.1.ADiscount4')}</p>
                <p>{t('practicalinfo:terms3.1.ADiscount5')}</p>
                <br></br>
                <p className="bold">{t('practicalinfo:terms3.1.BDiscount')}</p>
                <ul>
                    <li>{t('practicalinfo:terms3.1.BDiscount1')}</li>
                    <li>{t('practicalinfo:terms3.1.BDiscount2')}</li>
                </ul>
                <br></br>
                <p>{t('practicalinfo:terms3.1.BDiscount3')}</p>
                <p>{t('practicalinfo:terms3.1.BDiscount4')}</p>
                <br></br>
                <h3>{t('practicalinfo:terms3.2BookingFee')}</h3>
                <p className="bold">{t('practicalinfo:terms3.2.ABookingFee')}</p>
                <p> {t('practicalinfo:terms3.2.ABookingFee1')}</p>
                <ul>
                    <li>{t('practicalinfo:terms3.2.ABookingFee2')}</li>
                    <li>{t('practicalinfo:terms3.2.ABookingFee3')}</li>
                </ul>
                <br></br>
                <p className="bold">{t('practicalinfo:terms3.2.BBookingFee')}</p>
                <p>{t('practicalinfo:terms3.2.BBookingFee1')}</p>
                <br></br>
                <h3>{t('practicalinfo:terms3.3Payment')}</h3>
                <p>{t('practicalinfo:terms3.3Payment1')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.3Payment2')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.3Payment3')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.3Payment4')}</p>
                <br></br>
                <h3>{t('practicalinfo:terms3.4FormofPayment')}</h3>
                <p>{t('practicalinfo:terms3.4FormofPayment1')}</p> 
                <br></br>
                <p>{t('practicalinfo:terms3.4FormofPayment2')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.4FormofPayment3')}</p>
                <br></br>
                <p className="bold">{t('practicalinfo:terms3.4FormofPayment4')}</p>
                <p>{t('practicalinfo:terms3.4FormofPayment5')}</p>
                <br></br>
                <h3>{t('practicalinfo:terms3.5Validity')}</h3>
                <p>{t('practicalinfo:terms3.5Validity1')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.5Validity2')}</p>
                <br></br>
                <h3>{t('practicalinfo:terms3.6Delivery')}</h3>
                <p>{t('practicalinfo:terms3.6Delivery1')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.6Delivery2')}</p>
                <br></br>
                <h3>{t('practicalinfo:terms3.7Refund')}</h3>
                <p className="bold">{t('practicalinfo:terms3.7.ARefundShip')}</p>
                <p>{t('practicalinfo:terms3.7.ARefundShip1')}</p>
                <p>{t('practicalinfo:terms3.7.ARefundShip2')}</p>
                <p>{t('practicalinfo:terms3.7.ARefundShip3')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.7.ARefundShip4')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.7.ARefundShip5')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.7.ARefundShip6')}</p>
                <p>{t('practicalinfo:terms3.7.ARefundShip7')}</p>
                <br></br>
                <p className="bold">{t('practicalinfo:terms3.7.BRefundDLE')}</p>
                <p>{t('practicalinfo:terms3.7.BRefundDLE1')}</p>
                <br></br>
                <p>{t('practicalinfo:terms3.7.BRefundDLE2')} </p>
                <br></br>
                <p>{t('practicalinfo:terms3.7.BRefundDLE3')} </p>
        {/*4. Andre bestemmelser for personbefordring */}
                 <br></br><hr></hr><br></br> 
                <h2>{t('practicalinfo:terms4Provisions')}</h2>
                <h3>{t('practicalinfo:terms4.1General')}</h3>
                        <p>{t('practicalinfo:terms4.1General1')}</p>
                        <p>{t('practicalinfo:terms4.1General2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1.AChanges')}</p>
                        <p>{t('practicalinfo:terms4.1.AChanges1')}</p>
                        <p>{t('practicalinfo:terms4.1.AChanges2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1.BSales')}</p>
                        <p>{t('practicalinfo:terms4.1.BSales1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1CWeapons')}</p>
                        <p>{t('practicalinfo:terms4.1CWeapons1')}</p>
                        <p>{t('practicalinfo:terms4.1CWeapons3')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1.DVandalism')}</p>
                        <p>{t('practicalinfo:terms4.1.DVandalism1')}</p>
                        <p>{t('practicalinfo:terms4.1.DVandalism2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1.ETobacco')}</p>
                        <p>{t('practicalinfo:terms4.1.ETobacco1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.1.FCrew')}</p>
                        <p>{t('practicalinfo:terms4.1.FCrew1')}</p>
                        <br></br>
                    <h3>{t('practicalinfo:terms4.2Transportaion')}</h3>
                        <p>{t('practicalinfo:terms4.2Transportaion1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.2.APregnant')}</p>
                        <p>{t('practicalinfo:terms4.2.APregnant1')}</p>
                        <ul>
                            <li>{t('practicalinfo:terms4.2.APregnant2')}</li>
                            <li>{t('practicalinfo:terms4.2.APregnant3')}</li>
                            <li>{t('practicalinfo:terms4.2.APregnant4')}</li>
                        </ul>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.APregnant5')}</p>
                        <ul>
                            <li>{t('practicalinfo:terms4.2.APregnant6')}</li>
                            <li>{t('practicalinfo:terms4.2.APregnant7')}</li>
                            <li>{t('practicalinfo:terms4.2.APregnant8')}</li>
                        </ul>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.2.BInfection')}</p>
                        <p>{t('practicalinfo:terms4.2.BInfection1')}</p>
                        <ul>
                            <li>{t('practicalinfo:terms4.2.BInfection2')}</li>
                            <li>{t('practicalinfo:terms4.2.BInfection3')}</li>
                            <li>{t('practicalinfo:terms4.2.BInfection4')}</li>
                            <li>{t('practicalinfo:terms4.2.BInfection5')}</li>
                            <li>{t('practicalinfo:terms4.2.BInfection6')}</li>
                            <li>{t('practicalinfo:terms4.2.BInfection7')}</li>
                        </ul>
                        <p>{t('practicalinfo:terms4.2.BInfection8')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.2.CMobility')}</p>
                        <p>{t('practicalinfo:terms4.2.CMobility1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.CMobility2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.CMobility3')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.2.DWheelchair')}</p>
                        <p>{t('practicalinfo:terms4.2.DWheelchair1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.DWheelchair2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.DWheelchair3')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.2.DWheelchair4')}</p>
                        <br></br>
                        <h3>{t('practicalinfo:terms4.3Children')}</h3>
                        <p className="bold">{t('practicalinfo:terms4.3.AChildren')}</p>
                        <p>{t('practicalinfo:terms4.3.AChildren1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.3.AChildren2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.3.AChildren3')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.3.AChildren4')}</p>
                        <p>{t('practicalinfo:terms4.3.AChildren5')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.3.BChildren')}</p>
                        <p>{t('practicalinfo:terms4.3.BChildren1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.3.BChildren2')}</p>
                    <br></br>
                    <h3>{t('practicalinfo:terms4.4CheckIn')}</h3>
                        <p>{t('practicalinfo:terms4.4CheckIn1')} </p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.4CheckIn2')} </p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.4CheckIn3')} </p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.4CheckIn4')} </p>
                    <br></br>
                    <h3>{t('practicalinfo:terms4.5Delay')}</h3>
                        <p>{t('practicalinfo:terms4.5Delay1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5Delay2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.ACheckIn')}</p>
                        <p>{t('practicalinfo:terms4.5.ACheckIn1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.ACheckIn2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.BCancel')}</p>
                        <p>{t('practicalinfo:terms4.5.BCancel1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.CConnection')}</p>
                        <p>{t('practicalinfo:terms4.5.CConnection1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.CConnection2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.DRefund')}</p>
                        <p>{t('practicalinfo:terms4.5.DRefund1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.ERefund')}</p>
                        <p>{t('practicalinfo:terms4.5.ERefund1')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.FRebook')}</p>
                        <p>{t('practicalinfo:terms4.5.FRebook1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.FRebook2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.FRebook3')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.5.GInsurance')}</p>
                        <p>{t('practicalinfo:terms4.5.GInsurance1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.GInsurance2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.5.GInsurance3')}</p>
                        <br></br>
                    <h3>{t('practicalinfo:terms4.6Cancellation')}</h3>
                        <p className="bold">{t('practicalinfo:terms4.6.AScheduled')}</p>
                        <p>{t('practicalinfo:terms4.6.AScheduled1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.6.AScheduled2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.6.BDLE')}</p>
                        <p>{t('practicalinfo:terms4.6.BDLE1')}</p>
                        <ul>
                            <li>{t('practicalinfo:terms4.6.BDLE2')}</li>
                            <li>{t('practicalinfo:terms4.6.BDLE3')}</li>
                            <li>{t('practicalinfo:terms4.6.BDLE4')}</li>
                            <li>{t('practicalinfo:terms4.6.BDLE5')}</li>
                            <li>{t('practicalinfo:terms4.6.BDLE6')}</li>
                            <li>{t('practicalinfo:terms4.6.BDLE7')}</li>
                        </ul>
                        <br></br>
                        <p>{t('practicalinfo:terms4.6.BDLE8')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.6.BDLE9')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.6.BDLE10')}</p>
                        <br></br>
                    <h3>{t('practicalinfo:terms4.7Baggage')}</h3>
                        <p className="bold">{t('practicalinfo:terms4.7.AAllowance')}</p>
                        <p>{t('practicalinfo:terms4.7.AAllowance1')}</p>
                        <br></br>
                        <div class="overflow">
                        <table>
                            <tr>
                                <th>{t('practicalinfo:terms4.7.AAllowance2')}</th>
                                <td>2-5kg</td>
                                <td>6-10kg</td>
                                <td>11-20kg</td>
                                <td>21-30kg</td>
                                <td>31-40kg</td>
                                <td>41-50kg</td>
                                <td>51-60kg</td>
                                <td>61-70kg</td>
                                <td>71-80kg</td>
                                <td>81-90kg</td>
                                <td>91-100kg</td>
                            </tr>
                            <tr>
                                <th>{t('practicalinfo:terms4.7.AAllowance3')}</th>
                                <td>100 DKK</td>
                                <td>180 DKK</td>
                                <td>360 DKK</td>
                                <td>540 DKK</td>
                                <td>720 SKK</td>
                                <td>900 SKK</td>
                                <td>1080 DKK</td>
                                <td>1260 DKK</td>
                                <td>1440 DKK</td>
                                <td>1620 DKK</td>
                                <td>1800 DKK</td> 
                            </tr>
                        </table>
                        </div>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.AAllowance4')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.AAllowance5')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.AAllowance6')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.AAllowance7')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.7.BLarge')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.BLarge1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.BLarge2')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.7.CFirearms')}</p>
                        <p>{t('practicalinfo:terms4.7.CFirearms1')}</p>
                        <br></br>
                     {/*HUSK LINK HER */}   <p>{t('practicalinfo:terms4.7.CFirearms2')}</p>
                        <br></br><br></br>
                        <p>{t('practicalinfo:terms4.7.CFirearms3')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.7.DDelay')}</p>
                        <p>{t('practicalinfo:terms4.7.DDelay1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.DDelay2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.DDelay3')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.7.EDamaged')}</p>
                        <p>{t('practicalinfo:terms4.7.EDamaged1')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged2')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged3')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged4')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged5')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged6')}</p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.7.EDamaged7')}</p>
                        <br></br>
                        <p className="bold">{t('practicalinfo:terms4.7.FLost')}</p>
                        <p>{t('practicalinfo:terms4.7.FLost1')}</p>
                        <br></br>
                    <h3>{t('practicalinfo:terms4.8Animals')}</h3>
                        <p>{t('practicalinfo:terms4.8Animals1')} </p>
                    <br></br>
                    <h3>{t('practicalinfo:terms4.9DangerousGoods')}</h3>
                        <p>{t('practicalinfo:terms4.9DangerousGoods1')} </p>
                        <br></br>
                        <p>{t('practicalinfo:terms4.9DangerousGoods2')} </p>
                        <ul>
                            <li>{t('practicalinfo:terms4.9DangerousGoods3')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods4')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods5')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods6')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods7')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods8')}</li>
                            <li>{t('practicalinfo:terms4.9DangerousGoods9')}</li>
                        </ul>
        {/*5. Reklamationer */}
                <br></br><hr></hr><br></br>
                <h2>{t('practicalinfo:terms5.Complaints')}</h2>
                <p>{t('practicalinfo:terms5.Complaints1')}</p>
                <br></br>
                <p>{t('practicalinfo:terms5.Complaints2')}</p>
        {/*6. Forbehold */}
                <br></br><hr></hr><br></br>
                <h2>{t('practicalinfo:terms6.Reservations')}</h2>
                <p>{t('practicalinfo:terms6.Reservations1')}</p>
        {/*7. Ophavsret */}
                <br></br><hr></hr><br></br>
                <h2>{t('practicalinfo:terms7.Copyright')}</h2>
                <p>{t('practicalinfo:terms7.Copyright1')}</p>
        {/*8. Ansvar */}
                <br></br><hr></hr><br></br>
                <h2>{t('practicalinfo:terms8.Responsibility')}</h2>
                <p>{t('practicalinfo:terms8.Responsibility1')}</p>
        {/*9. Accept */}
                <br></br><hr></hr><br></br>
                <h2>{t('practicalinfo:terms9.Acceptance')}</h2>
                <p>{t('practicalinfo:terms9.Acceptance1')}</p>
        </div>
    </div>
    )
}