import { useEffect, useState } from "react";
import config from "../configs/config";
import { useTranslation } from "react-i18next";
import enums from '../commons/Enums'

export default function SystemInfoMessage() {
  const apiUrl = config.default.apiPath;
  const [systemInfoMessageStatus, setSystemInfoMessageStatus] = useState(false);
  const [systemInfoMessage, setSystemInfoMessage] = useState('');
  const { i18n } = useTranslation();
  const { languagesToIndex } = enums;

  useEffect(() => {
    async function fetchSystemInfoMessage() {
      const response = await fetch(`${apiUrl}/System/GetFrontEndSetup`);
      const data = await response.json();
      const systemInfoStatus = data[0].systemInfoMessageActive;
      setSystemInfoMessageStatus(systemInfoStatus);
        const getMessage = async (language) => {
          const response = await fetch(`${apiUrl}/System/GetFrontEndSystemInfo/${language}`);
          const data = await response.json();
          const Message = data[0].systemInfoDesc;
          setSystemInfoMessage(Message);
        };

      if (systemInfoMessageStatus === true){
        getMessage(languagesToIndex[i18n.language]);
      }
    }
    fetchSystemInfoMessage();
  }, [apiUrl, i18n.language, languagesToIndex, systemInfoMessage, systemInfoMessageStatus]);

  return (
  <div>
    {systemInfoMessageStatus === true && (
    <div className="system-info-message">
    {systemInfoMessage}
    </div>)}
  </div>
  );
}
