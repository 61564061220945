import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import config from '../../configs/config';
export default function PraiseAndCriticism() {
    const { t } = useTranslation(["common", "practicalinfo"]);
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
    const maxLength = 500;
    const [messageSent, setMessageSent] = useState(false);
    const apiUrl = config.default.apiPath;
    const handleMessageChange = (e) => {
      const text = e.target.value;
      setFormData({ ...formData, message: text });
    };
    const handleFormSubmit = async (e) => {
      e.preventDefault();
      // Send the POST request
      try {
        const response = await fetch(`${apiUrl}/Cart/SendPraiseAndCriticism`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
  
        if (response.ok) {
            setMessageSent(true);
        } else {
          // Handle errors (e.g., display an error message)
          console.error("Request failed");
        }
      } catch (error) {
        console.error("An error occurred while sending the request:", error);
      }
    };
  
    return (
      <div className="praiseandcriticismpage">
        <Helmet>
          <title>{t("praiseAndCriticism")} | Disko Line Greenland</title>
        </Helmet>
        <h1>{t("praiseAndCriticism")}</h1>
        {messageSent ? (
        <div>
          <p>{t("practicalinfo:messageSent")}</p>
        </div>
      ) : (
        <div>
        <p>{t("practicalinfo:praiseAndCriticismIntro")}</p>
        <div>
          <form className="flexed-form" onSubmit={handleFormSubmit}>
            <label htmlFor="name">
              <div className="label-and-input">
                {t("name")}:{' '}
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
            </label>
            <label htmlFor="contactmail">
              <div className="label-and-input">
                {t("contactMail")}:{' '}
                <input
                  type="text"
                  id="contactmail"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </div>
            </label>
            <label htmlFor="phonenumber">
              <div className="label-and-input">
                {t("phoneNumber")}:{' '}
                <input
                  type="text"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                />
              </div>
            </label>
            <label>
              <div className="label-and-input">
                {t("message")}
                <textarea
                  name="myTextarea"
                  placeholder={t("practicalinfo:yourMessageHere")}
                  cols="28"
                  rows="6"
                  minLength="11"
                  maxLength={maxLength}
                  required
                  onChange={handleMessageChange}
                  value={formData.message}
                />
              </div>
            </label>
            {t("practicalinfo:charactersRemaining")}:{' '}
            {maxLength - formData.message.length} / {maxLength}
            <button className="btn" type="submit">
              {t("practicalinfo:sendMessage")}
            </button>
          </form>
        </div>
        </div>
          )}
      </div>
      );
    }