import React, { useState } from "react";
const requiredFields = ["fullName", "email", "phoneNumber", "stayphoneNumber", "address", "postalCode", "city", "country"];
const emailRegex = /^(?!.*\.\.)(?!.*__)[a-zA-Z0-9][a-zA-Z0-9._]*[a-zA-Z0-9]@[a-zA-Z0-9][a-zA-Z0-9.-]+\.[a-zA-Z0-9.]+[a-zA-Z0-9]$/;

export const OrderInputField = ({ label, name, value, onChange, error, inputType = "text", required }) => (
  <label>
    <div className="input-label">
      {label}
      {required && <span className="required-field">*</span>}:
    </div>
    <input
      type={inputType}
      name={name}
      value={name === "phoneNumber" ? value.number : value}
      onChange={onChange}
      className={name === "phoneNumber" ? "phone-number" : ""}
      inputMode={inputType === "number" ? "numeric" : "text"}
    />
    <span className="error-message">{error}</span>
  </label>
);

export  function OrderData({ sessionID }) {
  const [orderForm, setOrderForm] = useState({
    fullName: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    companyName: "",
    email: "",
    CopyOfEmail: "",
    phoneNumber: "",
    stayphoneNumber: "",
    sessionID: sessionID,
  });

  return { orderForm,  setOrderForm };
}

//HANDLES INPUT CHANGES IN FORM FOR REQUESTBODY
export const handleOrderFormInputChange = (event, setOrderForm) => {
  const { name, value } = event.target;
  if (name === "postalCode" || name === "phoneNumber") {
    const numericValue = value.replace(/[^\d+]/g, "");
    setOrderForm((prevInfo) => ({
      ...prevInfo,
      [name]: numericValue,
    }));
  }
  else if (name === "email" || name === "CopyOfEmail"){
    const emailValue = value.replace(/\s+/g, "");
    setOrderForm((prevInfo) => ({
      ...prevInfo,
      [name]: emailValue,
    }));
  }
  else {
    setOrderForm((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  }
}

//VALIDATION OF ORDERFORM - 
export const validateForm = (orderForm, errors, t) => {
  let isSuccess = true;
  for (const field of requiredFields) {
    if (!orderForm[field] || orderForm[field] === "") {
      isSuccess = false;
    }
  }

  if (!isValidPhoneNumber(orderForm.phoneNumber)) {
    isSuccess = false;
  }
  if (!isValidPhoneNumber(orderForm.stayphoneNumber)) {
    isSuccess = false;
  }
  if (orderForm.email) {
    if (!emailRegex.test(orderForm.email)) {
      errors.email = t("buyticket:invalidEmailFormat");
      isSuccess = false;
    } else {
      errors.email = null;
    }

  }
  if (orderForm.CopyOfEmail) {
    if (!emailRegex.test(orderForm.CopyOfEmail)) {
      errors.CopyOfEmail = t("buyticket:invalidEmailFormat");
      isSuccess = false;
    } else {
      errors.CopyOfEmail = null;
    }
  }

  return isSuccess;
};


const isValidPhoneNumber = (phoneNumber) => {
  return phoneNumber.length > 3;
}
