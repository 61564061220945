import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCart } from "./CartContext";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import { useAuth } from "./AuthContext";
import config from '../configs/config';
import { useForm } from "./FormContext";
import { useSearchData } from "./SearchContext";
import enums from '../commons/Enums'

export default function SearchResultActivity({onSearchMoreActivites}) {
  const { t, i18n } = useTranslation(["common", "buyticket"]);
  const params = useParams();
  const { authenticated, checkTokenExpiry } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [noMoreResults, setNoMoreResults] = useState(false);
  const navigate = useNavigate();
  const { fetchCartItems } = useCart();
  const [areaSlug, setAreaSlug] = useState("");
  const apiUrl = config.default.apiPath;
  const {formRequest, setFormRequest} = useForm();
  const {searchData} = useSearchData();
  const { languagesToIndex } = enums;
  useEffect(()=>{
    setAreaSlug(params.id)
  }, [params.id])
  useEffect(() => {
    // Reset noMoreResults to false whenever searchData changes
    setNoMoreResults(false);
  }, [searchData]);
    if (!searchData || !Array.isArray(searchData)) {       
     return <p>Error: Search data is not in the expected format.</p>;
    }
    checkTokenExpiry();
    var selectedPartnerGroupStr = Cookies.get('selectedPartnerGroup')
    const selectedPartnerGroup = authenticated && selectedPartnerGroupStr
    ? JSON.parse(selectedPartnerGroupStr) : null;

    const formatNumber = (number) => {
      const parts = number.toFixed(2).toString().split('.');
      // Replace dot with comma as decimal separator
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      // Return formatted number
      return parts.join(',');
    };
    
    async function handleAddToCart(activeDepartureIDs, includePartnerGroupID = false) {
      if (clicked) {
        return;
      }
      setClicked(true); 
      try {
        const apiURL = `${apiUrl}/Cart/AddTicketToCart`;
        const sessionId = Cookies.get('sessionID');
        const numberTicketTypes = JSON.parse(localStorage.getItem("numberTicketTypes"));
        const requestBody = {
          sessionId,
          activeDepartureIDs,
          numberTicketTypes,
        };
    
        // Automatically set includePartnerGroupID to true if the user is authenticated
        if (authenticated) {
          checkTokenExpiry();
          const selectedPartnerGroup = JSON.parse(Cookies.get('selectedPartnerGroup'));
          requestBody.partnerGroupID = selectedPartnerGroup.partnerGroupID;
          includePartnerGroupID = true;
        } else {
          // If not authenticated, make sure includePartnerGroupID is false
          includePartnerGroupID = false;
        }
    
        const response = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json-patch+json",
            // Include Authorization header only if partnerGroupID is included and user is authenticated
            ...(includePartnerGroupID && {
              Authorization: `Bearer ${Cookies.get('authToken')}`,
            }),
          },
          body: JSON.stringify(requestBody),
        });
    
        if (response.ok) {
          await fetchCartItems();
          navigate("/my-cart");
        } else {
          // Handle error if needed
          console.error('Failed to add to cart.');
        }
      } catch (error) {
        console.error("An error occurred while adding to cart:", error);
      }
      finally {
        setClicked(false);
      }
    }
    
    const handleSearchMore = async () => {
      const departureDate = new Date(formRequest.departureDate);
      const year = departureDate.getFullYear();
      const day = String(departureDate.getDate()).padStart(2, '0');
      const month = String(departureDate.getMonth() + 1).padStart(2, '0');
      const formattedDate = `${year}-${day}-${month}`;
      const nextPage = formRequest.page + 1;
      
      // Update page number
      setFormRequest((prevData) => ({
        ...prevData,
        page: nextPage,
      }));
      const apiURL = `${apiUrl}/Cart/SearchActivityJourney`;
      console.log("apiURL", apiURL);
      const requestBody = {
        areaID: areaSlug,
        departureStopID: formRequest.departureStopID,
        arrivalStopID: formRequest.arrivalStopID,
        departureDate: formattedDate,
        numberTicketTypes: formRequest.numberTicketTypes,
        page: nextPage,
        isTransfer: formRequest.isTransfer,
        isSearchEarlier: formRequest.isSearchEarlier,
        ...(authenticated && selectedPartnerGroup && { partnerGroupID: selectedPartnerGroup.partnerGroupID }),
      };
      console.log("Form Request", requestBody);
      try {
        const response = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "LanguageID": languagesToIndex[i18n.language],
            ...(authenticated && { Authorization: `Bearer ${Cookies.get('authToken')}` }),
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          const errorData = await response.json();
          return Promise.reject(errorData);
        }
        const data = await response.json();
        if (data.length === 0) {
          setNoMoreResults(true);
        } else {
          // Update the searchData with the new data
          onSearchMoreActivites(data);
        }
      } catch (error) {
        console.error('Error fetching more data:', error);
      }
      console.log(noMoreResults);
    };
    //More Details About Journey MODAL 
    const handleDetailsClick = async (activeDepartureIDs, position) => {
      try {
        let headers = {
          'Content-Type': 'text/plain',
          'LanguageID': languagesToIndex[i18n.language]
        };
    
        // Check if the user is authenticated

        let url = `${apiUrl}/Cart/GetDetailActivityJourney?activeDepartureIDs=${activeDepartureIDs}`;
    
        if (authenticated) {
          // If authenticated, get partnerGroupID and authToken
          checkTokenExpiry();
          const selectedPartnerGroup = JSON.parse(Cookies.get('selectedPartnerGroup'));
          const partnerGroupID = selectedPartnerGroup.partnerGroupID;
          const authToken = Cookies.get('authToken');
          // Add authentication header and modify URL
          headers = {
            ...headers,
            'Authorization': `Bearer ${authToken}`
          };
    
          url += `&partnerGroupID=${partnerGroupID}`;
        }
    
        const response = await fetch(url, {
          method: 'GET',
          headers: headers,
        });
    
        const data = await response.json();
        setModalContent({ data, position });
        setShowModal(true);
      } catch (error) {
        console.error("Error fetching activity details:", error);
      }
    };

      function ActivityDetailsModal({  content, onClose }) {
        const { position, data } = content || {};
        const ticketTypePrices = data?.ticketTypePrices || [];
        const itineraries = data?.itineraries || [];
        useEffect(() => {
          const handleClickOutsideModal = (event) => {
            const modalContainer = document.querySelector(".details-modal");
            if (modalContainer && !modalContainer.contains(event.target)) {
              onClose(); // Close the modal when clicked outside
            }
          };
      
          document.addEventListener("click", handleClickOutsideModal);
      
          return () => {
            document.removeEventListener("click", handleClickOutsideModal);
          };
        }, [onClose]);
        // Calculate the modal position based on the button's position
        let modalStyle = {
          position: "absolute",
          top: position ? position.y + "px" : 0,
          left: position ? 300 + "px" : 0,
          transform: "translateY(10px)", 
          backgroundColor: "#fff",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          borderRadius: "4px",
          padding: "10px",
          zIndex: 9999,
          textAlign: "center"
        };
      
        return (
          <div className="details-modal" style={modalStyle}>
            <div>
              <div className="modal-content">
              {data && (
                <div className="ticket-types">
                  <h3>{t("buyticket:ticketPrices")}</h3>
                  {ticketTypePrices.length > 0 ? (
                    ticketTypePrices.map((price) => (
                      <div key={price.ticketTypeID} className="ticket-type">
                        <p className="bold">{price.ticketTypeName}: </p>
                        <p>{formatNumber(price.price)} DKK</p>
                      </div>
                    ))
                  ) : (
                    <p>No ticket type prices available.</p>
                  )}
                </div>
              )}
                  <div className="itineraries">
                  <h3>{t("buyticket:itinerary")}</h3>
                  {itineraries.map((activity) => (
                    <div key={activity.activeDepartureID} className="itinerary">
                    <p> {activity.departureTime.slice(11, 16)}</p> -
                    <p>{activity.arrivalTime.slice(11, 16)}</p>
                    <p>{activity.departureStopName}</p>
                  </div>
                  ))}
                  </div>
                    </div>
              <button className="modal-close" onClick={onClose}>
                {t("buyticket:closeDetails")}
              </button>
            </div>
          </div>
        );
      };
      //END OF MODAL


    const journeys = searchData;
  
    return (
      <div>
        {journeys.length === 0 ? (
          <p>{t("noResultFound")}</p>
        ) : (
          <div className="search-results activity">
            <h2>{t("buyticket:selectJourney")}</h2>
            <div className="search-result-list-container">
              {journeys.map((result) => (
                <div key={result.activeDepartureIDs} className="search-result-list-item">
                  <div className="search-result-lines">
                    <div className="date-and-travel-time">
                    <p className="date-label">{new Date(result.departureDate).toLocaleDateString("da-DK", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                     })}</p>
                      <p>{t("buyticket:travelTime")}: {result.totalTravelTime}</p>
                      <p>{t("buyticket:availableTickets")}: {result.ticketAvailable}</p>
                    </div>
                    <div className="departure-and-time">
                      <h3 className="departure-stop-name">{result.departureStopName}</h3>
                      <p className="departure-time"> {result.departureTime.slice(11, 16)}</p>
                    </div>
                    <div className="total-price">
                      <p className="price-label">{t("buyticket:totalPrice")}</p>
                      <p className="price-value">{formatNumber(result.totalPrice)} DKK</p>
                    </div>
                  </div>
                  <div className="buttons-container">
                  <button
            className="button-details"
            onClick={(event) => {
              const buttonRect = event.currentTarget.getBoundingClientRect();
              handleDetailsClick(result.activeDepartureIDs, {
                x: buttonRect.left,
                y: buttonRect.bottom + window.scrollY,
              });
            }}
          >
            {t("buyticket:details")}
          </button>
                    <button className="button-buy" onClick={() => handleAddToCart(result.activeDepartureIDs)}>{t("buyTicket")}</button>
                  </div>
                </div>
              ))}
            </div>
            {noMoreResults ? null : (
              <div className="button-container">
          <button className="btn-secondary search-more" onClick={handleSearchMore}>{t("buyticket:searchMoreActivites")}</button>
          </div>
        )}
            <div>
              {showModal && (
                <div>
                  <ActivityDetailsModal  content={modalContent} onClose={() => setShowModal(false)} />
                  </div>
                  )}
                  </div>
          </div>
        )}
      </div>
    );
  };
  