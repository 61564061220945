import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { toast } from "react-toastify";
import config from '../../configs/config';
import MyJourneyDescription from "../../components/MyJourneyDescriptions";

export default function ChangePhoneNumber() {
  const { t, i18n } = useTranslation(["common", "toastmessages"]);
  const [ticketData, setTicketData] = useState(null);
  const [orderNumber, setOrderNumber] = useState("");
  const [ticketNumber, setTicketNumber] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [error, setError] = useState(null);
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false);
  const apiUrl = config.default.apiPath;
  function mapLanguageCodeToCountryCode(languageCode) {
    // Define your mapping here, e.g., for Danish (dk) and English (en)
    const languageMappings = {
      dk: "DK", 
      en: "US", 
      gl: "GL", 
    };
    return languageMappings[languageCode] || "GL"; 
  }
  const handleTicketNumberChange = (e) => {
    setTicketNumber(e.target.value);
  };

  const handleOrderNumberChange = (e) => {
    setOrderNumber(e.target.value);
  };
  const handlePhoneNumberChange = async (e) => {
    e.preventDefault();
    try {
      if (!ticketData) {
        // Check if there is an active ticket
        setError(t("errorMessages.ticketNotFound"));
        return;
      }
      // Create the request body
      const requestBody = {
        ticketID: ticketNumber,
        orderID: orderNumber,
        phone: newPhoneNumber,
      };
      // Send the request to change the phone number
      const response = await fetch(`${apiUrl}/Cart/ChangeTicketCustomerPhone`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(t("errorMessages.genericError"));
      }
      // Phone number change was successful
      const numberChanged = t("toastmessages:changeNumber");
      toast.success(numberChanged);
      setError(null);
      setShowPhoneNumberInput(false);
    } catch (error) {
      setError(error.message || t("errorMessages.genericError"));
    }
  };

const handleSearch = async (e) => {
  e.preventDefault();
  try {
    setError(null);
    
    const url = `${apiUrl}/Cart/SearchActiveTicket?OrderID=${orderNumber}&TicketID=${ticketNumber}`;
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error(t("errorMessages.genericError"));
    }
    
    const responseData = await response.json();
    
    if (Array.isArray(responseData) && responseData.length > 0) {
      setTicketData(responseData[0]);
      setShowPhoneNumberInput(true);
    } else if (responseData.success === false) {
      setError(responseData.errorMessage || t("errorMessages.genericError"));
      setTicketData(null);
    }
  } catch (error) {
    setError(error.message || t("errorMessages.genericError"));
  }
};


  return (
    <div className="changephonenumberpage">
      <Helmet>
        <title>{t('changePhoneNo')} | Disko Line {t("greenland")}</title>
      </Helmet>
      <h1>{t('changePhoneNo')}</h1>
        <MyJourneyDescription Id="3" />
      {!showPhoneNumberInput && (
        <form onSubmit={handleSearch}>
          <div>
            <label>
                <div className="label-and-input">
            {t("orderID")}:
              <input
                type="text"
                value={orderNumber}
                onChange={handleOrderNumberChange}
                />
                </div>
            </label>
          </div>
          <div>
            <label>
                <div className="label-and-input">
            {t("ticketID")}:
              <input
                type="text"
                value={ticketNumber}
                onChange={handleTicketNumberChange}
                />
                </div>
            </label>
          </div>
          <div className="button-center">
            <button className="btn search" type="submit">
              {t("search")}
            </button>
          </div>
        </form>
      )}
      {error && <p className="error">{error}</p>}
      {showPhoneNumberInput && (
  <form onSubmit={handlePhoneNumberChange}>
    <div className="current-phone"><p>{t("currentPhoneNumber")}:</p><p className="phone">{ticketData.ticketCustomerPhone}</p></div>
    <div>
      <label>
        <div className="label-and-input">
          {t("newPhoneNumber")}:
      <PhoneInputWithCountrySelect  defaultCountry={mapLanguageCodeToCountryCode(i18n.language)} value={newPhoneNumber} onChange={setNewPhoneNumber} />
        </div>
      </label>
    </div>
    <div className="button-center">
      <button className="btn save" type="submit">
        {t("saveChanges")}
      </button>
    </div>
  </form>
)}
    </div>
  );
}