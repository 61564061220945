import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";


// Images
import IlimanaqHeader from "../../images/area/IlimanaqHeader.jpg"
import Ilimanaq1 from "../../images/area/Ilimanaq1.jpg";
import Ilimanaq2 from "../../images/area/Ilimanaq2.jpg";
import Ilimanaq3 from "../../images/area/Ilimanaq3.jpg";
import Ilimanaqsektion2 from "../../images/area/Ilimanaqsektion2.jpg";
import Ilimanaq1sektion3 from "../../images/area/Ilimanaq1sektion3.jpg";
import Ilimanaq2sektion3 from "../../images/area/Ilimanaq2sektion3.jpg";


export default function Ilimanaq() {
    const { t } = useTranslation(["common", "areas"]);
    const headerStyle = {
      backgroundImage: `url(${IlimanaqHeader})`,
      position: 'relative',
    };

    const overlayStyle = {
      content: "",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0.3)', 
    };
    useLayoutEffect(() => {
      // Scroll to the top on load
      window.scrollTo(0, 0);
    }, []);

return(
    <div className="area-pages">
        <Helmet>
            <title>Ilimanaq | Disko Line {t("greenland")}</title>
        </Helmet>
        <div className="header" style={headerStyle}>
        <div style={overlayStyle}></div>
        <div className="header-content">
          <div className="header-tag">
            <h1 className="bolder">{t('areas:Ilimanaq')}</h1>
            <h3 className="subHeader">{t('areas:villageVisit')}</h3>
          </div>
        </div>
      </div>

      <div className="section1">
        <h2>{t('areas:section1Ilimanaq')}</h2>
        <p>{t('areas:firstpSektion1Ilimanaq')}</p>
        <h3>{t('areas:subheader1sektion1')}</h3>
        <p>{t('areas:secondpSektion1Ilimanaq')}</p>
        <h3>{t('areas:subheader2sektion1')}</h3>
        <p>{t('areas:thirdpSektion1Ilimanaq')}</p>
        <div className="img-container-sektion1">
        <img className="Ilimanaq1" src={Ilimanaq1} alt="Ilulissat" />
        <img src={Ilimanaq2} alt="Ilulissat" />
        <img src={Ilimanaq3} alt="Ilulissat" />
        </div>
      </div>

      <div className="section2">
        <h2>{t('areas:section2IIlimanaq')}</h2>
        <p>{t('areas:firstpSektion2Ilimanaq')}</p>
        <p>{t('areas:secondpSektion2Ilimanaq')}</p>
        <h3>{t('areas:subheader1sektion3')}</h3>
        <p>{t('areas:thirdpSektion2Ilimanaq')}</p>
        <p>{t('areas:fourthpSektion2Ilimanaq')}</p>
        <div className="img-container-sektion2">
        <img src={Ilimanaqsektion2} alt="Ilulissat" />
        </div>
      </div>

      <div className="section3">
        <h2>{t('areas:section3Ilimanaq')}</h2>
        <p>{t('areas:firstpSektion3Ilimanaq')}</p>
        <p>{t('areas:secondpSektion3Ilimanaqt')}</p>
        <div className="img-container-sektion3">
        <img src={Ilimanaq1sektion3} alt="Ilulissat" />
        <img src={Ilimanaq2sektion3} alt="Ilulissat" />
        </div>
      </div>

      </div>
)
}