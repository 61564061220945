import Cookies from "js-cookie";
import config from '../configs/config';

let getAuthenHeader = function() {
    const authToken = Cookies.get("authToken");
    if (!authToken) {
        throw new Error("Authentication token not found in cookies");
    }

    return {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
    };
};

let getPartnerGroupID = function() {
    const selectedPartnerGroupCookie = Cookies.get("selectedPartnerGroup");
    if (!selectedPartnerGroupCookie) {
        throw new Error("Selected partner group not found in cookies");
    }

    let selectedPartnerGroup;
    try {
        selectedPartnerGroup = JSON.parse(selectedPartnerGroupCookie);
    } catch (error) {
        throw new Error("Error parsing selected partner group cookie");
    }

    return selectedPartnerGroup.partnerGroupID;
};

let getApiUrl = function() {
    return config.default.apiPath;
};

const baseService = { getAuthenHeader, getPartnerGroupID, getApiUrl };

export default baseService;