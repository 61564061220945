import { Helmet } from "react-helmet";
import headerImage from "../../../images/dle-tours/fishing-trip/fishing-header.jpg";
import inclusionImage from "../../../images/dle-tours/fishing-trip/Torsk.jpg";
import wideImage from "../../../images/dle-tours/fishing-trip/fishing-header.jpg";
import programImage from "../../../images/dle-tours/fishing-trip/Markus-og-Magnus-scaled.jpg";
import { useTranslation, Trans } from "react-i18next";
import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../configs/config";

export default function FishingTrip(){
    const apiUrl = config.default.apiPath;

    const {t} = useTranslation(["common", "diskolineexplorer"]);
    const navigate = useNavigate();
    useLayoutEffect(() => {
        // Scroll to the top on load
        window.scrollTo(0, 0); 
      }, []);
      function handleGoBack(){
        navigate("/diskolineexplorer/sailing-trips");
      }
      function handleGoToExplorerArea(){
        navigate("/buy-ticket/93?selectedActivity=173")
      }

    return(
       <div className="diskoline-explorer-tour">
        {/*HEAD*/}
        <Helmet><title>{t('fishingTrip')} | {t('diskoLineExplorer')}</title></Helmet>
        <div className="header header-shadow">
                    <div className="header-content">
                    <img src={headerImage} alt="Fishing-trip"/>
                        <div className="hero-image-text">
                        <p className="tagline">Disko Line Explorer</p>
                        <h1>{t('fishingTrip')} </h1>
                        </div>
                    </div>
        </div>
        <div className="content">
                <div className="central_row">
                <div className="breadcrumb"><p><span className="breadcrumb-1" onClick={handleGoBack}>{t('sailingTrips')}</span>/<span className="breadcrumb-2">{t('fishingTrip')}</span></p></div>
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('fishingTrip')} </h2>
                    <p><b>{t('dle:fishingTripSubHeader')}</b></p>
                    <p>{t('dle:fishingTripDescription')}</p>
                    <br></br>
                    <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>
                    </div>
                <div className="row">
                    <div className="section">
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dle:tripIncludes')}</h2>
                    <ul>
                        <li>{t('dle:fishingTripInclusion1')}</li>
                        <li>{t('dle:fishingTripInclusion2')}</li>
                        <li>{t('dle:fishingTripInclusion3')}</li>
                        <li>{t('dle:fishingTripInclusion4')}</li>
                        <li>{t('dle:fishingTripInclusion5')}</li>
                        <li>{t('dle:fishingTripInclusion6')}</li>
                        <li>
                        <Trans i18nKey="dle:fishingTripInclusion7">
                        klikke<a href={`${apiUrl}/System/GetSharedFile?fileName=How-to-acquire-fishing-license-in-Greenland.pdf`} target="_blank" rel="noopener noreferrer">her</a>.
                        </Trans>
                        </li>
                    </ul>
                    <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>

                    </div>
                    <div className="section">
                        <img src={inclusionImage} alt="Disko Line Explorer" className="img"/>

                    </div>
                </div>
        </div>
        <div >
            <img src={wideImage} alt="Fishing boat" className="img video-div"/>
            </div>
        <div className="content">
                <div className="row">
                    <div className="section">
                        <img src={programImage} alt="Disko Line Boat" className="img"/>
                    </div>
                    <div className="section">
                        <p className="tagline">Disko Line Explorer</p>
                        <h2>{t('dle:fishingTripHeader2')}</h2>
                        <p>{t('dle:fishingTripDescription2.1')}</p>
                        <br></br>
                        <p>{t('dle:fishingTripDescription2.2')}</p>
                        <br></br>
                        <p>{t('dle:fishingTripDescription2.3')}</p>
                        <br></br>
                        <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>
                    </div>
                </div>
                <div className="practical-info">
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dle:practical')} </h2>
                    <br></br>
                    <p><span className="bold">{t('dle:duration')}</span> {t('dle:fishingTripDuration')}</p>
                    <p><span className="bold">{t('dle:season')}</span> {t('dle:fishingTripSeason')}</p>
                    <p><span className="bold">{t('dle:note')}</span> {t('dle:fishingTripNote')} </p>
                    <br>
                    </br><button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')}</button>
        
                </div>
        </div>
       </div>
    )
}