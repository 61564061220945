import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import headerImage from "../../images/headers/Icebergs_from_above.jpg";
import textImage from "../../images/targaInSunset.jpg";
import { useTranslation } from "react-i18next";

export default function AdviceAndGuidance(){
    const {t} = useTranslation(["common", "practicalinfo"]);
    const navigate = useNavigate();

    function handleNavigateToRefund(){
  navigate("/my-journey/cancel-ticket");
}
function handleNavigateToCarriageRegulations(){
    navigate("/practical-information/befordringsbestemmelser");
}
function handleNavigateToContact(){
    navigate("/contact");
}
function handleNavigateToBuyTicket(){
    navigate("/buy-ticket");
}
const headerStyle = {
    backgroundImage: `url(${headerImage})`
}
    return(
        <div className="adviceandguidancepage">
              {/*Head*/}
              <Helmet>
                <title>{t("adviceAndGuidance")}  | Disko Line {t("adviceAndGuidance")}</title>
            </Helmet>
            {/*Body*/}
            <header style={headerStyle}>
                <div className="header-text">
                    <p>Disko Line</p>
                    <h1>{t("adviceAndGuidance")}</h1>
                </div>
            </header>
            <div className="content">
                <div className="grid">
                <div className="section">
                <p className="diskoline-header">Disko Line</p>
                <h2>{t("adviceAndGuidance")}</h2>
                <h3>{t("practicalinfo:purchaseOfTicketTitle")}</h3>
                <p>{t("practicalinfo:purchaseOfTicketBody")}</p>
                <br></br>
                <h3>{t("practicalinfo:refundTicketTitle")}</h3>
                <p>{t("practicalinfo:refundTicketBody")} <span className="clickable underline" onClick={handleNavigateToRefund}>{t("practicalinfo:refund")}</span></p>
                <br></br>
                <h3>{t("practicalinfo:journeysTitle")}</h3>
                <p>{t("practicalinfo:journeysBody1")}</p>
                <p>{t("practicalinfo:journeysBody2")}</p>
                </div>
                <div className="section">
                <img src={textImage} alt="DiskoLine"/>
                </div>
                </div>
                <div className="center-section">
                <h3>{t("practicalinfo:carriageRegulationsTitle")}</h3>
                <p>{t("practicalinfo:carriageRegulationsBody")}</p>
                <p onClick={handleNavigateToCarriageRegulations} className="clickable underline">{t("practicalinfo:clickToReadCarriageRegulations")}</p>
                <p>{t("practicalinfo:youAreAlsoWelcome")} <span className="clickable underline" onClick={handleNavigateToContact}>{t("practicalinfo:contactUs")}</span> {t("practicalinfo:moreAdviceAndGuidance")}</p>
                <button className="buyticket btn" onClick={handleNavigateToBuyTicket}>{t("buyTicket")}</button>
                </div>
            </div>
        </div>
    )
}