import { Helmet } from "react-helmet";
//Images
import headerImage from "../../images/headers/charter-header.jpg";
import targaImage from "../../images/targaInSunset.jpg";
import boatImage from "../../images/diskolineboat.jpg";
import { useTranslation } from "react-i18next";
export default function Charter(){
    const {t} = useTranslation(["common", "about"])
    const headerStyle = {
        backgroundImage: `url(${headerImage})`
    }
    return(
        <div className="charterpage">
            <Helmet>
                <title>{t('charter')} | Disko Line Greenland</title>
            </Helmet>
            <div className="header" style={headerStyle}>
                <div className="header-content">
                    <p>Disko Line</p>
                    <h1>{t('charter')} </h1>
                </div>
            </div>
            <div className="content">
                <div className="row">
                    <div className="section">

                    <p className="tagline">Disko Line</p>
                    <h2>{t('charter')} </h2>
                    <p>{t('about:aboutChartertext')}</p>
                    <h3>{t('about:aboutCharterIndustry')}</h3>
                    <p>{t('about:aboutCharterIndustryText')}</p>

                    <br></br>
                    <h3>{t('about:aboutCharterTourism')}</h3>
                    <p>{t('about:aboutCharterTourismText')}</p>
                    <br></br>
                    <p><b>{t('about:aboutCharterWhyDL')}</b></p>
                    <ol>
                        <li>{t('about:aboutCharterWhyDL1')}</li>
                        <li>{t('about:aboutCharterWhyDL2')}</li>
                        <li>{t('about:aboutCharterWhyDL3')}</li>
                    </ol>
                    </div>
                    <div className="section">
                        <img src={targaImage} alt="Targa" className="img"/>

                    </div>
                </div>
                <div className="central_row">

                    <p className="tagline">Disko Line</p>
                    <h2>{t('fleet')} </h2>
                    <p><b>{t('about:aboutCharterFleetText1')}</b></p>
                    <p>{t('about:aboutCharterFleet1')}</p>
                    <p>{t('about:aboutCharterFleet2')}</p>
                    <p>{t('about:aboutCharterFleet3')}</p>
                    <br></br>
                    <p>{t('about:aboutCharterFleetText2')} </p>
                    <br></br>
                    <button className="btn">
                        <a href="/om-disko-line/flaaden">{t('about:aboutCharterFleetbtn')}</a>
                    </button>
                </div>
                <div className="row">
                    <div className="section">
                        <img src={boatImage} alt="Disko Line Boat" className="img"/>
                    </div>
                    <div className="section">

                        <p className="tagline">Disko Line</p>
                        <h2>{t('about:aboutCharterConditions')}</h2>

                        <ol>
                            <li>{t('about:aboutCharterConditions1')}</li>
                           <li>{t('about:aboutCharterConditions2')}</li>
                           <li>{t('about:aboutCharterConditions3')}</li>
                           <li>{t('about:aboutCharterConditions4')}</li>
                           <li>{t('about:aboutCharterConditions5')}
                                <ul>
                                    <li>{t('about:aboutCharterConditions6')}</li>
                                    <li>{t('about:aboutCharterConditions7')}</li>
                                </ul>
                           </li>
                           <li>{t('about:aboutCharterConditions8')}</li>
                        </ol>
                        <br></br>
                        <button className="btn">
                            <a href="/contact">{t('contactUs')}</a>
                    </button>
                    </div>
                </div>
            </div>
        </div>
    )
}