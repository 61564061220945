import React, { useState, useEffect, useLayoutEffect } from "react";
import BuyTicketTabs from "../../components/BuyTicketTabs";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useAuth } from "../../components/AuthContext";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import InfoQuestion from "../../components/InfoQuestion";
import config from "../../configs/config";
import {
  OrderInputField,
  OrderData,
  handleOrderFormInputChange,
  validateForm,
} from "../../components/OrderData";
import {
  mapLanguageCodeToId,
} from "../../components/LanguageUtils";
import {
  ParticipantForm,
  validateParticipants,
  initializeArrayOfParticipantForms,
} from "../../components/ParticipantForm";

export default function CustomerInformation() {
  const { t, i18n } = useTranslation(["common", "buyticket"]);
  const sessionID = Cookies.get("sessionID");
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  const [isDataValid, setIsDataValid] = useState(false);
  const { authenticated } = useAuth();
  const [arrayOfParticipantForms, setArrays] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [stayphoneNumber, setstayPhoneNumber] = useState();
  const [showInfoQuestion, setshowInfoQuestion] = useState(false);
  const [allquestions, setallquestions] = useState([]);
  const [currentJourneyInfo, setCurrentJourneyInfo] = useState({});
  const [currentQuestionIndex, setcurrentQuestionIndex] = useState(0);
  const [lastQuestionAnswered, setLastQuestionAnswered] = useState(false);
  const [errors, setErrors] = useState({});

  const Questions = [];
  const apiUrl = config.default.apiPath;

  // Imports
  // State needs same name as the components state to work. idk why
  const { orderForm, setOrderForm } = OrderData({ sessionID });

  // Handles InfoQuestion next button
  // fieldsdata is an array of answers
  const handleAnswerClick = async (fieldsData, lastQuestion, type) => {
    const updatedArrayOfParticipantForms = [...arrayOfParticipantForms];

    // For each item in fieldsData array, create an answerItem if not handleNotRelevant
    fieldsData.forEach((data) => {

      // check answerItem not handleNotRelevant
      if (type !== undefined && type !== null && data.stringValue != null) {
        const answerItem = {
          infoTransportQuestionID: data.infoTransportQuestionID,
          answer: data.stringValue,
          infoTransportID: data.infoTransportID,
          infoQuestionID: data.infoQuestionID,
          type: type
        }; // Empty 
        updatedArrayOfParticipantForms[data.index].forEach((ticketType) => {
          ticketType.forEach((participant) => {
            participant.infoTransportAnswers.push(answerItem);
          });
        });
      }
    });

    setArrays(updatedArrayOfParticipantForms);

    if (lastQuestion) {
      // Perform the necessary logic when the last question of the last set is answered
      setLastQuestionAnswered(true);
      handleCloseAllQuestions();
    } else {
      setshowInfoQuestion(true);
      setcurrentQuestionIndex((prev) => prev + 1);
    }
  };

  // Handles InfoQuestion next button
  // fieldsdata is an array of answers
  const handleNextClick = async (fieldsData, lastQuestion, type) => {
    await handleAnswerClick(fieldsData, lastQuestion, type);
  };

  // Handles InfoQuestion not relevant button
  const handleNotRelevantClick = async (fieldsData, lastQuestion) => {
    const currentQuestion = allquestions[currentQuestionIndex];
    const currentIndex = currentQuestion.Index;
    setCurrentJourneyInfo({
      departureDate: cartItems[currentIndex].departureDate,
      departureStopName: cartItems[currentIndex].departureStopName,
      arrivalStopName: cartItems[currentIndex].arrivalStopName,
      arrivalTime: cartItems[currentIndex].arrivalTime,
      departureTime: cartItems[currentIndex].departureTime,
    });
    await handleAnswerClick(fieldsData, lastQuestion);
  };

  const handleCloseAllQuestions = async () => {
    setshowInfoQuestion(false);
    await addCustomerCartInformation();
    setcurrentQuestionIndex(0);
  };
  // Updates participant field changes
  // Handles replication and manuel changes to fields
  const handleChange = (
    fieldType,
    value,
    arrayIndex,
    typeIndex,
    fieldIndex,
    isManualChange,
    isPhoneManualChange
  ) => {
    const updateField = (field, i, j, k) => {
      let updatedField = { ...field };
      updatedField.CustomerCartID =
        cartItems[arrayIndex]?.ticketTypes[typeIndex]?.customerCartID;
      if (i === arrayIndex && j === typeIndex && k === fieldIndex) {
        if (fieldType === "name") {
          return { ...field, name: value, isManualChange: isManualChange };
        } else if (fieldType === "phoneNumber") {
          return {
            ...field,
            phone: value,
            isPhoneManualChange: isPhoneManualChange,
          };
        }
      } else {
        // Apply manual change checks for other fields
        if (fieldType === "name" && !field.isManualChange) {
          return { ...field, name: value };
        } else if (fieldType === "phoneNumber" && !field.isPhoneManualChange) {
          return { ...field, phone: value };
        }
      }

      return field;
    };
    /// journey 0
    if (arrayIndex === 0) {
      /// Journey Index 0, first type 0 , first of a type 0
      /// this sets the first participant field and only that
      if (fieldIndex === 0 && typeIndex === 0 && !authenticated) {
        setArrays((prev) =>
          prev.map((arr, i) =>
            i === arrayIndex
              ? arr.map((typeArr, j) =>
                j === typeIndex
                  ? typeArr.map((field, k) =>
                    k === fieldIndex ? updateField(field, i, j, k) : field
                  )
                  : typeArr
              )
              : arr
          )
        );
        /// rest of journey 0 excluded first participant field above
        /// Replicates rest of the fields at same index
      } else {
        setArrays((prev) =>
          prev.map((arr) =>
            arr.map((typeArr, j) =>
              j === typeIndex
                ? typeArr.map((field, k) =>
                  k === fieldIndex ? updateField(field) : field
                )
                : typeArr
            )
          )
        );
      }
      /// Non 0 indexes
    } else {
      setArrays((prev) =>
        prev.map((arr, i) =>
          i === arrayIndex
            ? arr.map((typeArr, j) =>
              j === typeIndex
                ? typeArr.map((field, k) =>
                  k === fieldIndex ? updateField(field, i, j, k) : field
                )
                : typeArr
            )
            : arr
        )
      );
    }
  };

  //--------------------------UseEffects----------------------------------------------------------


  // Initial loading of the page and the required data.
  // On SessionID Changes.
  // Question: Do SessionID change when we go to this page ?
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getCustomerCartItems();
    getPartnerInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionID]);
  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      const currentQuestion = allquestions[currentQuestionIndex];
      const currentIndex = currentQuestion.Index;
      setCurrentJourneyInfo({
        departureDate: cartItems[currentIndex].departureDate,
        departureStopName: cartItems[currentIndex].departureStopName,
        arrivalStopName: cartItems[currentIndex].arrivalStopName,
        arrivalTime: cartItems[currentIndex].arrivalTime,
        departureTime: cartItems[currentIndex].departureTime,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex]);
  // 2
  // Initialization of participantsforms array and Check Stop Id´s
  // On cartItem changes. Cartitems change when getCustomerCartItems is called
  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      setArrays(initializeArrayOfParticipantForms(cartItems));
      CheckStopIDs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  useEffect(() => {
    var errors = {};
    const isValid =
      validateForm(orderForm, errors, t) && validateParticipants(arrayOfParticipantForms);
      setErrors(errors);
    setIsDataValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderForm, phoneNumber, stayphoneNumber, arrayOfParticipantForms]);

  /// If order phone input is changed, replicate to other phone fields (index 0)
  /// Dosent replicate to fields manuelly edited
  useEffect(() => {
    if (arrayOfParticipantForms.length > 0) {
      if (!authenticated) {
        setArrays((prev) =>
          prev.map((order) =>
            order.map((ticketType, typeIdx) => {
              if (typeIdx === 0) {
                return ticketType.map((field, fieldIdx) => {
                  if (fieldIdx === 0 && !field.isPhoneManualChange) {
                    return {
                      ...field,
                      phone: orderForm.phoneNumber,
                    };
                  }
                  return field;
                });
              }
              return ticketType;
            })
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderForm.phoneNumber]);

  /// If order name input is changed, replicate to other name fields (index 0)
  /// Dosent replicate to fields manuelly edited
  useEffect(() => {
    if (arrayOfParticipantForms.length > 0) {
      if (!authenticated) {
        setArrays((prev) =>
          prev.map((order) =>
            order.map((ticketType, typeIdx) => {
              if (typeIdx === 0) {
                return ticketType.map((field, fieldIdx) => {
                  if (fieldIdx === 0 && !field.isManualChange) {
                    return {
                      ...field,
                      name: orderForm.fullName,
                    };
                  }
                  return field;
                });
              }
              return ticketType;
            })
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderForm.fullName]);

  //--------------------------UseEffects----------------------------------------------------------

  //--------------------------Functions----------------------------------------------------------

  // 1
  // Gets cartitems from related sessionID
  async function getCustomerCartItems() {
    const sessionID = Cookies.get("sessionID");
    const languageId = mapLanguageCodeToId(i18n.language);
    const response = await fetch(
      `${apiUrl}/Cart/GetCartItems?sessionID=${sessionID}`,
      {
        headers: {
          "Content-Type": "application/json",
          LanguageID: languageId,
        },
      }
    );
    const data = await response.json();
    setCartItems(data.cartItems);
  }

  // Get PartnerInformation
  // If logged in, populate the order From with partner info.
  // otherwise this dosent do anything
  async function getPartnerInformation() {
    if (authenticated) {
      const selectedPartnerGroup = JSON.parse(
        Cookies.get("selectedPartnerGroup")
      );
      const partnerGroupID = selectedPartnerGroup.partnerGroupID;
      const partnerUserID = selectedPartnerGroup.partnerUserID;
      const response = await fetch(
        `${apiUrl}/Partner/GetPartnerGroupPartnerUserByIDs/${partnerGroupID}/${partnerUserID}`
      );
      const data = await response.json();
      const partnerGroupInfo = data.partnerGroup || {};
      const partnerUserInfo = data.partnerUser || {};

      const stayphoneNumberFromPartner =
        partnerUserInfo.partnerStayTel?.toString();
      const phoneNumberFromPartner = partnerUserInfo.partnerTel?.toString();
      setstayPhoneNumber(stayphoneNumberFromPartner);
      setPhoneNumber(phoneNumberFromPartner);
      setOrderForm({
        fullName: partnerUserInfo.partnerName || "",
        address: partnerGroupInfo.partnerGroupAddress || "",
        postalCode: partnerGroupInfo.partnerGroupZip || "",
        city: partnerGroupInfo.partnerGroupCity || "",
        country: partnerGroupInfo.partnerGroupCountry || "",
        companyName: partnerGroupInfo.partnerGroupName || "",
        email: partnerUserInfo.partnerEmail.trim() || "",
        CopyOfEmail: partnerUserInfo.partnerAccEmail?.trim() || "",
        phoneNumber: phoneNumberFromPartner || "",
        stayphoneNumber: stayphoneNumberFromPartner || "",
        sessionID: sessionID,
      });
    }
  }

  async function getInfoTransportQuestions(departureStopID, arrivalID) {
    let languageCode = mapLanguageCodeToId(i18n.language);

    const headers = {
      "Content-Type": "text/plain",
      LanguageID: languageCode,
    };
    const response = await fetch(
      `${apiUrl}/Cart/GetInfoTransportQuestions?departureStopID=${departureStopID}&arrivalStopID=${arrivalID}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const data = await response.json();
    return data;
  }

  // Checks each cartitem for arrival / departure stops
  // if api call returns questions, we add it to
  async function CheckStopIDs() {
    for (let index = 0; index < cartItems.length; index++) {
      const item = cartItems[index];
      const response = await getInfoTransportQuestions(
        item.departureStopID,
        item.arrivalStopID
      );

      if (response.departureQuestions.length > 0) {
        const questionsWithDepartureStopID = response.departureQuestions.map(
          (question) => ({
            ...question,
            type: 1,
            Index: index,
          })
        );
        Questions.push(...questionsWithDepartureStopID);
      }

      if (response.arrivalQuestions.length > 0) {
        const questionsWithArrivalStopID = response.arrivalQuestions.map(
          (question) => ({
            ...question,
            type: 2,
            Index: index,
          })
        );
        Questions.push(...questionsWithArrivalStopID);
      }
    }
    setallquestions(Questions);
  }

  function handleGoToSummary() {
    if (!lastQuestionAnswered) {
      // Set showInfoQuestion to true if there are more question sets to answer
      if (currentQuestionIndex < allquestions.length) {
        const currentQuestion = allquestions[currentQuestionIndex];
        const currentIndex = currentQuestion.Index;
        setCurrentJourneyInfo({
          departureDate: cartItems[currentIndex].departureDate,
          departureStopName: cartItems[currentIndex].departureStopName,
          arrivalStopName: cartItems[currentIndex].arrivalStopName,
          arrivalTime: cartItems[currentIndex].arrivalTime,
          departureTime: cartItems[currentIndex].departureTime,
        });
        setshowInfoQuestion(true);
      } else {
        // If there are no more question sets, or there were no questions, trigger the summary page logic
        addCustomerCartInformation();
      }
    }
  }
  // Adds the order data with participants to database.
  async function addCustomerCartInformation() {
    const apiURL = `${apiUrl}/Cart/AddCartInfomation`;
    const sessionID = Cookies.get("sessionID");
    const languageId = mapLanguageCodeToId(i18n.language);

    const participantArray = [];

    arrayOfParticipantForms.forEach((order) => {
      order.forEach((ticketType) => {
        ticketType.forEach((participant) => {
          participantArray.push(participant);
        });
      });
    });

    const requestBody = {
      order: {
        sessionID: sessionID,
        orderCustomerName: orderForm.fullName,
        orderCustomerAddress: orderForm.address,
        orderCustomerCity: orderForm.city,
        orderCustomerZip: orderForm.postalCode,
        orderCustomerCountry: orderForm.country,
        orderCustomerPhone: orderForm.phoneNumber,
        orderCustomerCompany: orderForm.companyName,
        orderCustomerEmail: orderForm.email,
        orderSecondaryEmail: orderForm.CopyOfEmail,
        orderLanguage: languageId,
        orderCustomerStayPhone: orderForm.stayphoneNumber,
      },
      participants: participantArray.map((participant) => {
        // Remove isManualChange and isPhoneManualChange properties since they are not needed in the api call structure.
        const {
          isManualChange,
          isPhoneManualChange,
          ...participantWithoutFlags
        } = participant;
        return participantWithoutFlags;
      }),
    };
    let headers = {
      "Content-Type": "application/json-patch+json",
    };
    if (authenticated) {
      const authToken = Cookies.get("authToken");
      const selectedPartnerGroup = JSON.parse(
        Cookies.get("selectedPartnerGroup")
      );
      const partnerGroupID = selectedPartnerGroup.partnerGroupID;
      const partnerUserID = selectedPartnerGroup.partnerUserID;
      requestBody.order.partnerGroupID = partnerGroupID;
      requestBody.order.partnerUserID = partnerUserID;
      headers["Authorization"] = `Bearer ${authToken}`;
    }

    await fetch(apiURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestBody),
    });
    navigate("/buy-ticket/summary");
  }


  function handleGoBack() {
    navigate("/my-cart");
  }

  //--------------------------Functions----------------------------------------------------------

  //--------------------------View----------------------------------------------------------

  let journeyNumber = 0;
  return (
    <div className="customerinformationpage">
      <Helmet>
        <title>
          {t("buyticket:information")} | Disko Line {t("greenland")}
        </title>
      </Helmet>
      <BuyTicketTabs activeTab={3} />
      <div className="customer-and-participants">
        <form className="billing-address-form">
          <h2>{t("buyticket:billingAddress")}</h2>
          <div className="input-container">
            <div className="input-list list-1">
              <OrderInputField
                label={t("buyticket:fullName")}
                name="fullName"
                value={orderForm.fullName}
                onChange={(event) =>
                  handleOrderFormInputChange(event, setOrderForm)
                }
                required={true}
              />
              <OrderInputField
                label={t("buyticket:address")}
                name="address"
                value={orderForm.address}
                onChange={(event) =>
                  handleOrderFormInputChange(event, setOrderForm)
                }
                required={true}
              />
              <OrderInputField
                label={t("buyticket:postCode")}
                name="postalCode"
                value={orderForm.postalCode}
                onChange={(event) =>
                  handleOrderFormInputChange(event, setOrderForm)
                }
                required={true}
              />
              <OrderInputField
                label={t("buyticket:city")}
                name="city"
                value={orderForm.city}
                onChange={(event) =>
                  handleOrderFormInputChange(event, setOrderForm)
                }
                required={true}
              />
              <OrderInputField
                label={t("buyticket:country")}
                name="country"
                value={orderForm.country}
                onChange={(event) =>
                  handleOrderFormInputChange(event, setOrderForm)
                }
                required={true}
              />
            </div>

            <div className="input-list list-2">
              <OrderInputField
                label={t("buyticket:companyName")}
                name="companyName"
                value={orderForm.companyName}
                onChange={(event) =>
                  handleOrderFormInputChange(event, setOrderForm)
                }
              />
              <label>
                <div>
                  {t("buyticket:stayPhoneNumber")}
                  <span className="required-field">*</span>:
                  <span title={t("buyticket:stayPhoneNumberInfo")}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </span>
                </div>
              </label>
              <PhoneInputWithCountrySelect
                defaultCountry="GL"
                value={stayphoneNumber}
                name="stayphoneNumber"
                onChange={(value) => {
                  handleOrderFormInputChange({ target: {name:"stayphoneNumber", value : (value ? value : "")}}, setOrderForm)
                }}
                countryOptionsOrder={["GL", "DK", "US", "|", "..."]}
              />
              <label>
                <div>
                  {t("buyticket:phoneNumber")}
                  <span className="required-field">*</span>:
                </div>
              </label>
              <PhoneInputWithCountrySelect
                defaultCountry="GL"
                value={phoneNumber}
                name="phoneNumber"
                onChange={(value) => {
                  handleOrderFormInputChange({ target: {name:"phoneNumber", value : (value ? value : "")}}, setOrderForm)
                }}
                countryOptionsOrder={["GL", "DK", "US", "|", "..."]}
              />
              <OrderInputField
                label={t("buyticket:email")}
                name="email"
                value={orderForm.email}
                onChange={(event) =>
                  handleOrderFormInputChange(event, setOrderForm)
                }
                required={true}
                error={errors.email}
              />

              <OrderInputField
                label={t("buyticket:copyOfEmail")}
                name="CopyOfEmail"
                value={orderForm.CopyOfEmail}
                onChange={(event) =>
                  handleOrderFormInputChange(event, setOrderForm)
                }
                error={errors.CopyOfEmail}
              />
            </div>
          </div>
        </form>

        <div className="participants-container">
          <h2>{t("buyticket:participants")}</h2>
          {arrayOfParticipantForms.map((order, orderIndex) => {
            const key = `${cartItems[orderIndex].departureDate}-${cartItems[orderIndex].departureDate}`;
            journeyNumber++;

            return (
              <div className="tickets-container" key={key}>
                <div className="journey-number">
                  {t("journey")} {journeyNumber}
                </div>
                <div className="journey-info">
                  <p className="date">
                    {" "}
                    {new Date(
                      cartItems[orderIndex].departureDate
                    ).toLocaleDateString("da-DK", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                  <div className="departure-arrival-stops">
                    {/* Conditional rendering of stops */}
                    {cartItems[orderIndex].isActivity ? (
                      <p className="departure-stop-name">
                        {cartItems[orderIndex].departureStopName}
                      </p>
                    ) : (
                      <>
                        <p className="departure-stop-name">
                          {cartItems[orderIndex].departureStopName}
                        </p>
                        <span>-</span>
                        <p className="arrival-stop-name">
                          {cartItems[orderIndex].arrivalStopName}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="departure-arrival-times">
                    <p className="departure-time">
                      {cartItems[orderIndex].departureTime.slice(11, 16)}
                    </p>{" "}
                    -
                    <p className="arrival-time">
                      {cartItems[orderIndex].arrivalTime.slice(11, 16)}
                    </p>
                  </div>
                </div>
                {order.map((ticketType, typeIndex) => (
                  <div className="ticket-types" key={ticketType.ticketTypeName}>
                    {ticketType.map((field, fieldIndex) => {
                      return (
                        <div
                          key={`${ticketType.ticketTypeName}-${fieldIndex}`}
                          className="ticket-line"
                        >
                          <div className="participant-type">
                            {
                              cartItems[orderIndex]?.ticketTypes[typeIndex]
                                ?.ticketTypeName
                            }
                          </div>
                          <ParticipantForm
                            key={fieldIndex}
                            name={field.name}
                            onNameChange={(
                              value,
                              isManualChange,
                              isPhoneManualChange
                            ) =>
                              handleChange(
                                "name",
                                value,
                                orderIndex,
                                typeIndex,
                                fieldIndex,
                                isManualChange,
                                isPhoneManualChange
                              )
                            }
                            phone={field.phone}
                            onPhoneChange={(
                              value,
                              isManualChange,
                              isPhoneManualChange
                            ) =>
                              handleChange(
                                "phoneNumber",
                                value,
                                orderIndex,
                                typeIndex,
                                fieldIndex,
                                isManualChange,
                                isPhoneManualChange
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            );
          })}
          <div className="buttons">
            <button className="btn" onClick={handleGoBack}>
              {t("buyticket:back")}
            </button>
            <button
              onClick={handleGoToSummary}
              className="btn continue"
              disabled={!isDataValid}
            >
              {t("buyticket:continue")}
            </button>
          </div>
          {showInfoQuestion && (
            <InfoQuestion
              allQuestions={allquestions}
              onNextClick={({ dynamicFields, lastQuestion, type }) =>
                handleNextClick(dynamicFields, lastQuestion, type)
              }
              onNotRelevantClick={({ dynamicFields, lastQuestion, type }) =>
                handleNotRelevantClick(dynamicFields, lastQuestion, type)
              }
              onCloseAllQuestions={handleCloseAllQuestions}
              currentJourneyInfo={currentJourneyInfo}
            />
          )}
        </div>
      </div>
    </div>
  );
}
//--------------------------View----------------------------------------------------------