import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";


// Images
import EquipSermiaHeader from "../../images/area/eqipsermiaHeader.jpg";
import Equip from "../../images/area/Equip.jpg";
import Equip1 from "../../images/area/Equip1.jpg";
import Equip2 from "../../images/area/Equip2.jpg";
import Equip3 from "../../images/area/Equip3.jpg";
import Equip4 from "../../images/area/Equip4.jpg";
import Equip5 from "../../images/area/Equip5.jpg";


export default function EquipSermia() {
    const { t } = useTranslation(["common", "areas"]);
    const headerStyle = {
      backgroundImage: `url(${EquipSermiaHeader})`,
      position: 'relative',
    };

    const overlayStyle = {
      content: "",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0.3)', 
    };
    useLayoutEffect(() => {
      // Scroll to the top on load
      window.scrollTo(0, 0);
    }, []);

return(
    <div className="area-pages">
        <Helmet>
          <title>Equip Sermia | Disko Line {t("greenland")}</title>
        </Helmet>
        <div className="header" style={headerStyle}>
        <div style={overlayStyle}></div>
        <div className="header-content">
          <div className="header-tag">
            <h1 className="bolder">{t('areas:EqipSermia')}</h1>
            <h3 className="subHeader">{t('areas:EqiGlacier')}</h3>
          </div>
        </div>
      </div>

      <div className="section1">
        <h2>{t('areas:section1Equip')}</h2>
        <p>{t('areas:firstpSektion1Equip')}</p>
        <p>{t('areas:secondpSektion1Equip')}</p>
        <p>{t('areas:thirdpSektion1Equip')}</p>
        <p>{t('areas:fourthpSektion1Equip')}</p>
        <p>{t('areas:fifthpSektion1Equip')}</p>
        <div className="img-container-sektion1">
        <img className="Equip" src={Equip} alt="Equip Sermia" />
        <img src={Equip1} alt="Equip Sermia" />
        <img src={Equip2} alt="Equip Sermia" />
        </div>
      </div>

      <div className="section2">
        <h2>{t('areas:section2Equip')}</h2>
        <p>{t('areas:firstpSektion2Equip')}</p>
        <p>{t('areas:secondpSektion2Equip')}</p>
        <div className="img-container-sektion2">
        <img src={Equip3} alt="Equip Sermia" />
        </div>
      </div>

      <div className="section3">
        <h2>{t('areas:section3Equip')}</h2>
        <p>{t('areas:firstpSektion3Equip')}</p>
        <p>{t('areas:secondpSektion3Equip')}</p>
        <p>{t('areas:thirdpSektion3Equip')}</p>
        <h3>{t('areas:subheader1sektion3Equip')}</h3>
        <p>{t('areas:fourthpSektion3Equip')}</p>
        <div className="img-container-sektion3">
        <img src={Equip4} alt="Equip Sermia" />
        <img src={Equip5} alt="Equip Sermia" />
        </div>
      </div>
      
      </div>
)
}