import { Helmet } from "react-helmet";
import headerImage from "../../../images/dle-tours/daytrip-eqi/eqi-header.jpg";
import inclusionImage from "../../../images/dle-tours/daytrip-eqi/DSCN5124-scaled.jpg";
import video from "../../../videoes/dle-tours/daytrip-eqi.mp4";
import programImage from "../../../images/dle-tours/daytrip-eqi/Calving-glacier-Eqi_LEIFTAURER.jpg";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function DayTripToEqipSermia(){
    const {t} = useTranslation(["common", "diskolineexplorer"]);
    const headerStyle = {
        backgroundImage: `url(${headerImage})`
    }
    const navigate = useNavigate();
    useLayoutEffect(() => {
        // Scroll to the top on load
        window.scrollTo(0, 0); 
      }, []);
      function handleGoBack(){
        navigate("/diskolineexplorer/sailing-trips");
      }
      function handleGoToExplorerArea(){
        navigate("/buy-ticket/93?selectedActivity=170")
      }
    return(
       <div className="diskoline-explorer-tour">
        {/*HEAD*/}
        <Helmet><title>{t('dayTripToEqipSermia')} | {t('diskoLineExplorer')}</title></Helmet>
        <div className="header header-shadow">
        <div className="header-content">
            <video autoPlay loop webkit-playsinline playsInline>
            <source src={video} type="video/mp4"></source> 
            </video>
            <div className="hero-text">
            <p className="tagline">Disko Line Explorer</p>
            <h1>{t('dayTripToEqipSermia')}</h1>
            </div>
                    </div>
        </div>
        <div className="content">
                <div className="central_row">
                    <div className="breadcrumb"><p><span className="breadcrumb-1" onClick={handleGoBack}>{t('sailingTrips')}</span>/<span className="breadcrumb-2">{t('dayTripToEqipSermia')}</span></p></div>
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dayTripToEqipSermia')}</h2>
                    <p><b>{t('dle:daytripEqiSubHeader')}</b></p>
                    <p>{t('dle:daytripEqiDescription')}</p>
                    <br></br>
                    <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>
                    </div>
                <div className="row">
                    <div className="section">
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dle:tripIncludes')}</h2>
                    <ul>
                        <li>{t('dle:daytripEqiInclusion1')}</li>
                        <li>{t('dle:daytripEqiInclusion2')}</li>
                        <li>{t('dle:daytripEqiInclusion3')}</li>
                        <li>{t('dle:daytripEqiInclusion4')}</li>
                        <li>{t('dle:daytripEqiInclusion5')}</li>
                        <li>{t('dle:daytripEqiInclusion6')}</li>
                        <li>{t('dle:daytripEqiInclusion7')}</li>
                        <li>{t('dle:daytripEqiInclusion8')}</li>
                    </ul>
                    <button  onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>

                    </div>
                    <div className="section">
                        <img src={inclusionImage} alt="Disko Line Explorer" className="img"/>

                    </div>
                </div>
        </div>
            <div className="header header-shadow" style={headerStyle}>
        </div>
        <div className="content">
                <div className="row">
                    <div className="section">
                        <img src={programImage} alt="Disko Line Boat" className="img"/>
                        <p>{t('dle:daytripEqiImage2')}</p>
                    </div>
                    <div className="section">
                        <p className="tagline">Disko Line Explorer</p>
                        <h2>{t('dle:daytripEqiHeader2')}</h2>
                        <p>{t('dle:daytripEqiDescription2.1')}</p>
                        <br></br>
                        <p>{t('dle:daytripEqiDescription2.2')}</p>
                        <br></br>
                        <p>{t('dle:daytripEqiDescription2.3')}</p>
                        <br></br>
                        <button  onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>
                    </div>
                </div>
                <div className="practical-info">
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dle:practical')} </h2>
                    <br></br>
                    <p><span className="bold">{t('dle:duration')}</span> {t('dle:daytripEqiDuration')}</p>
                    <p><span className="bold">{t('dle:season')}</span> {t('dle:daytripEqiSeason')}</p>
                    <p><span className="bold">{t('dle:note')}</span> {t('dle:daytripEqiNote')} </p>
                    <br>
                    </br><button  onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')}</button>
        
                </div>
        </div>
       </div>
    )
}