import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useAuth } from "../../components/AuthContext";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import config from '../../configs/config';

export default function CreatePartnerUser(){
    const {t} = useTranslation(["common", "partner", "toastmessages"]);
    const [loading, setLoading] = useState(false);
    const [partnerName, setPartnerName] = useState("");
    const [partnerEmail, setPartnerEmail] = useState("");
    const [partnerAccEmail, setPartnerAccEmail] = useState("");
    const [partnerTel, setPartnerTel] = useState("");
    const [partnerStayTel, setPartnerStayTel] = useState("");
    const [languageID, setLanguageID] = useState(1);
    const { checkTokenExpiry } = useAuth();
    const apiUrl = config.default.apiPath;
    const navigate = useNavigate();


  useEffect(() => {
    getPartnerInformation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPartnerInformation = async () =>{
    checkTokenExpiry();
    const authToken = Cookies.get('authToken');
    const currentSelectedPartner = JSON.parse(Cookies.get("selectedPartnerGroup"));
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };
    const partnerGroupID = currentSelectedPartner.partnerGroupID;
    const response = await fetch(`${apiUrl}/Partner/GetPartnerGroupByPartnerGroupID/${partnerGroupID}`,{
      method: "GET",
      headers: headers,
    });
    const data = await response.json();
    setLanguageID(data.languageID);
  }
  
    const handleSubmit = async (event) => {
        event.preventDefault();
        checkTokenExpiry();
        const authToken = Cookies.get('authToken');
        setLoading(true);
        const currentSelectedPartner = JSON.parse(Cookies.get("selectedPartnerGroup"));
        const partnerGroupID = currentSelectedPartner.partnerGroupID;
        const partnerGroupName = currentSelectedPartner.partnerGroupName;
        
        try {
            const plainPasswordResponse = await fetch(`${apiUrl}/User/GetPlainNewPassword`);
            if (!plainPasswordResponse.ok) {
              const failedRandomPass = t("toastmessages:failObtainRandomPass");
              toast.error(failedRandomPass);
              return;
            }
            const plainPassword = await plainPasswordResponse.text();
            // Encrypt the password asynchronously
            const encryptedPasswordPromise = fetch(`${apiUrl}/User/CreateNewPassword/${plainPassword}`)
              .then((response) => {
                if (!response.ok) {
                  const failedEncryptPass = t("toastmessages:failEncryptionPass");
                  toast.error(failedEncryptPass);
                  throw new Error("Failed to encrypt the password.");
                }
                return response.text();
              });
            // Wait for the encryptedPasswordPromise to resolve
            const encryptedPassword = await encryptedPasswordPromise;
            
            const requestBody = {
              partnerName: partnerName,
              partnerEmail: partnerEmail,
              partnerPassword: encryptedPassword,
              partnerAccEmail: partnerAccEmail,
              partnerTel: partnerTel,
              partnerStayTel: partnerStayTel,
              partnerGroupID: partnerGroupID,
              isSuper: false,
            };
            
          async function checkIfEmailIsInUse() {
            const authToken = Cookies.get('authToken');
            const headers = {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            };
            const request = await fetch(
              `${apiUrl}/Partner/GetPartnerUserByEmailAndPartnerGroupID/${partnerEmail}/${partnerGroupID}`,{
                method: "GET",
                headers: headers,
              }
            );
            const response = await request.json();
            return response.length > 0; 
          }
          const isEmailInUse = await checkIfEmailIsInUse();

          if (isEmailInUse) {
            const emailInUse = t("toastmessages:emailAlreadyUsed");
            toast.error(emailInUse);
          } else {
            const updateResponse = await fetch(
              `${apiUrl}/Partner/CreateNewPartnerUserInExistingPartnerGroup`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(requestBody),
              }
            );

            //Fetch Mail Template and Send Email
            const emailTemplate = await fetch(`${apiUrl}/Email/GetMailTemplateByMailTemplateID/7/${languageID}`).then(result => result.json());
                const emailRequestBody = {
                    Body: emailTemplate.body,
                    MailTemplateID: emailTemplate.id,
                    IsSent: false,
                    Subject: emailTemplate.subject,
                    SendTo: partnerEmail,
                    Params: "subject{},body{@user,@partnergroupname,@password}",
                    Values: `subject{},body{${partnerName}, ${partnerGroupName},${plainPassword}}`,
                                        }
            const sendEmailResponse = await fetch(
            `${apiUrl}/Email/SendMailAtOnce`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(emailRequestBody)
            }
        )
            //If both API calls return OK navigate back to partner
            if (updateResponse.ok && sendEmailResponse.ok) { 
              navigate("/partner");
              const createdNewUser = t("toastmessages:newUserCreated");
              toast.success(createdNewUser);
            } else {
              const changesNotSaved = t("toastmessages:errorSavingChanges");
              toast.error(changesNotSaved);
            }
          }
        } catch (error) {
          console.error("An error occurred while saving changes:", error);
          const errorOccured = t("toastmessages:errorTryAgain");
          toast.error(errorOccured);
        } finally {
          setLoading(false); 
        }
      };

    return(
        <div className="createpartneruser">
            <Helmet>
                <title>{t("partner:createUser")} | Disko Line {t("greenland")}</title>
            </Helmet>
         <h1>{t("partner:createUser")}</h1>
            <form onSubmit={handleSubmit}>
            {loading ? (
              <div className="under-creation">
                <p>{t("partner:userIsBeingCreated")}</p>
                <Spinner />
              </div>
        ) : (
                <><div className='flexed-form'>
                <label>
                  <div className='label-and-input'>
                    <p>{t("partner:name")}:<span className="asterix">*</span></p>
                    <input type="text" value={partnerName} required onChange={(e) => setPartnerName(e.target.value)} />
                  </div>
                </label>
                <label>
                  <div className='label-and-input'>
                    <p> Email:<span className="asterix">*</span> </p>
                    <input type="text" value={partnerEmail} required onChange={(e) => setPartnerEmail(e.target.value)} />
                  </div>
                </label>
                <label>
                  <div className='label-and-input'>
                    <p>{t("partner:secondary")} Email:</p>
                    <input type="text" value={partnerAccEmail} onChange={(e) => setPartnerAccEmail(e.target.value)} />
                  </div>
                </label>
                <label>
                  <div className='label-and-input'>
                    <p>{t("partner:telephone")}:<span className="asterix">*</span></p>
                    <PhoneInputWithCountrySelect  value={partnerTel} required onChange={(e) => setPartnerTel(e)} />
                  </div>
                </label>
                <label>
                  <div className='label-and-input'>
                    <p> {t("partner:stay")} {t("partner:telephone")}:</p>
                    <PhoneInputWithCountrySelect value={partnerStayTel} onChange={(e) => setPartnerStayTel(e)} />
                  </div>
                </label>
              </div>
              <div className="buttons">
                <button onClick={()=> navigate("/partner")} className="btn-secondary">{t("partner:backToMyAccount")}</button>
                <button type="submit" className='btn'>{t("partner:createUser")}</button>
            </div></>
        )}
            </form>
        </div>
    )
}