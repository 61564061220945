import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";


// Images
import IlulissatHeader from "../../images/area/Illulisat-header.jpg"
import Illulisat1 from "../../images/area/Illulisat1.jpg";
import Illulisat2 from "../../images/area/Illulisat2.jpg";
import Illulisat3 from "../../images/area/Illulisat3.jpg";
import Illulisatsektion2 from "../../images/area/Illulisat-sektion2.jpg";
import Illulisat1sektion3 from "../../images/area/Illulisat1sektion3.jpg";
import Illulisat2sektion3 from "../../images/area/Illulisat2sektion3.jpg";


export default function Ilulissat() {
    const { t } = useTranslation(["common", "areas"]);
    const headerStyle = {
      backgroundImage: `url(${IlulissatHeader})`,
      position: 'relative',
    };

    const overlayStyle = {
      content: "",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0.3)', 
    };
    useLayoutEffect(() => {
      // Scroll to the top on load
      window.scrollTo(0, 0);
    }, []);

return(
    <div className="area-pages">
        <Helmet>
          <title>Illulissat | Disko Line {t("greenland")}</title>
        </Helmet>
        <div className="header" style={headerStyle}>
        <div style={overlayStyle}></div>
        <div className="header-content">
          <div className="header-tag">
            <h1 className="bolder">{t('areas:Ilulissat')}</h1>
          </div>
        </div>
      </div>

      <div className="section1">
        <h2>{t('areas:section1Ilulissat')}</h2>
        <p>{t('areas:firstpSektion1Ilulissat')}</p>
        <p>{t('areas:secondpSektion1Ilulissat')}</p>
        <p>{t('areas:thirdpSektion1Ilulissat')}</p>
        <div className="img-container-sektion1">
        <img className="Illulisat1" src={Illulisat1} alt="Ilulissat" />
        <img src={Illulisat2} alt="Ilulissat" />
        <img src={Illulisat3} alt="Ilulissat" />
        </div>
      </div>

      <div className="section2">
        <h2>{t('areas:section2Ilulissat')}</h2>
        <p>{t('areas:firstpSektion2Ilulissat')}</p>
        <p>{t('areas:secondpSektion2Ilulissat')}</p>
        <p>{t('areas:thirdpSektion2Ilulissat')}</p>
        <p>{t('areas:fourthpSektion2Ilulissat')}</p>
        <div className="img-container-sektion2">
        <img src={Illulisatsektion2} alt="Ilulissat" />
        </div>
      </div>

      <div className="section3">
        <h2>{t('areas:section3Ilulissat')}</h2>
        <p>{t('areas:firstpSektion3Ilulissat')}</p>
        <p>{t('areas:secondpSektion3Ilulissat')}</p>
        <div className="img-container-sektion3">
        <img src={Illulisat1sektion3} alt="Ilulissat" />
        <img src={Illulisat2sektion3} alt="Ilulissat" />
        </div>
      </div>

      </div>
)
}