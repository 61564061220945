/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../components/AuthContext";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import config from '../../configs/config';

export default function PartnerLogin() {
    const {t} = useTranslation(["common", "partner", "toastmessages"]);
    const { setAuthenticated, setPartnerGroupName, setPartnerName } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [partnerGroups, setPartnerGroups] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const apiUrl = config.default.apiPath;




    useEffect(() => {
        if (partnerGroups.length > 1) {
          setIsModalOpen(true);
        }
      }, [partnerGroups]);
      const location = useLocation();
      useEffect(() => {
        // Check if 'login_with_token' exists in URL params
        const params = new URLSearchParams(location.search);
        const loginToken = params.get('login_by_token');
        const handleTokenLogin = async (token) => {
          try {
              // API call to perform token-based login
              const response = await fetch(`${apiUrl}/Authentication/PartnerLogin`, {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json"
                  },
                  body: JSON.stringify({ token }), // Send token in the request body
              });
    
              const data = await response.json();
              if (data.success) {
                setAuthenticated(true);
                Cookies.set('authToken', data.token, { sameSite: 'Lax', secure: true });
                Cookies.set("userEmail", data.partnerEmail);
                Cookies.set('refreshToken', data.refreshToken, { sameSite: 'Lax', secure: true });
                Cookies.set("selectedPartnerGroup", JSON.stringify(data.partnerGroupList[0]),{ sameSite: 'Lax', secure: true });
                // Update the centralized state and set the partner group name
                setPartnerGroupName(data.partnerGroupList[0].partnerGroupName);
                setPartnerName(data.partnerGroupList[0].partnerName);
                await recalculateCustomerCart();
                navigate("/partner");
                window.location.reload();
              } else {
                const tokenBasedFail = t("toastmessages:tokenBasedLoginFail");
                  toast(tokenBasedFail, data.errorMessage);
                  console.error("Token-based login failed:", data.errorMessage);
              }
          } catch (error) {
              console.error("An error occurred during token-based login:", error);
          }
      };
        if (loginToken) {
            // Perform token-based login if token exists
            handleTokenLogin(loginToken);
        }
    }, [location.search]);

   
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const recalculateCustomerCart = async () => {
        try {
          const sessionID = Cookies.get('sessionID');
          const selectedPartnerGroup = JSON.parse(Cookies.get('selectedPartnerGroup'));
          const partnerGroupID = selectedPartnerGroup.partnerGroupID;
          const authToken = Cookies.get('authToken');
          const response = await fetch(
            `${apiUrl}/Cart/RecalculateCustomerCart?sessionID=${sessionID}&partnerGroupID=${partnerGroupID}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
    
          if (response.ok) {
            console.log('Customer cart recalculated successfully.');
          } else {
            console.error('Failed to recalculate customer cart.');
          }
        } catch (error) {
          console.error('An error occurred while recalculating customer cart:', error);
        }
      };

    const handleLogin = async () => {
        try {
            const loginData = {
                email: email,
                password: password
            };
            const response = await fetch(`${apiUrl}/Authentication/PartnerLogin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(loginData),
            });
            const data = await response.json();
            if (data.success) {
                setAuthenticated(true);
                Cookies.set('authToken', data.token, { sameSite: 'Lax', secure: true });
                Cookies.set('tokenExpiry', data.tokenExpired,{ sameSite: 'Lax', secure: true });
                Cookies.set('refreshToken', data.refreshToken, { sameSite: 'Lax', secure: true });
                if (data.partnerGroupList.length > 1) {
                    // If there are multiple partner groups, show options to the user
                    setPartnerGroups(data.partnerGroupList);
                } else {
                    Cookies.set('authToken', data.token, { sameSite: 'Lax', secure: true });
                    Cookies.set("userEmail", data.partnerEmail);
                    Cookies.set('refreshToken', data.refreshToken, { sameSite: 'Lax', secure: true });
                    Cookies.set("selectedPartnerGroup", JSON.stringify(data.partnerGroupList[0]),{ sameSite: 'Lax', secure: true });
                    // Update the centralized state and set the partner group name
                    setPartnerGroupName(data.partnerGroupList[0].partnerGroupName);
                    setPartnerName(data.partnerGroupList[0].partnerName);
                    await recalculateCustomerCart();
                    navigate("/partner");
                    window.location.reload();
                }
            } else {
              const failLogin = t("toastmessages:loginFailed");
                toast(failLogin, data.errorMessage);
                console.error("Login failed:", data.errorMessage);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }
      const closeModal = () => {
        setIsModalOpen(false);
      };

      const fetchUser = async () => {
        const selectedPartnerGroup = JSON.parse(Cookies.get('selectedPartnerGroup'));
        const partnerGroupID = selectedPartnerGroup.partnerGroupID;
        const email = Cookies.get("userEmail");
        const authToken = Cookies.get('authToken');

        const response = await fetch(`${apiUrl}/Partner/GetPartnerUserByEmailAndPartnerGroupID/${email}/${partnerGroupID}`, {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${authToken}`,
          },
        });
      
        if (!response.ok) {
          throw new Error(`Error fetching partner user: ${response.statusText}`);
        }
      
        return await response.json();
      };

      const logUserActivity = async (partnerUserID) => {
            const authToken = Cookies.get('authToken');
            const newLogEntry = {
              PartnerUserID: partnerUserID,
              LogType: 1,
              LogDescription: "Logging for Event PartnerUser login",
              CreatedDate: new Date().toISOString(),
            }
            const response = await fetch(`${apiUrl}/Partner/AddPartnerUserLog`, {
              method: "POST",
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authToken}`,
              },
              body: JSON.stringify(newLogEntry),
            });
          
            if (!response.ok) {
              throw new Error(`Error logging partner user activity: ${response.statusText}`);
            }
          
            return await response.json();
          };

          const handleActivityLogging = async () => {

            try {
              console.log("Fetching user...");
              const userData = await fetchUser();
              console.log("User fetched:", userData);

              if (userData && userData[0].id) {
                console.log("Logging user activity...");
                const partnerUserID = userData[0].id;
                const logData = await logUserActivity(partnerUserID);
                console.log("Partner Log successfully updated", logData);
              } else {
                console.log("No partner user found to log activity");
              }
            } catch (error) {
              console.error("An error occurred:", error);
            }
          };

    const handlePartnerGroupSelect = async (selectedGroup) => {
        try {
            Cookies.set("selectedPartnerGroup", JSON.stringify(selectedGroup),{ sameSite: 'Lax', secure: true });
            Cookies.set("userEmail", email,{ sameSite: 'Lax', secure: true });
            // Update the authenticated state and navigate to the partner section
            setAuthenticated(true);
            setPartnerGroupName(selectedGroup.partnerGroupName);
            setPartnerName(selectedGroup.partnerName);
            closeModal();
            await handleActivityLogging();
            await recalculateCustomerCart();
            navigate("/partner");
            window.location.reload();
        } catch (error) {
            console.error("An error occurred while selecting partner group:", error);
        } 
      };

    //Press Enter to be able to Login
    const handleKeypress = e => {
        if (e.keyCode === 13) {
            handleLogin();}};
    
    return (
        <div className={`partner-log-in ${isModalOpen ? 'modal-open' : ''}`}>
            <Helmet>
                <title>{t("login")} | Disko Line {t("greenland")}</title>
            </Helmet>
            <h1>Partner Login</h1>
            <div className="log-in-form">
                <div className="label-and-input">
                    <label>Email:</label>
                    <input type="email" value={email}  onChange={handleEmailChange} placeholder={t('partner:enterEmail')}/>
                </div>
                <div className="label-and-input">
                    <label>{t("partner:password")}:</label>
                    <input type="password" value={password} onKeyDown={handleKeypress} onChange={handlePasswordChange} placeholder={t("partner:enterPassword")} />
                </div>
            </div>
            <div className="button-center">
                <button onClick={handleLogin} className="btn login">{t("partner:login")}</button>
            </div>
            {/* Render partner group selection if applicable */}
            {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>{t("partner:selectPartnerGroup")}</h2>
            <ul>
              {partnerGroups.map((group) => (
                <li key={group.partnerGroupID}>
                  <button onClick={() => handlePartnerGroupSelect(group)}>
                    {group.partnerGroupName}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
        </div>
    );
}
