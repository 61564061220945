import React, { createContext, useContext, useState } from 'react';

const CookieContext = createContext();

export function CookieProvider ({ children }) {
    const [showSettings, setShowSettings] = useState(
        false
      );


  return (
    <CookieContext.Provider value={{ showSettings, setShowSettings}}>
      {children}
    </CookieContext.Provider>
  );
};

export function useCookieContext() {
    return useContext(CookieContext);
  }