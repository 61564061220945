let config = {};

if (process.env.REACT_APP_ENVIRONMENT.trim() === 'development') {
  const devConfig = require('./config.development');
  config = { ...config, ...devConfig };
} else if (process.env.REACT_APP_ENVIRONMENT.trim() === 'staging') {
  const stagingConfig = require('./config.staging');
  config = { ...config, ...stagingConfig };
} else if(process.env.REACT_APP_ENVIRONMENT.trim() === 'production') {
  const prodConfig = require('./config.production');
  config = { ...config, ...prodConfig };
}

export default config;