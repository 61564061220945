import { Helmet } from "react-helmet";

export default function TrafficInfo(){

    return(
        <div className="trafficinfopage">
             <Helmet>
                <title>Trafik Info  | Disko Line Greenland</title>
            </Helmet>
            <h1>Trafik Info</h1>
        </div>
    )
}