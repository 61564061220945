import LoadingGif from '../images/loading-boat.gif';

const Spinner = ({ className, size, width }) => {
  return (
    <div className={`spinner-container ${className || ''}`}>
      {
        size === "small" ? (
          <div className="spinner-sm"></div>
        ) : (
          <div className="spinner" style={width ? {width: width}: {}}>
            <img src={LoadingGif} alt="Loading..." />
          </div>
        )
      }
    </div>
  );
};

export default Spinner;
