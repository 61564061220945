import baseService from './BaseService'

let updateOrderLanguage = async function updateOrderLanguage (orderID, orderLanguage) {

    const apiUrl = baseService.getApiUrl();
    const headers = baseService.getAuthenHeader();
    const partnerGroupID = baseService.getPartnerGroupID();

    const requestBody = {
        orderID: orderID, 
        LanguageID: orderLanguage,
        partnerGroupID: partnerGroupID,
    };
    const response = await fetch(`${apiUrl}/Partner/UpdateOrderLanguage`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
        console.log('api error', `Failed to update order language: ${response.statusText}`);
        return null;
    }
    return response.json();
};

var partnerService = { updateOrderLanguage }
export default partnerService;