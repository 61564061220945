import React from 'react';
import logService from '../services/LogService'
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError() {
      return { hasError: true };
    }
  
    async componentDidCatch(error, info) {
      this.setState({ hasError: true });
         await logService.saveLog(error);
         window.location.href = '/error';
    }
    render() {
      if (this.state.hasError) {
        return null;
      }
  
      return this.props.children;
    }
  }
  
  export default ErrorBoundary;