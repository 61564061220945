import { Helmet } from "react-helmet";
import headerImage from "../../../images/headers/Targa.jpg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DayTripEqi from "../../../images/dle-tours/DaytripEqi.jpg";
import WhaleSafari from "../../../images/dle-tours/WhaleSafari.jpg";
import FishingTrip from "../../../images/dle-tours/FishingTrip.jpg";
import IcefiordCruise from "../../../images/dle-tours/IcefiordCruise.jpg";
import NorthernLights from "../../../images/dle-tours/NorthernLights.jpg";
 
export default function SailingTrips(){
    const {t} = useTranslation(["common", "diskolineexplorer"]);
    const headerStyle = {
      backgroundImage: `url(${headerImage})`
  }
  const navigate = useNavigate();
  function navigateToActivity(activityName){
    navigate(`/diskolineexplorer/${activityName}`);
  }
  return (
    <div className="diskoline-explorer-page">
      <Helmet>
        <title>{t('sailingTrips')} | {t('diskoLineExplorer')}</title>
      </Helmet>
      <div className="header" style={headerStyle}>
                <div className="header-content">
                    <p className="tagline">Disko Line</p>
                    <h1>{t('sailingTrips')}</h1>
                </div>
      </div>
      <div className="area-container">
      <div className="area-card">
            <div className="area-img">
              <img src={DayTripEqi} alt="Day Trip to Eqi" />
               <h2 className="area-name">{t('dayTripToEqipSermia')}</h2>
              <div className="area-img-overlay" onClick={() => navigateToActivity("daytrip-to-eqip-sermia")}>
                <button onClick={() => navigateToActivity("daytrip-to-eqip-sermia")}>{t("readMore")}</button>
              </div>
            </div>
          </div>
          <div className="area-card">
            <div className="area-img">
              <img src={IcefiordCruise} alt="Icefiord Cruise" />
               <h2 className="area-name">{t('icefiordCruise')}</h2>
              <div className="area-img-overlay" onClick={() => navigateToActivity("icefiord-cruise")}>
                <button onClick={() => navigateToActivity("icefiord-cruise")}>{t("readMore")}</button>
              </div>
            </div>
          </div>
      <div className="area-card">
            <div className="area-img">
              <img src={WhaleSafari} alt="Whale Safari" />
               <h2 className="area-name">{t('whaleSafari')}</h2>
              <div className="area-img-overlay" onClick={() => navigateToActivity("whale-safari")}>
                <button onClick={() => navigateToActivity("whale-safari")}>{t("readMore")}</button>
              </div>
            </div>
          </div>
          <div className="area-card margin-left">
            <div className="area-img">
              <img src={FishingTrip} alt="Fishing Trip" />
               <h2 className="area-name">{t('fishingTrip')}</h2>
              <div className="area-img-overlay" onClick={() => navigateToActivity("fishing-trip")}>
                <button onClick={() => navigateToActivity("fishing-trip")}>{t("readMore")}</button>
              </div>
            </div>
          </div>
          <div className="area-card margin-right">
            <div className="area-img">
              <img src={NorthernLights} alt="Northern Lights" />
               <h2 className="area-name">{t('northernLights')}</h2>
              <div className="area-img-overlay" onClick={() => navigateToActivity("northern-lights")}>
                <button onClick={() => navigateToActivity("northern-lights")}>{t("readMore")}</button>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}