import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap, faArrowUp, faArrowDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from '../configs/config';
import { useAuth } from "./AuthContext";
import Cookies from "js-cookie";
import Spinner from "./Spinner";
import { useForm } from "./FormContext";
import { useSearchData } from "./SearchContext";
import { useLocation } from 'react-router-dom';
import enums from '../commons/Enums'


export default function SearchTicketRoute({ areaName, onSearch }) {
  const params = useParams();
  const { t, i18n } = useTranslation(["common", "buyticket"]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 810);
  const areaSlug = params.id;
  const [stops, setStops] = useState([]);
  const [selectedDepartureStop, setSelectedDepartureStop] = useState(null);
  const [selectedArrivalStop, setSelectedArrivalStop] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [globalSetup, setGlobalSetup] = useState([]);
  const [area, setArea] = useState([]);
  const { setSearchData } = useSearchData();
  const [isAreaImageModalOpen, setIsAreaImageModalOpen] = useState(false);
  const resultsSectionRef = useRef();
  const [loading, setLoading] = useState(false);
  const apiUrl = config.default.apiPath;
  const { languagesToIndex, languagesToIndexCalendar } = enums;
  const [translatedTicketTypes, setTranslatedTicketTypes] = useState([
    { ticketTypeID: 1, quantity: "0" },
    { ticketTypeID: 2, quantity: "0" },
    { ticketTypeID: 3, quantity: "0" },
    { ticketTypeID: 4, quantity: "0" },
  ]);
  async function fetchTicketTypesFromAPI() {
    const response = await fetch(`${apiUrl}/Cart/GetTicketTypes`);
    const data = await response.json();
    return data;
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    fetch(`${apiUrl}/Area/GetArea/${areaSlug}`)
      .then((response) => response.json())
      .then((data) => {
        setArea(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetch(`${apiUrl}/System/GetGlobalSetup`)
      .then((response) => response.json())
      .then((data) => {
        setGlobalSetup(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [apiUrl])

  // Get authentication status and selected partner group
  const { authenticated, checkTokenExpiry } = useAuth();
  checkTokenExpiry();
  var selectedPartnerGroupStr = Cookies.get('selectedPartnerGroup')
  const selectedPartnerGroup = authenticated && selectedPartnerGroupStr
  ? JSON.parse(selectedPartnerGroupStr) : null;
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 810);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const openAreaImageModal = () => {
    setIsAreaImageModalOpen(true);
  };
  const closeAreaImageModal = () => {
    setIsAreaImageModalOpen(false);
  };
  const handleModalClick = (e) => {
    if (e.target.classList.contains("modal-container")) {
      closeAreaImageModal();
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      closeAreaImageModal();
    }
  };

  const swapStops = (event) => {
    event.preventDefault(); // Prevent form submission

    // Swap selected departure and arrival stops
    const tempSelectedDepartureStop = selectedDepartureStop;
    setSelectedDepartureStop(selectedArrivalStop);
    setSelectedArrivalStop(tempSelectedDepartureStop);

    // Swap departure and arrival stops in formRequest
    setFormRequest((prevData) => ({
      ...prevData,
      departureStopID: formRequest.arrivalStopID,
      arrivalStopID: formRequest.departureStopID,
    }));
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // State variables for validation errors
  const [validationErrors, setValidationErrors] = useState({
    departure: "",
    arrival: "",
    date: "",
    ticketTypes: "",
  });
  const validateForm = () => {
    let isValid = true;
    const errors = {
      departure: "",
      arrival: "",
      date: "",
      ticketTypes: "",
    };
    //ERROR MESSAGES
    const errorDeparture = t("buyticket:error.departureMissing");
    const errorArrival = t("buyticket:error.arrivalMissing");
    const errorDate = t("buyticket:error.dateMissing");
    const errorTicketTypes = t("buyticket:error.ticketTypesMissing");
    //VALIDATION FOR INPUT
    if (formRequest.departureStopID === "0" || !formRequest.departureStopID) {
      errors.departure = errorDeparture
      isValid = false;
    }
    if (formRequest.arrivalStopID === "0" || !formRequest.arrivalStopID) {
      errors.arrival = errorArrival
      isValid = false;
    }
    if (!selectedDate) {
      errors.date = errorDate
      isValid = false;
    }
    const hasSelectedTicketType = formRequest.numberTicketTypes.some(
      (ticketType) => parseInt(ticketType.quantity) > 0
    );
    if (!hasSelectedTicketType) {
      errors.ticketTypes = errorTicketTypes;
      isValid = false;
    }
    setValidationErrors(errors);
    return isValid;
  };

  const areaImgSrc = globalSetup.hostName + area.imgUrl;
  useEffect(() => {
    // Fetch ticket types from your API
    fetchTicketTypesFromAPI()
      .then((ticketTypes) => {
        // Translate ticket types based on the current language
        const translatedTypes = ticketTypes.map((ticketType) => ({
          ticketTypeID: ticketType.id,
          ticketTypeName: translateTicketType(ticketType.ticketTypeTranslations, i18n.language),
        }));
        setTranslatedTicketTypes(translatedTypes);
      })
      .catch((error) => {
        console.error('Error fetching ticket types:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const translateTicketType = (ticketTypeTranslations, currentLanguage) => {
    const languageIdMapping = {
      dk: 1,
      en: 2,
      gl: 3,
    };
    // Find the translation that matches the current language
    const translation = ticketTypeTranslations.find(
      (translation) => translation.languageID === languageIdMapping[currentLanguage]
    );

    // If a translation is found, return its ticketTypeName, otherwise, use the default translation
    return translation ? translation.ticketTypeName : ticketTypeTranslations[0].ticketTypeName;
  };
  useEffect(() => {
    let searchEncode = queryParams.get("search");
    if (searchEncode != null) {
      var searchModel = JSON.parse(atob(searchEncode));
      var ticketTypes = JSON.parse(searchModel.ticketTypes);
      const translatedTypes = translatedTicketTypes.map((ticketType) => ({
        ticketTypeID: ticketType.ticketTypeID,
        ticketTypeName: ticketType.ticketTypeName,
        quantity: ticketTypes.find(n => n.ticketTypeID === ticketType.ticketTypeID)?.quantity ?? 0,
      }));
      setTranslatedTicketTypes(translatedTypes);

      setFormRequest((prevData) => ({
        ...prevData,
        departureStopID: searchModel.departureStopID,
        arrivalStopID: searchModel.arrivalStopID,
        numberTicketTypes: translatedTypes,
      }));
    }
    else {
      setFormRequest((prevData) => ({
        ...prevData,
        numberTicketTypes: translatedTicketTypes,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  const { formRequest, setFormRequest } = useForm();

  useEffect(() => {
    async function getStops() {
      const headers = {
        'Content-Type': 'application/json',
        'LanguageID': languagesToIndex[i18n.language]
      };

      let ticketApiUrl = `${apiUrl}/Stop/GetTicketableStops/${areaSlug}`;

      if (authenticated) {
        checkTokenExpiry();
        const selectedPartnerGroup = JSON.parse(Cookies.get('selectedPartnerGroup'));
        const partnerGroupID = selectedPartnerGroup.partnerGroupID;
        const authToken = Cookies.get('authToken');
        // Add authentication header
        headers['Authorization'] = `Bearer ${authToken}`;
        // Assuming partnerGroupID is a value accessible in the scope
        const queryParams = `?partnerGroupID=${partnerGroupID}`;
        ticketApiUrl = `${ticketApiUrl}${queryParams}`;
      }

      const response = await fetch(ticketApiUrl, {
        method: 'GET',
        headers: headers
      });

      const data = await response.json();
      setStops(data);
    }

    if (areaSlug) {
      getStops();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaSlug, apiUrl, authenticated, checkTokenExpiry, languagesToIndex[i18n.language]]);
  const handleChange = (event, ticketTypeID, index) => {
    const { name, value } = event.target;
    if (name === "departureStopID") {
      setSelectedDepartureStop(parseInt(value));
      setFormRequest((prevData) => ({
        ...prevData,
        departureStopID: value,
      }));
    } else if (name === "arrivalStopID") {
      setSelectedArrivalStop(parseInt(value));
      setFormRequest((prevData) => ({
        ...prevData,
        arrivalStopID: value,
      }));
    } else if (name === "quantity") {
      const updatedTicketTypes = formRequest.numberTicketTypes.map((ticketType, idx) =>
        idx === index ? { ...ticketType, quantity: value } : ticketType
      );

      setFormRequest((prevData) => ({
        ...prevData,
        numberTicketTypes: updatedTicketTypes,
      }));

      const updatedLocalStorage = formRequest.numberTicketTypes.map((ticketType) => ({
        ticketTypeID: ticketType.ticketTypeID,
        quantity: ticketType.quantity,
      }));
      localStorage.setItem("numberTicketTypes", JSON.stringify(updatedLocalStorage));
    }
  };
  const handleChangeDate = (date) => {
    setSelectedDate(date);
    if (date) {
      const formattedDate = date.toLocaleDateString("en-GB"); // Format as dd/mm/yyyy
      setFormRequest((prevData) => ({
        ...prevData,
        departureDate: formattedDate,
      }));
    }
  };
  async function handleSubmit(event) {
    event.preventDefault();
    const formattedDate = selectedDate
      ? selectedDate.toLocaleDateString("en-GB").split('/').reverse().join('-')
      : "";
    const apiURL = `${apiUrl}/Cart/SearchRouteJourney`;

    const requestBody = {
      departureStopID: formRequest.departureStopID,
      arrivalStopID: formRequest.arrivalStopID,
      departureDate: formattedDate,
      numberTicketTypes: formRequest.numberTicketTypes,
      page: 0,
      isTransfer: formRequest.isTransfer,
      isSearchEarlier: formRequest.isSearchEarlier,
      isSearchActivity: formRequest.isSearchActivity,
      ...(authenticated && selectedPartnerGroup && { partnerGroupID: selectedPartnerGroup.partnerGroupID }),
    };
    console.log("Requestbody SearchRouteJourney", requestBody);
    localStorage.setItem("numberTicketTypes", JSON.stringify(requestBody.numberTicketTypes))

    if (validateForm()) {
      // Set loading to true after 1 second
      const loadingTimeout = setTimeout(() => {
        setLoading(true);
      }, 1000);
      try {
        const response = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "LanguageID": languagesToIndex[i18n.language],
            ...(authenticated && { Authorization: `Bearer ${Cookies.get('authToken')}` }),
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          const errorData = await response.json();
          return Promise.reject(errorData);
        }

        const data = await response.json();
        clearTimeout(loadingTimeout); // Clear the timeout if the response is received before 1 second
        setLoading(false);
        setSearchData(data);
        onSearch(data);
        resultsSectionRef.current.scrollIntoView({ behavior: "smooth" });
      } catch (error) {
        console.error('Error fetching data:', error);
        clearTimeout(loadingTimeout); // Clear the timeout if there's an error
        setLoading(false);
      }
    }
  }


  // Options that will be displayed in the dropdown menu
  const zeroToTwelve = [...Array(13).keys()].map((i) => <option key={i}>{i}</option>);
  let today = new Date();

  // Define a state to keep track of whether the label is being edited or not
  const [isEditing, setIsEditing] = useState(false);

  // Function to handle double click event on the label
  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const [showPopup, setShowPopup] = useState(false);


  return (
    <div className="search-form route-form">
      {loading && <Spinner className="search-spinner" />}
      {isAreaImageModalOpen && (
        <div className="modal-container" onClick={handleModalClick}>
          <div className="modal-content">
            <span className="close-button" onClick={closeAreaImageModal}>
              &times;
            </span>
            <img src={areaImgSrc} alt="Area" className="area-img-full" />
          </div>
        </div>
      )}
      <form className="form-step-1" onSubmit={handleSubmit}>
        <div className="title-and-map-button">
          <h1>{areaName}</h1>
          {isMobile ? (
            <p className="map-button" onClick={openAreaImageModal}><FontAwesomeIcon icon={faMap} /><span>{t("buyticket:seeMap")}</span></p>
          ) : (null
          )}

        </div>
        <div className="search-form-content">
          {isMobile ? (
            null) : (
            <div className="img-container">
              <img src={areaImgSrc} alt="Area" className="area-img-small" onClick={openAreaImageModal} />
            </div>)}

          <div className="departure-arrival-date">
            <div className="departure flex-column">
              <label htmlFor="departure">{t("buyticket:from")}:</label>
              <select
                name="departureStopID"
                id="departure"
                value={formRequest.departureStopID}
                onChange={handleChange}
                className={validationErrors.departure ? 'input-error' : ''}
                data-error-message={validationErrors.departure}
              >
                {formRequest.departureStopID === 0 && (
                  <option value="0">{t("buyticket:selectDestination")}</option>
                )}
                {stops.map((stop) => (
                  <option
                    key={stop.id}
                    value={stop.id}
                    disabled={stop.id === selectedArrivalStop}
                  >
                    {stop.stopName} - <span className="stop-code">{stop.stopCode}</span>
                  </option>
                ))}
              </select>

          {validationErrors.departure && (
        <div className="tooltip">{validationErrors.departure}</div>
      )}
          </div>
          {(formRequest.departureStopID !== 0 && formRequest.arrivalStopID !== 0) && (
      <div className="swap-button-container">
        <button className="swap-button" onClick={swapStops}>
        <FontAwesomeIcon icon={faArrowUp}/><FontAwesomeIcon icon={faArrowDown}/>
        </button>
      </div>
    )}
          <div className="arrival flex-column">
          <label htmlFor="arrival">{t("buyticket:to")}:</label>
          <select
  name="arrivalStopID"
  id="arrival"
  value={formRequest.arrivalStopID}
  onChange={handleChange}
  className={validationErrors.arrival ? 'input-error' : ''}
  data-error-message={validationErrors.arrival}
>
  {formRequest.arrivalStopID === 0 && (
    <option value="0">{t("buyticket:selectDestination")}</option>
  )}
  {stops.map((stop) => (
    <option
      key={stop.id}
      value={stop.id}
      disabled={stop.id === selectedDepartureStop}
    >
      {stop.stopName} - <span className="stop-code">{stop.stopCode}</span>
    </option>
  ))}
</select>
        
               {validationErrors.arrival && (
        <div className="tooltip">{validationErrors.arrival}</div>
      )}
          </div>
        <br></br>
        <div className="flex-column">
          <label htmlFor="date">{t("buyticket:selectDate")}: </label>
          <DatePicker
              id="date"
              name="departureDate"
              autoComplete="off"
              selected={selectedDate}
              onChange={handleChangeDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/mm/yyyy"
              locale={languagesToIndexCalendar[i18n.language]}
              calendarStartDay={1}
              minDate={today}
              className={validationErrors.date ? 'input-error' : ''}
              data-error-message={validationErrors.date}
            />
              {validationErrors.date && (
                <div className="tooltip">{validationErrors.date}</div>
              )}
            </div>
          </div>
          <div className="participants">
            {translatedTicketTypes.map((ticketType, index) => (
              <div key={ticketType.ticketTypeID} className="participant">
                <label onDoubleClick={handleDoubleClick}>
                  {isEditing ? (
                    <>
                      {ticketType.ticketTypeName}
                      <br />
                      <input
                        className="ticket-quantity-input"
                        data-index={index}
                        name="quantity"
                        type="number"
                        value={ticketType.quantity}
                        onChange={(event) =>
                          handleChange(event, ticketType.ticketTypeID, index)
                        }
                      />
                    </>
                  ) : (
                    <>
                      {ticketType.ticketTypeName}
                      <br />
                      <select
                        data-index={index}
                        name="quantity"
                        value={ticketType.quantity}
                        onChange={(event) =>
                          handleChange(event, ticketType.ticketTypeID, index)
                        }
                      >
                        {zeroToTwelve}
                      </select>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="info-icon"
                        onClick={() => setShowPopup(true)}
                      />

                      {showPopup && (
                        <div className="popup">
                          <div className="popup-content">
                            <p>{t("common:enterQuantity")}</p>
                            <span className="close" onClick={() => setShowPopup(false)}>X</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </label>
              </div>
            ))}
            {validationErrors.ticketTypes && (
              <div className="tooltip">{validationErrors.ticketTypes}</div>
            )}
          </div>
        </div>
        <button className="search-button" type="submit">{t("buyticket:searchJourney")}</button>


      </form>
    </div>
  );
}
