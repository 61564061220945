import Cookies from 'js-cookie';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation(["common", "buyticket"]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    Cookies.set('selectedLanguage', language, { sameSite: 'Lax' });
    setIsOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const languages = [
    { code: 'dk', name: 'DA', flag: <img className='flagIcons' src="/denmark-flag.png" alt="Denmark Flag" /> },
    { code: 'en', name: 'EN', flag: <img className='flagIcons' src="/gb-flag.png" alt="GB Flag" /> },
    { code: 'gl', name: 'KA', flag: <img className='flagIcons' src="/greenland-flag.png" alt="Greenland Flag" /> }
  ];

  return (
    <div className={`language-selector ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
      <button className="btn" onClick={toggleDropdown}>
        {languages.find(lang => lang.code === i18n.language)?.flag}{' '}
        {languages.find(lang => lang.code === i18n.language)?.name}{' '}
        <span className='mobile-arrow'>
          {isOpen ? '▲' : '▼'}
        </span>
      </button>
      <ul className="language-dropdown">
        {languages.map((lang) => (
          <li key={lang.code} onClick={() => changeLanguage(lang.code)}>
            {lang.flag} {lang.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LanguageSelector;