import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";


// Images
import diskoBayHeader from "../../images/area/diskobugtenHeader.jpg"
import diskoBayfirstSection from "../../images/area/diskobugten-firstSection.png"
import diskoBayCardOne from "../../images/area/diskobugtenCardOne.jpg"
import diskoBayCardTwo from "../../images/area/diskobugtenCardTwo.jpg"
import diskoBayCardThree from "../../images/area/diskobugtenCardThree.jpg"
import diskoBayCardFour from "../../images/area/diskobugtenCardFour.jpg"
import diskoBayCardFive from "../../images/area/diskobugtenCardFive.jpg"
import diskoBayThirdSection from "../../images/area/diskobugtenThirdSection.jpg"
import GreenlandByTopas from "../../images/area/GreenlandByTopas.png"
import { useNavigate } from "react-router-dom";

export default function DiskoBay() {
    const { t } = useTranslation(["common", "areas"]);
    const headerStyle = {
      backgroundImage: `url(${diskoBayHeader})`,
      position: 'relative',
    };

    const overlayStyle = {
      content: "",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0.3)', 
    };
    useLayoutEffect(() => {
      // Scroll to the top on load
      window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    useLayoutEffect(() => {
        // Scroll to the top on load
        window.scrollTo(0, 0); 
      }, []);
      function handleGoToIlulissat(){
        navigate("/diskolineexplorer/ilulissat");
      }
      function handleGoToIlimanaq(){
        navigate("/diskolineexplorer/ilimanaq")
      }
      function handleGoToEquipSermia(){
        navigate("/diskolineexplorer/equipSermia")
      }
      function handleGoToQeqertarsuaq(){
        navigate("/diskolineexplorer/qeqertarsuaq")
      }
      function handleGoToQasigiannguit(){
        navigate("/diskolineexplorer/qasigiannguit")
      }

return(
    <div className="diskoBay">
        <Helmet>
            <title>{t('areas:diskoBay')} | Disko Line </title>
        </Helmet>
        <div className="header" style={headerStyle}>
        <div style={overlayStyle}></div>
        <div className="header-content">
          <div className="header-tag">
            <h1 className="bolder">{t('areas:theArea')}<br />{t('areas:diskoBay')}</h1>
            <h3 className="subHeader">{t('areas:subHeadDiskoBay')}</h3>
          </div>
        </div>
      </div>

      <div className="section-1">
        <div className="section-text">
          <img src={diskoBayfirstSection} alt="Diskobugten" />
          <div className="firstSectionDiskoBay">
            <h1>{t('areas:diskoBay')}</h1>
            <p>{t('areas:diskoBay1')}</p>
            <p>{t('areas:diskoBay2')}</p>
            <p>{t('areas:diskoBay3')}</p>
          </div>
        </div>
        </div>

      <div className="section-2">
        <h1>{t('areas:5Places')}</h1>
        <div className="cardInclPhoto">
          <div className="diskoBayCard">
            <div className="cardOneHeader">
              <h1>{t('areas:Ilulissat')}</h1>
              <h3>{t('areas:worldHeritageSite')}</h3>
            </div>
            <div className="cardText">
              <p>{t('areas:Ilulissat1')}</p>
              <p>{t('areas:Ilulissat2')}</p>
              <button className="btn" onClick={handleGoToIlulissat}>{t('areas:IlulissatBtn')} </button>
            </div>
          </div>
          <img src={diskoBayCardOne} alt="Ilulissat" />
        </div>
      </div>

      <div className="section-2">
        <div className="cardInclPhoto">
        <img src={diskoBayCardTwo} alt="Ilimanaq" />
          <div className="diskoBayCard">
            <div className="cardOneHeader">
              <h1>{t('areas:Ilimanaq')}</h1>
              <h3>{t('areas:villageVisit')}</h3>
              </div>
              <div className="cardText">
              <p>{t('areas:Ilimanaq1')}</p>
              <p>{t('areas:Ilimanaq2')}</p>
              <button className="btn" onClick={handleGoToIlimanaq}>{t('areas:IlimanaqBtn')} </button>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2">
        <div className="cardInclPhoto">
          <div className="diskoBayCard">
            <div className="cardOneHeader">
              <h1>{t('areas:EqipSermia')}</h1>
              <h3>{t('areas:EqiGlacier')}</h3>
            </div>
            <div className="cardText">
              <p>{t('areas:EqipSermia1')}</p>
              <p>{t('areas:EqipSermia2')}</p>
              <button className="btn" onClick={handleGoToEquipSermia}>{t('areas:EqipSermiaBtn')} </button>
            </div>
          </div>
          <img src={diskoBayCardThree} alt="Eqip Sermia" />
        </div>
      </div>

      <div className="section-2">
        <div className="cardInclPhoto">
        <img src={diskoBayCardFour} alt="Qeqertarsuaq" />
          <div className="diskoBayCard">
            <div className="cardOneHeader">
              <h1>{t('areas:Qeqertarsuaq')}</h1>
              <h3>{t('areas:diskoIsland')}</h3>
            </div>
            <div className="cardText">
              <p>{t('areas:Qeqertarsuaq1')}</p>
              <p>{t('areas:Qeqertarsuaq2')}</p>
              <button className="btn" onClick={handleGoToQeqertarsuaq}>{t('areas:QeqertarsuaqBtn')} </button>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2">
        <div className="cardInclPhoto">
          <div className="diskoBayCard">
            <div className="cardOneHeader">
              <h1>{t('areas:Qasigiannguit')}</h1>
              <h3>{t('areas:southeastDiskoBay')}</h3>
            </div>
            <div className="cardText">
              <p>{t('areas:Qasigiannguit1')}</p>
              <p>{t('areas:Qasigiannguit2')}</p>
              <button className="btn" onClick={handleGoToQasigiannguit}>{t('areas:QasigiannguitBtn')} </button>
            </div>
          </div>
          <img src={diskoBayCardFive} alt="Qasigiannguit" />
        </div>
      </div>

      <div className="section-3">
        <img src={diskoBayThirdSection} alt="Diskobugten" />
        <div style={overlayStyle}></div>
        <div className="section-3-text">
          <img src={GreenlandByTopas} alt="Greenland by Topas logo" />
          <h1>{t('areas:section3h1')}</h1>
          <h3>{t('areas:section3h3')}</h3>
        </div>
      </div>
    </div>
);
}
