import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";


// Images
import QasigiannguitHeader from "../../images/area/QasigiannguitHeader.jpg"
import Qasigiannguit1 from "../../images/area/Qasigiannguit1.jpg";
import Qasigiannguit2 from "../../images/area/Qasigiannguit2.jpg";
import Qasigiannguit3 from "../../images/area/Qasigiannguit3.jpg";
import Qasigiannguitsektion2 from "../../images/area/Qasigiannguitsektion2.jpg";
import Qasigiannguit1sektion3 from "../../images/area/Qasigiannguit1sektion3.jpg";
import Qasigiannguit2sektion3 from "../../images/area/Qasigiannguit2sektion3.jpg";


export default function Qasigiannguit() {
    const { t } = useTranslation(["common", "areas"]);
    const headerStyle = {
      backgroundImage: `url(${QasigiannguitHeader})`,
      position: 'relative',
    };

    const overlayStyle = {
      content: "",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0.3)', 
    };
    useLayoutEffect(() => {
      // Scroll to the top on load
      window.scrollTo(0, 0);
    }, []);

return(
    <div className="area-pages">
        <Helmet>
          <title>Qasigiannguit | Disko Line {t("greenland")}</title>
        </Helmet>
        <div className="header" style={headerStyle}>
        <div style={overlayStyle}></div>
        <div className="header-content">
          <div className="header-tag">
            <h1 className="bolder special-header">{t('areas:Qasigiannguit')}</h1>
          </div>
        </div>
      </div>

      <div className="section1">
        <h2>{t('areas:section1Qasigiannguit')}</h2>
        <p>{t('areas:firstpSektion1Qasigiannguit')}</p>
        <p>{t('areas:secondpSektion1Qasigiannguit')}</p>
        <p>{t('areas:thirdpSektion1Qasigiannguit')}</p>
        <p>{t('areas:fourthpSektion1Qasigiannguit')}</p>
        <div className="img-container-sektion1">
        <img className="Qasigiannguit1" src={Qasigiannguit1} alt="Qasigiannguit" />
        <img src={Qasigiannguit2} alt="Qasigiannguit" />
        <img src={Qasigiannguit3} alt="Qasigiannguit" />
        </div>
      </div>

      <div className="section2">
        <h2>{t('areas:section2Qasigiannguit')}</h2>
        <p>{t('areas:firstpSektion2Qasigiannguit')}</p>
        <p>{t('areas:secondpSektion2Qasigiannguit')}</p>
        <p>{t('areas:thirdpSektion2Qasigiannguit')}</p>
        <p>{t('areas:fourthpSektion2Qasigiannguit')}</p>
        <div className="img-container-sektion2">
        <img src={Qasigiannguitsektion2} alt="Qasigiannguit" />
        </div>
      </div>

      <div className="section3">
        <h2>{t('areas:section3Qasigiannguit')}</h2>
        <p>{t('areas:firstpSektion3Qasigiannguit')}</p>
        <p>{t('areas:secondpSektion3Qasigiannguit')}</p>
        <p>{t('areas:thirdpSektion3Qasigiannguit')}</p>
        <div className="img-container-sektion3">
        <img src={Qasigiannguit1sektion3} alt="Qasigiannguit" />
        <img src={Qasigiannguit2sektion3} alt="Qasigiannguit" />
        </div>
      </div>

      </div>
)
}