import { Helmet } from "react-helmet";
import headerImage from "../../images/headers/jobs-header.jpg";
import { useTranslation } from "react-i18next";
export default function AvailableJobs(){
    const {t} = useTranslation(["common", "about"])
    const headerStyle = {
        backgroundImage: `url(${headerImage})`
    }
    return(
        <div className="availablejobspage" >
            <Helmet>
                <title>{t('availablePositions')}| Disko Line Greenland</title>
            </Helmet>
            <div className="header" style={headerStyle}>
                <div className="header-content">
                    <p>Disko Line</p>
                    <h1>{t('availablePositions')}</h1>
                </div>
            </div>
            <div className="content">
            <div className="availablejob">
                <div className="translation">
                <img src="/greenland-flag.png" alt="Greenlandic" /><a href="https://topasexplorergroup.com/job-hos-topas/?hr=show-job%2F193054%26locale%3Dkl_GL" target="_blank" rel="noreferrer">Aasaanerani 2024-imi angallassinermut ataqatigiissaarisussamik suleqatissarsiorpugut</a>
                </div>
                <div className="translation">
                <img src="/denmark-flag.png" alt="Danish" /><a href="https://topasexplorergroup.com/job-hos-topas/?hr=show-job%2F192866%26locale%3Dda_DK" target="_blank" rel="noreferrer">Trafikkoordinator til Disko Line - sommeren 2024</a>
                </div>
               </div>
               <div className="availablejob">
                <div className="translation">
                <img src="/greenland-flag.png" alt="Greenlandic" /><a href="https://topasexplorergroup.com/job-hos-topas/?hr=show-job%2F192446%26locale%3Dkl_GL" target="_blank" rel="noreferrer">Disko Line Ilulissani allaffimmut saaffiginnittarfimmi sulerusuttumik pissarsiorpoq.</a>
                </div>
                <div className="translation">
                <img src="/denmark-flag.png" alt="Danish" /><a href="https://topasexplorergroup.com/job-hos-topas/?hr=show-job%2F192413%26locale%3Dda_DK" target="_blank" rel="noreferrer">Kundeservicemedarbejder i Disko Line A/S Ilulissat</a>
                </div>
               </div>
               <div className="availablejob">
                <div className="translation">
                <img src="/greenland-flag.png" alt="Greenlandic" /><a href="https://topasexplorergroup0.hr-on.com/show-job/189788&locale=kl_GL" target="_blank" rel="noreferrer">Disko Line A/S – Angallatinut biilinullu mekaniikerissarsiorneq</a>
                </div>
                <div className="translation">
                <img src="/denmark-flag.png" alt="Danish" /><a href="https://topasexplorergroup0.hr-on.com/show-job/189215&locale=da_DK" target="_blank" rel="noreferrer">Disko Line A/S - Båd/bilmekaniker søges</a>
                </div>
               </div>
               <div className="availablejob">
                <div className="translation">
                <img src="/greenland-flag.png" alt="Greenlandic" /><a href="https://topasexplorergroup0.hr-on.com/show-job/174041&locale=kl_GL" target="_blank" rel="noreferrer">Disko Line angallassinermut ataqatigiissaarisumik pissarsiorpugut</a>
                </div>
                <div className="translation">
                <img src="/denmark-flag.png" alt="Danish" /><a href="https://topasexplorergroup0.hr-on.com/show-job/174026&locale=da_DK" target="_blank" rel="noreferrer">Trafikkoordinator til Disko Line</a>
                </div>
               </div>
            </div>
        </div>
    )
}