import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import config from '../../configs/config';
import MyJourneyDescription from "../../components/MyJourneyDescriptions";
import enums from '../../commons/Enums'

export default function CancelTicket() {
  const { t, i18n } = useTranslation(["common", "toastmessages"]);
  const [error, setError] = useState(null);
  const [orderNumber, setOrderNumber] = useState("");
  const [ticketNumber, setTicketNumber] = useState("");
  const [ticketData, setTicketData] = useState(null);
  const [totalRefund, setTotalRefund] = useState(0);
  const [showCancelTicket, setShowCancelTicket] = useState(false);
  const [clicked, setClicked] = useState(false);
  const apiUrl = config.default.apiPath;
  const { languagesToIndex } = enums;
  const handleTicketNumberChange = (e) => {
    setTicketNumber(e.target.value);
  };
  const handleOrderNumberChange = (e) => {
    setOrderNumber(e.target.value);
  };
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      const url = `${apiUrl}/Cart/SearchActiveTicket?OrderID=${orderNumber}&TicketID=${ticketNumber}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "LanguageID": languagesToIndex[i18n.language],
        },
      });
      if (!response.ok) {
        throw new Error(t("errorMessages.genericError"));
      }
      const responseData = await response.json();
      if (Array.isArray(responseData) && responseData.length > 0) {
        setTicketData(responseData[0]);
        setTotalRefund(responseData[0].unitPrice - responseData[0].cancelFee);
        setShowCancelTicket(true);
        
      } else if (responseData.success === false) {
        setError(responseData.errorMessage || t("errorMessages.genericError"));
        setTicketData(null);
      } else {
        setError(t("errorMessages.ticketNotFound"));
        setTicketData(null);
      }
    } catch (error) {
      setError(error.message || t("errorMessages.genericError"));
    }
  };

  const handleConfirmCancel = async () => {
    if (clicked) {
      return;
    }
    setClicked(true); 

    try {
      const requestBody = {
        ticketIDs: [ticketData.id],
        orderID: orderNumber,
      };
      // Send the request to cancel ticket
      const response = await fetch(`${apiUrl}/Cart/UserCancelTicket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.status === 200) {
        const responseData = await response.json();
        if (responseData.success === true) {
          // Ticket cancellation was successful
          const ticketsCancelled = t("toastmessages:cancelSucces");
          toast.success(ticketsCancelled);
          setError(null);
          setShowCancelTicket(false);
        } else {
          const ticketsNotCancelled = t("toastmessages:cancelUnsuccessful");
          toast.error(ticketsNotCancelled);
          setError(responseData.errorMessage || t("errorMessages.genericError"));
        }
      } else {
        setError(t("errorMessages.genericError"));
      }
    } catch (error) {
      setError(error.message || t("errorMessages.genericError"));
    }
    finally {
      setClicked(false);
    }
  };

  return (
    <div className="cancelticketpage">
      <Helmet>
        <title>{t("cancelTicket")} | Disko Line {t("greenland")}</title>
      </Helmet>
      <h1>{t("cancelTicket")}</h1>
        <MyJourneyDescription Id="2" />
      {!showCancelTicket && (
        <form onSubmit={handleSearch}>
          <div>
            <label>
              <div className="label-and-input">
                {t("orderID")}:{" "}
                <input
                  type="text"
                  value={orderNumber}
                  onChange={handleOrderNumberChange}
                />
              </div>
            </label>
          </div>
          <div>
            <label>
              <div className="label-and-input">
                {t("ticketID")}:{" "}
                <input
                  type="text"
                  value={ticketNumber}
                  onChange={handleTicketNumberChange}
                />
              </div>
            </label>
          </div>
          <div className="button-center">
            <button className="btn search" type="submit">
              {t("search")}
            </button>
          </div>
        </form>
      )}
       {error && <p className="error">{error}</p>}
      {showCancelTicket && (
        <div className="cancelticket-journey">
            <h2>{t("myTicket")}</h2>
          <table>
          <thead>
              <tr>
                <th className="description">{t('date')}</th>
                <th className="description">{t('time')}</th>
                {ticketData.isActivity ? (
            <th className="description">{t('activity')}</th> ) : (
            <th className="description">{t('route')}</th>
            )
          }
                <th className="description">{t('price')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
            <p>
              {new Date(ticketData.departureDate).toLocaleDateString("da-DK", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </p>
              </th>
              <th>
                <p>{ticketData.departureTime.slice(11, 16)} - {ticketData.arrivalTime.slice(11, 16)}</p> </th>
                {ticketData.isActivity ? (
            <th><p>{ticketData.departureStopName}</p></th> ) : (
            <th><p>{ticketData.departureStopName}  - {ticketData.arrivalStopName} </p></th>
            )
          }
            <th> <p>{ticketData.unitPrice} DKK</p></th>
            </tr>
              <tr>
                <th><p>{t("cancelFee")}</p> </th>
                <th></th>
                <th></th>
                <th><p>{ticketData.cancelFee} DKK</p></th>
              </tr>
              <tr>
                <th><p className="bold">{t("forRefund")}</p></th>
                <th></th>
                <th></th>
                <th><p className="bold">{totalRefund} DKK</p></th>
              </tr>

              </tbody>
            </table>
        <div className="cancel-message">
        <p>{t("confirmCancelMessage")}</p>
          <button className="btn cancel" onClick={handleConfirmCancel}>{t("refundTicket")}</button>
        </div>
        </div>
      )}
    </div>
  );
}
