import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function BuyTicketTabs({ activeTab }) {
    const { t } = useTranslation(["common", "buyticket"]);
    const containerClassName = `buy-ticket-tabs ${activeTab === 1 ? 'tab-1-active' : ''}`;
    return (
        <div className={containerClassName}>
            <div className={`step-tab step-tab-1 ${activeTab === 1 ? 'active-tab' : ''}`}><NavLink to={"/buy-ticket"}>1.{t("buyticket:findJourney")}</NavLink></div>
            <div className={`step-tab ${activeTab === 2 ? 'active-tab' : ''}`}><NavLink to={"/my-cart"}>2.{t("cart")}</NavLink></div>
            <div className={`step-tab ${activeTab === 3 ? 'active-tab' : ''}`}>3.{t("buyticket:information")}</div>
            <div className={`step-tab ${activeTab === 4 ? 'active-tab' : ''}`}>4.{t("buyticket:summary")}</div>
            <div className={`step-tab ${activeTab === 5 ? 'active-tab' : ''}`}>5.{t("buyticket:payment")}</div>
        </div>
    );
}
