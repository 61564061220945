import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "./AuthContext";
import Cookies from "js-cookie";
import config from '../configs/config';
import Spinner from "./Spinner";
import { useForm } from "./FormContext";
import { useSearchData } from "./SearchContext";
import enums from '../commons/Enums'

export default function SearchTicketRoute({ areaName, onSearch }) {
  const params = useParams();
  const location = useLocation();
  const { t, i18n } = useTranslation(["common", "buyticket"]);
  const [areaSlug, setAreaSlug] = useState("");
  const [stops, setStops] = useState([]);
  const [area, setArea] = useState([]);
  const [globalSetup, setGlobalSetup] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiUrl = config.default.apiPath;
  const { languagesToIndex, languagesToIndexCalendar } = enums;
  const [translatedTicketTypes, setTranslatedTicketTypes] = useState([
    { ticketTypeID: 1, quantity: "0" },
    { ticketTypeID: 2, quantity: "0" },
    { ticketTypeID: 3, quantity: "0" },
    { ticketTypeID: 4, quantity: "0" },
  ]);
  const {formRequest, setFormRequest} = useForm();
  const {setSearchData } = useSearchData();
  useEffect(() => {
    fetch(`${apiUrl}/Area/GetArea/${areaSlug}`)
       .then((response) => response.json())
       .then((data) => {
          setArea(data);
       })
       .catch((err) => {
          console.log(err.message);
       });
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [areaSlug]);
  useEffect(() => {
    fetch(`${apiUrl}/System/GetGlobalSetup`)
    .then((response) => response.json())
    .then((data) => {
       setGlobalSetup(data);
    })
    .catch((err) => {
       console.log(err.message);
    });
    },[apiUrl])
    const areaImgSrc = globalSetup.hostName + area.imgUrl;
  
    // Get authentication status and selected partner group
    const { authenticated, checkTokenExpiry } = useAuth();
    checkTokenExpiry();
    var selectedPartnerGroupStr = Cookies.get('selectedPartnerGroup')
    const selectedPartnerGroup = authenticated && selectedPartnerGroupStr
    ? JSON.parse(selectedPartnerGroupStr) : null;
  
      const [validationErrors, setValidationErrors] = useState({
        departure: "",
        date: "",
        ticketTypes: "",
      });
      const validateForm = () => {
        let isValid = true;
        const errors = {
          date: "",
          ticketTypes: "",
        };
        //ERROR MESSAGES
        const errorDate = t("buyticket:error.dateMissing");
        const errorTicketTypes = t("buyticket:error.ticketTypesMissing");
        //VALIDATION FOR INPUT
        if (!selectedDate) {
          errors.date = errorDate
          isValid = false;
        }
        const hasSelectedTicketType = formRequest.numberTicketTypes.some(
          (ticketType) => parseInt(ticketType.quantity) > 0
        );
        if (!hasSelectedTicketType) {
          errors.ticketTypes = errorTicketTypes;
          isValid = false;
        }
        setValidationErrors(errors);
        return isValid;
      };
      async function fetchTicketTypesFromAPI() {
        const response = await fetch(`${apiUrl}/Cart/GetTicketTypes`);
        const data = await response.json();
        return data;
      }
      useEffect(() => {
        // Fetch ticket types from your API
        fetchTicketTypesFromAPI()
          .then((ticketTypes) => {
            // Translate ticket types based on the current language
            const translatedTypes = ticketTypes.map((ticketType) => ({
              ticketTypeID: ticketType.id,
              ticketTypeName: translateTicketType(ticketType.ticketTypeTranslations, i18n.language),
            }));
            setTranslatedTicketTypes(translatedTypes);
          })
          .catch((error) => {
            console.error('Error fetching ticket types:', error);
          });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [i18n.language]);
      const translateTicketType = (ticketTypeTranslations, currentLanguage) => {
        const languageIdMapping = {
          dk: 1, 
          en: 2,
          gl: 3, 
        };
        // Find the translation that matches the current language
        const translation = ticketTypeTranslations.find(
          (translation) => translation.languageID === languageIdMapping[currentLanguage]
        );
      
        // If a translation is found, return its ticketTypeName, otherwise, use the default translation
        return translation ? translation.ticketTypeName : ticketTypeTranslations[0].ticketTypeName;
      };
  useEffect(() => {
    setFormRequest((prevData) => ({
      ...prevData,
      numberTicketTypes: translatedTicketTypes,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(()=>{
    setAreaSlug(params.id)
  }, [params.id])

  useEffect(() => {
    async function getStops() {
      const headers = {
        'Content-Type': 'application/json',
        'LanguageID': languagesToIndex[i18n.language]
      };
  
      let ticketApiUrl = `${apiUrl}/Stop/GetTicketableStops/${areaSlug}`;
  
      if (authenticated) {
        checkTokenExpiry();
        const selectedPartnerGroup = JSON.parse(Cookies.get('selectedPartnerGroup'));
        const partnerGroupID = selectedPartnerGroup.partnerGroupID;
        const authToken = Cookies.get('authToken');
        // Add authentication header
        headers['Authorization'] = `Bearer ${authToken}`;
        // Assuming partnerGroupID is a value accessible in the scope
        const queryParams = `?partnerGroupID=${partnerGroupID}`;
        ticketApiUrl = `${ticketApiUrl}${queryParams}`;
      }
  
      const response = await fetch(ticketApiUrl, {
        method: 'GET',
        headers: headers
      });
  
      const data = await response.json();
      setStops(data);
    }
  
    if (areaSlug) {
      getStops();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaSlug, apiUrl, authenticated, checkTokenExpiry, languagesToIndex[i18n.language]]);


  useEffect(() => {
    async function getStops() {
      const response = await fetch(
        `${apiUrl}/Stop/GetTicketableStops/${areaSlug}`
      );
      const data = await response.json();
      setStops(data);
    }
    if (areaSlug) {
      getStops();
    }
  },[areaSlug, apiUrl]);


  const handleChangeDate = (date) => {
    setSelectedDate(date);
    if (date) {
      const formattedDate = date.toLocaleDateString("en-GB"); // Format as dd/mm/yyyy
      setFormRequest((prevData) => ({
        ...prevData,
        departureDate: formattedDate,
      }));
    }
  };

  const handleChange = (event, ticketTypeID, index) => {
    const { name, value } = event.target;
    if (name === "departureStopID") {
      setFormRequest((prevData) => ({
        ...prevData,
        departureStopID: value,
      }));
    } else if (name === "quantity") {
      const updatedTicketTypes = formRequest.numberTicketTypes.map((ticketType, idx) =>
        idx === index
          ? { ...ticketType, quantity: value }
          : ticketType // Keep other ticket types unchanged
      );
      setFormRequest((prevData) => ({
        ...prevData,
        numberTicketTypes: updatedTicketTypes,
      }));
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const formattedDate = selectedDate
      ? selectedDate.toLocaleDateString("en-GB").split('/').reverse().join('-')
      : "";
    const apiURL = `${apiUrl}/Cart/SearchActivityJourney`;
    const requestBody = {
      areaID: areaSlug,
      departureStopID: formRequest.departureStopID,
      departureDate: formattedDate,
      numberTicketTypes: formRequest.numberTicketTypes,
      page: 0,
      isTransfer: formRequest.isTransfer,
      isSearchEarlier: formRequest.isSearchEarlier,
      ...(authenticated && selectedPartnerGroup && { partnerGroupID: selectedPartnerGroup.partnerGroupID }),
    };
    localStorage.setItem("numberTicketTypes", JSON.stringify(requestBody.numberTicketTypes))
    if (validateForm()) {
      const loadingTimeout = setTimeout(() => {
        setLoading(true);
      }, 1000);
   
      try {
        const response = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "LanguageID": languagesToIndex[i18n.language],
            ...(authenticated && { Authorization: `Bearer ${Cookies.get('authToken')}` }),
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          return Promise.reject(errorData);
        }
  
        const data = await response.json();
        clearTimeout(loadingTimeout); 
        setLoading(false);
        setSearchData(data);
        onSearch(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        clearTimeout(loadingTimeout); 
        setLoading(false);
      }
    }
  }

  // Options that will be displayed in the dropdown menu 
  const zeroToTwelve = [...Array(13).keys()].map((i) => <option key={i}>{i}</option>);

  // Get the day today and make it the min day in the date input
  let today = new Date();


  //Function that returns selected trip in searchfield on search page
  const getSelectedActivity = (activityID) => { 
    const params = new URLSearchParams(location.search);
   return params.get(activityID)
  }
  
  useEffect(() => {
    const selectedTrip = getSelectedActivity('selectedActivity')
    
    if (selectedTrip) {
      const activityFound = stops.some((stop) => stop.id === parseInt(selectedTrip)); 
      setFormRequest((prevData) => ({
      ...prevData,
      departureStopID: activityFound ? parseInt(selectedTrip):0,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }}, [location.search, stops]
  )



  return (
    <div className="search-form activity-form">
      {loading && <Spinner className="search-spinner" />}
      <form className="form-step-1" onSubmit={handleSubmit}>
        <h1>{areaName}</h1>
        <div className="search-form-content">
        <div className="img-container activity-img">
        <img src={areaImgSrc} alt="Area" className="area-img-small"/>
        </div>
        <div className="departure-and-date">
          <div className="departure">
          <label htmlFor="departure">{t("buyticket:selectActivity")}:</label>
          <select
            name="departureStopID"
            id="departure"
            value={formRequest.departureStopID}
            onChange={handleChange}
            className={validationErrors.departure ? 'input-error' : ''}
            data-error-message={validationErrors.departure}
          >
              <option value={0}>{t("buyticket:allActivites")}</option>
            {stops.map((stop) => (
              <option key={stop.id} value={stop.id}>
              {stop.stopName}
              </option>
            ))}
          </select>
          {validationErrors.departure && (
        <div className="tooltip">{validationErrors.departure}</div>
      )}
            </div>
            <div className="date">
          <label htmlFor="date">{t("buyticket:selectDate")}</label>
          <DatePicker
              id="date"
              name="departureDate"
              autoComplete="off"
              selected={selectedDate}
              onChange={handleChangeDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/mm/yyyy"
              locale={languagesToIndexCalendar[i18n.language]}
              calendarStartDay={1}
              minDate={today}
              className={validationErrors.date ? 'input-error' : ''}
              data-error-message={validationErrors.date}
            />
             {validationErrors.date && (
        <div className="tooltip">{validationErrors.date}</div>
      )}
            </div>
            </div>
          <div className="participants">
            {translatedTicketTypes.map((ticketType, index) => (
              <div key={ticketType.ticketTypeID} className="participants-type">
                
                <label>
                      {ticketType.ticketTypeName}
                      <br />
                      <select
                        data-index={index}
                        name="quantity"
                        value={ticketType.quantity}
                        onChange={(event) =>
                          handleChange(event, ticketType.ticketTypeID, index)
                        }
                      >
                        {zeroToTwelve}
                      </select>
                </label>
              </div>
            ))}
            {validationErrors.ticketTypes && (
        <div className="tooltip">{validationErrors.ticketTypes}</div>
      )}
        </div>
        </div>
        <button type="submit">{t("buyticket:searchActivity")}</button>
      </form>
    </div>
  );
}
