import { format } from 'date-fns';

function formatDate(dateString, formatStr) {
  if (!dateString) return '';

  try {
    const date = new Date(dateString);
    return format(date, formatStr);
  } catch (error) {
    console.error('Invalid date or format:', error);
    return '';
  }
}

function formatTime(dateString, formatStr) {
  if (!dateString) return '';

  try {
    const date = new Date(dateString);
    return format(date, formatStr);
  } catch (error) {
    console.error('Invalid date or format:', error);
    return '';
  }
}

var utilities = { formatDate, formatTime };
export default utilities;