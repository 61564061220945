import { Helmet } from "react-helmet";
//Images
import headerImage from "../../images/fleet/fleet-header.jpg";
import aleqaImage from "../../images/fleet/aleqa.JPG";
import amaroqImage from "../../images/fleet/amaroq.JPG";
import sulisoqImage from "../../images/fleet/Sulisoq.JPG";
import najaaraqImage from "../../images/fleet/Najaaraq.JPG";
import nanoqImage from "../../images/fleet/Nanoq.JPG";
import arfivikImage from "../../images/fleet/Arfivik.JPG";
import aarlukImage from "../../images/fleet/Aarluk.JPG";
import niisaImage from "../../images/fleet/Niisa.JPG";
import iceforceImage from "../../images/fleet/iceforce.jpg";
import nilakImage from "../../images/fleet/nilak.jpg";
import iluliaqImage from "../../images/fleet/iluliaq.jpg";
import siriusImage from "../../images/fleet/Sirius2.jpg";
import normaImage from "../../images/fleet/Norma.jpg";
import bolattaImage from "../../images/fleet/Bolatta.jpg";
import noImage from "../../images/fleet/imgcoming.png";
import { useTranslation } from "react-i18next";
export default function Fleet(){
    const {t} = useTranslation(["common", "about"])
    const headerStyle = {
        backgroundImage: `url(${headerImage})`
    }
    return(
        <div className="fleetpage" >
            <Helmet>
                <title>{t('fleet')} | Disko Line Greenland</title>
            </Helmet>
            <div className="header" style={headerStyle}>
                <div className="header-content">
                    <p>Disko Line</p>
                    <h1>{t('fleet')}</h1>
                </div>
            </div>
            <div className="content">
                <div className="section-1">

                    <p className="tagline">{t('about:aboutFleettagline')}</p>
                    <h2>{t('about:aboutFleetDiskobay')}</h2>

                </div>
                <div className="fleet">
                    <div className="fleet-section">
                        <h3>{t('about:aboutFleetAleqa')}</h3>
                        <img src={aleqaImage} alt="Aleqa boat" className="fleet-img" />
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b>36 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> {t('about:aboutFleetAleqaType')}</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 9,5 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> {t('about:')}1983</td>
                            </tr>
                        </table>
                    </div>
                    <div className="fleet-section">
                        <h3>{t('about:aboutFleetNajaaraq')}</h3>
                        <img src={najaaraqImage} alt="Najaaraq Ittuk boat" className="fleet-img"/>
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b>60 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> HVSA #90</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 15 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> 1999</td>
                            </tr>
                        </table>
                    </div>
                    
                </div>
                <div className="fleet">
                <div className="fleet-section">
                        <h3>{t('about:aboutFleetAarluk')}</h3>
                        <img src={aarlukImage} alt="Aarluk boat" className="fleet-img"/>
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> Targa 37; Botnia Marin</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 28 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> 2012</td>
                            </tr>
                        </table>
                    </div>
                    
                    <div className="fleet-section">
                        <h3>{t('about:aboutFleetAmaroq')}</h3>
                        <img src={amaroqImage} alt="Amaroq boat" className="fleet-img"/>
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b>Targa 37; Botnia Marin</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 28 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b>2011</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="fleet">
                <div className="fleet-section">
                        <h3>{t('about:aboutFleetArfivik')}</h3>
                        <img src={arfivikImage} alt="Arfivik boat" className="fleet-img" />
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> Targa 44; Botnia Marin</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 28 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> 2014</td>
                            </tr>
                        </table>
                    </div>
                    <div className="fleet-section">
                        <h3>{t('about:aboutFleetNanoq')}</h3>
                        <img src={nanoqImage} alt="Nanoq boat" className="fleet-img"/>
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b>12 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> Targa 37; Botnia Marin</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b>28 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> 2008</td>
                            </tr>
                        </table>
                    </div>
                    
                </div>
                <div className="fleet">
                    <div className="fleet-section">
                            <h3>{t('about:aboutFleetNiisa')}</h3>
                            <img src={niisaImage} alt="Niisa boat" className="fleet-img"/>
                            <table>
                                <tr>
                                    <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                    <td><b>{t('about:aboutFleetType')}</b> Targa 37; Botnia Marin</td>
                                </tr>
                                <tr>
                                    <td><b>{t('about:aboutFleetSpeed')}</b> 28 {t('about:aboutFleetKnots')}</td>
                                    <td><b>{t('about:aboutFleetBuild')}</b> 2019</td>
                                </tr>
                            </table>
                        </div>
                    <div className="fleet-section">
                            <h3>{t('about:aboutFleetSulisoq')}</h3>
                            <img src={sulisoqImage} alt="Sulisoq boat" className="fleet-img" />
                            <table>
                                <tr>
                                    <td><b>{t('about:aboutFleetCapacity')}</b>12 {t('about:aboutFleetPassengers')}</td>
                                    <td><b>{t('about:aboutFleetType')}</b> Targa 44; Botnia Marin</td>
                                </tr>
                                <tr>
                                    <td><b>{t('about:aboutFleetSpeed')}</b>28 {t('about:aboutFleetKnots')}</td>
                                    <td><b>{t('about:aboutFleetBuild')}</b> 2012</td>
                                </tr>
                            </table>
                        </div>
                    
                </div>
                <div className="fleet">
                    <div className="fleet-section">
                        <h3>{t('about:aboutFleetUkaleq')}</h3>
                        <img src={noImage} alt="Img coming soon" className="fleet-img" />
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> Targa 33; Botnia Marin</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 28 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> 1997</td>
                            </tr>
                        </table> 
                </div>
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <div className="section-1">

                    <p className="tagline">{t('about:aboutFleettagline2')}</p>
                    <h2>{t('about:aboutFleetCentral')}</h2>

                </div>
                <div className="fleet">
                    <div className="fleet-section">
                        <h3>{t('about:aboutFleetIceForce')}</h3>
                        <img src={iceforceImage} alt="iceforce V boat" className="fleet-img" />
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> Targa 37; Botnia Marin</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 28 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> 2009</td>
                            </tr>
                        </table>
                    </div>
                    <div className="fleet-section">
                <h3>{t('about:aboutFleetSirius')}</h3>
                        <img src={siriusImage} alt="Sirius boat" className="fleet-img" />
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> Targa 35; Botnia Marin</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 37 {t('about:aboutFleetKnots')}</td>
                                {/* <td><b>{t('about:aboutFleetBuild')}</b> 1997</td> */}
                            </tr>
                        </table> 
                </div>
                </div>
                <div className="fleet">
                    <div className="fleet-section">
                    <h3>{t('about:aboutFleetNorma')}</h3>
                        <img src={normaImage} alt="Norma boat" className="fleet-img" />
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> Targa 37; Botnia Marin</td>
                            </tr>
                            {/* <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b>  {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> </td>
                            </tr> */}
                        </table>
                </div>
                <div className="fleet-section">
                        <h3>{t('about:aboutFleetBolatta')}</h3>
                        <img src={bolattaImage} alt="Bolatta boat" className="fleet-img" />
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                {/* <td><b>{t('about:aboutFleetType')}</b> Targa 35, Botnia Marin</td> */}
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 28 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b>2009</td>
                            </tr>
                        </table> 
                </div>
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <div className="section-1">

                    <p className="tagline">{t('about:aboutFleettagline2')}</p>
                    <h2>{t('about:aboutFleetSouth')}</h2>

                </div>
                <div className="fleet">
                <div className="fleet-section">
                        <h3>{t('about:aboutFleetIluliaq')}</h3>
                        <img src={iluliaqImage} alt="Iluliaq boat" className="fleet-img"/>
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> Targa 37; Botnia Marin</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 28 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> 2009</td>
                            </tr>
                        </table>
                    </div>
                    <div className="fleet-section">
                        <h3>{t('about:aboutFleetNilak')}</h3>
                        <img src={nilakImage} alt="Nilak boat" className="fleet-img" />
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 12 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> Targa 37; Botnia Marin</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 28 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> 2008</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="fleet">
                    <div className="fleet-section">
                        <h3>{t('about:aboutFleetNajaaraq')}</h3>
                        <img src={najaaraqImage} alt="Najaaraq boat" className="fleet-img" />
                        <table>
                            <tr>
                                <td><b>{t('about:aboutFleetCapacity')}</b> 60 {t('about:aboutFleetPassengers')}</td>
                                <td><b>{t('about:aboutFleetType')}</b> HVSA #90</td>
                            </tr>
                            <tr>
                                 <td><b>{t('about:aboutFleetSpeed')}</b> 15 {t('about:aboutFleetKnots')}</td>
                                <td><b>{t('about:aboutFleetBuild')}</b> 1999</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}