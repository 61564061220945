import Cookies from "js-cookie";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faCreditCard, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import config from '../../configs/config';
import { toast } from "react-toastify";
import partnerService from '../../services/PartnerService'
import Spinner from "../../components/Spinner";


export default function PartnerOrderList() {
  const [t] = useTranslation(["common", "partner"]);
  const navigate = useNavigate();
  const { checkTokenExpiry } = useAuth();
  const [partnerOrderList, setPartnerOrderList] = useState([]);
  const [selectedOrderID, setSelectedOrderID] = useState(0);
  const selectedPartnerGroup = JSON.parse(Cookies.get("selectedPartnerGroup"));
  const partnerGroupID = selectedPartnerGroup.partnerGroupID;
  const [filterOptions, setFilterOptions] = useState({
    fromDate: "",
    toDate: "",
    referenceCode: "",
  });
  const [editedReferenceCodes, setEditedReferenceCodes] = useState({});
  const [displayLimit, setDisplayLimit] = useState(15);
  const loadMoreCount = 10;
  const displayedOrders = partnerOrderList.slice(0, displayLimit);
  const apiUrl = config.default.apiPath;
  const handleLoadMore = () => {
    // Increase the display limit to load more items
    setDisplayLimit(displayLimit + loadMoreCount);
  };

  async function getPartnerOrderList() {
    await checkTokenExpiry();
    const authToken = Cookies.get("authToken");
    if (!authToken) {
      throw new Error("Authentication token not found in cookies");
    }
    const headers = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json", // Set the content type to JSON
    };
    const filteredFilterOptions = Object.fromEntries(
      Object.entries(filterOptions).filter(([_, value]) => value !== "")
    );
    // Combine partnerGroupID and filteredFilterOptions when making the API call
    const requestBody = JSON.stringify({
      partnerGroupID,
      ...filteredFilterOptions,
    });
    const response = await fetch(`${apiUrl}/Partner/GetPartnerOrders`, {
      method: "POST", // Use POST method to send data
      headers,
      body: requestBody,
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
    const data = await response.json();
    // Format orderDate for each order to display only the date portion
    const formattedData = data.map((order) => ({
      ...order,
      orderDate: formatOrderDate(order.orderDate),
    }));
    setPartnerOrderList(formattedData);
  }
  // Function to format the date portion of orderDate
  function formatOrderDate(dateString) {
    try {
      const date = new Date(dateString);
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = date.getFullYear();
      return `${dd}-${mm}-${yyyy}`;
    } catch (error) {
      console.error("Error formatting date:", error);
      return dateString; // Return the original date string if there's an error
    }
  }
  const formatNumber = (number) => {
    // Convert number to string and split into integer and decimal parts
    const parts = number.toFixed(2).toString().split('.');
    // Replace dot with comma as decimal separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    // Return formatted number
    return parts.join(',');
  };
  async function downloadTickets(orderID, verifyCode) {
    const globalSetup = await fetch(`${apiUrl}/System/GetGlobalSetup`)
    const globalSetupData = await globalSetup.json()
    const APIUrl = globalSetupData.hostNameApi;
    const ticketUrl = `${APIUrl}Cart/GetTicketFile?OrderID=${orderID}&VerifyCode=${verifyCode}`
    window.open(ticketUrl);
  }

useEffect(() => {
  getPartnerOrderList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const handleViewOrderDetails = (orderID) => {
  navigate("/partner/orderdetail", { state: { orderID: orderID } });
};
const handleFilterChange = (e) => {
  const { name, value } = e.target;
  setFilterOptions({
    ...filterOptions,
    [name]: value,
  });
};

const handleChangeOrderLanguage = async (e, orderID) => {
  const value = e.target.value;
  setSelectedOrderID(orderID);
  const data = await partnerService.updateOrderLanguage(orderID, value);

  if (data && data.success) {
    setPartnerOrderList(prevOrders =>
      prevOrders.map(order =>
        order.orderID === orderID
          ? { ...order, orderLanguage: value }
          : order
      )
    );
    toast.success(t("partner:updateOrderLanguageSuccess"))
  } else {
    toast.error(t("partner:updateOrderLanguageFailed"));
  }
  setSelectedOrderID(0);
};
const handleEditReferenceCode = async (orderId) => {
  // Get the edited reference code for the specific order
  const editedReferenceCode = editedReferenceCodes[orderId];

  // Make the API call to update the reference code for the order
  const authToken = Cookies.get("authToken");
  if (!authToken) {
    throw new Error("Authentication token not found in cookies");
  }

  const headers = {
    Authorization: `Bearer ${authToken}`,
    "Content-Type": "application/json",
  };

  const requestBody = {
    orderID: orderId, // Ensure orderId is included in the request body
    referenceCode: editedReferenceCode,
    partnerGroupID,
  };

  const response = await fetch(`${apiUrl}/Partner/UpdateOrderReferenceCode`, {
    method: "POST",
    headers,
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(`Failed to update reference code: ${response.statusText}`);
  }

  // Refresh the order list after the update
  getPartnerOrderList();
};
const handleSearch = () => {
  getPartnerOrderList();
};
const handleGoBack = () => {
  navigate("/partner");
}

return (
  <div className="partnerorderlist">
    <Helmet>
      <title>Order List | Disko Line Greenland</title>
    </Helmet>
    <h1>Order List</h1>

    <div className="filter-form">
      <div className="label-and-input">
        <label htmlFor="fromDate">{t("partner:from")}:</label>
        <input
          type="date"
          id="fromDate"
          name="fromDate"
          value={filterOptions.fromDate}
          onChange={handleFilterChange}
        />
      </div>
      <div className="label-and-input">
        <label htmlFor="toDate">{t("partner:to")}:</label>
        <input
          type="date"
          id="toDate"
          name="toDate"
          value={filterOptions.toDate}
          onChange={handleFilterChange}
        />
      </div>
      <div className="label-and-input">
        <label htmlFor="orderID">{t("partner:orderID")}:</label>
        <input
          type="number"
          id="orderID"
          name="orderID"
          value={filterOptions.orderID}
          onChange={handleFilterChange}
        />
      </div>
      <div className="label-and-input">
        <label htmlFor="referenceCode">{t("partner:reference")}:</label>
        <input
          type="text"
          id="referenceCode"
          name="referenceCode"
          value={filterOptions.referenceCode}
          onChange={handleFilterChange}
        />
      </div>
      <button className="btn" onClick={handleSearch}>{t("search")}</button>
    </div>
    <table className="order-table">
      <thead>
        <tr>
          <th>
            <p>{t("partner:orderID")}</p>
          </th>
          <th>
            <p>{t('partner:customerName')}</p>
          </th>
          <th>
            <p>{t("partner:reference")}</p>
          </th>
          <th>
            <p>{t("partner:dateOfOrder")}</p>
          </th>
          <th>
            <p>{t("partner:paymentType")}</p>
          </th>
          <th>
            <p>{t("partner:orderLanguage")}</p>
          </th>
          <th className="total-amount header">
            <p>{t("partner:totalAmount")}</p>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {displayedOrders.map((order, index) => (
          <tr key={index}>
            <td className="pointer" onClick={() => handleViewOrderDetails(order.orderID)}>{order.orderID}</td>
            <td>{order.orderCustomerName}</td>
            <td>  <input
              type="text"
              value={editedReferenceCodes[order.orderID] || order.referenceCode}
              onChange={(e) => {
                // Track the edited referenceCode for the specific order
                const newEditedReferenceCodes = {
                  ...editedReferenceCodes,
                  [order.orderID]: e.target.value,
                };
                setEditedReferenceCodes(newEditedReferenceCodes);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleEditReferenceCode(order.orderID);
                }
              }}
              onBlur={() => {
                handleEditReferenceCode(order.orderID);
              }}
            /></td>
            <td>{order.orderDate}</td>
            <td className="payment-type">{order.isOrderInvoice ? <FontAwesomeIcon icon={faFileInvoiceDollar} /> : <FontAwesomeIcon icon={faCreditCard} />}</td>
            <td className="order-language">
              {selectedOrderID !== order.orderID ? (
                <select onChange={async (e) => await handleChangeOrderLanguage(e, order.orderID)} value={order.orderLanguage}>
                  <option value={1}>Danish</option>
                  <option value={2}>English</option>
                  <option value={3}>Greenlandic</option>
                </select>
              ) : (
                <Spinner size="small" />
              )}
            </td>
            <td className="total-amount"><p>{formatNumber(order.totalAmount)} DKK</p></td>
            <td><div className="download pointer" title={t("partner:downloadTickets")} onClick={() => downloadTickets(order.orderID, order.verifyCode)}><FontAwesomeIcon icon={faDownload} /></div></td>
            <td>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {displayLimit < partnerOrderList.length && (
      <button className="btn load-more" onClick={handleLoadMore}>
        {t("partner:loadMore")}
      </button>
    )}
    <div className="button-center">
      <button className="btn-secondary" onClick={handleGoBack}>{t("partner:backToMyAccount")}</button>
    </div>
  </div>
)
}