import Cookies from "js-cookie";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookieContext } from "../../components/CookieContext";




export default function CookiePolicy(){

const {t} = useTranslation(["common", "about", "toastmessages"]);
const cookieConsentStatus = Cookies.get("cookieConsent");
const [cookieConsent, setCookieConsent] = useState("");
const {setShowSettings} = useCookieContext();

useLayoutEffect(() => {
    // Scroll to the top on load
    window.scrollTo(0, 0); 
  }, []);

  useEffect(() => {
    function getCookieConsent() {
        if (cookieConsentStatus === "all") {
            setCookieConsent("allowed");
        } else if (cookieConsentStatus === "none") {
            setCookieConsent("denied");
        } else if (cookieConsentStatus === "custom") {
            setCookieConsent("partiallyAllowed");
        }
    }
    getCookieConsent();
}, [cookieConsentStatus, cookieConsent]);

  const cookieStates = {
    "allowed": t("about:allowed"),
    "denied": t("about:denied"),
    "partiallyAllowed": t("about:partiallyAllowed"),
  }

  const handleManageCookies = () => {
    Cookies.remove("viewed_cookie_policy");
    setShowSettings(true);

  }

  const handleClickCookie = () => {
    handleManageCookies();

  }
  

    return(
        <div className="cookie-policy-page">
        <div className="section">
            <h1>{t("about:aboutCookiePolicy")}</h1>
            <h2>{t("about:aboutCookiePolicy2")}</h2>
            <p>{t("about:aboutCookiePolicy3")}</p>
            <p>{t("about:aboutCookiePolicy4")}</p>
            <div className="current-state">
                <p>{t("about:currentState")}</p>
                <p className="current-state-value">{cookieStates[cookieConsent]}</p>
                <button className="btn" onClick={handleClickCookie}>{t("about:manageConsent")}</button>
        </div>
        </div>
        <div className="section">
            <h2>{t("about:whatAreCookies")}</h2>
            <p>{t("about:whatAreCookies2")}</p>
        </div>
        <div className="section">
            <h2>{t("about:howWeUseCookies")}</h2>
            <p>{t("about:howWeUseCookies2")}</p>
        </div>
        <div className="section">
            <h2>{t("about:whatTypesOfCookies")}</h2>
            <h3>{t("toastmessages:necessary")}</h3>
            <p>{t("toastmessages:aboutNecessary")}</p>
            <h3>Google Analytics</h3>
            <p>{t("toastmessages:aboutAnalytics")}</p>
            <h3>Google Tag Manager</h3>
            <p>{t("toastmessages:aboutTagManager")}</p>
        </div>
        <div className="section">
            <h2>{t("about:howToDeleteCookies")}</h2>
            <p>{t("about:howToDeleteCookies2")}</p>
            <a href="https://tools.google.com/dlpage/gaoptout">{t("about:howToDeleteCookies3")}</a>
            <h3>{t("about:howToDeleteCookies4")}</h3>
            <p>{t("about:howToDeleteCookies5")}</p>
            <p>{t("about:howToDeleteCookies6")}</p>
            <ul>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a></li>
                <li><a href="https://support.apple.com/en-us/105082">Safari</a></li>
                <li><a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11">Internet Explorer</a></li>
                <li><a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US">Mozilla Firefox</a></li>
                <li><a href="https://help.opera.com/en/latest/web-preferences/">Opera</a></li>
                <li><a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">Flash Cookies</a></li>
                <li><a href="https://support.apple.com/en-us/105082">Apple</a></li>
                <li><a href="https://turbofuture.com/cell-phones/How-to-delete-internet-cookies-on-your-Droid-or-any-Android-device">Android</a></li>
            </ul>
            <p>{t("about:howToDeleteCookies7")}</p>
        </div>
        </div>
    )
}