import { Helmet } from "react-helmet";
import headerImage from "../../../images/headers/Icefiord_RAISFOTO_0846.jpg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CityWalk from "../../../images/dle-tours/CityWalk.jpg";
import Sermermiut from "../../../images/dle-tours/sermermiut-header.jpg";

export default function HikingTrips(){
    const {t} = useTranslation(["common", "diskolineexplorer"]);
    const headerStyle = {
      backgroundImage: `url(${headerImage})`
  }
  const navigate = useNavigate();
  function navigateToActivity(activityName){
    navigate(`/diskolineexplorer/${activityName}`);
  }
  return (
    <div className="diskoline-explorer-page">
      <Helmet>
        <title>{t('hikingTrips')}  | {t('diskoLineExplorer')}</title>
      </Helmet>
      <div className="header" style={headerStyle}>
                <div className="header-content">
                    <p className="tagline">Disko Line</p>
                    <h1>{t('hikingTrips')} </h1>
                </div>
      </div>
      <div className="area-container hiking-trips">
      <div className="area-card">
            <div className="area-img">
              <img src={CityWalk} alt="City Walk in Illulisat" />
               <h2 className="area-name">{t('cityWalkInIlulissat')}</h2>
              <div className="area-img-overlay" onClick={() => navigateToActivity("citywalk-in-ilulissat")}>
                <button onClick={() => navigateToActivity("citywalk-in-ilulissat")}>{t("readMore")}</button>
              </div>
            </div>
          </div>
          <div className="area-card">
            <div className="area-img">
              <img src={Sermermiut} alt="Sermermiut Hike" />
               <h2 className="area-name">{t('sermermiutHike')}</h2>
              <div className="area-img-overlay" onClick={() => navigateToActivity("sermermiut-hike")}>
                <button onClick={() => navigateToActivity("sermermiut-hike")}>{t("readMore")}</button>
              </div>
            </div>
          </div>
          </div>
      </div>
  )
}