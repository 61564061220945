import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Contact(){
    const {t} = useTranslation(["common", "contactpage"]);
    return(
        <div className="contactpage">
            <Helmet>
                <title>{t('contact')} | Disko Line Greenland</title>
            </Helmet>
            <h1>{t('contact')}</h1>
            <div className="flex">
            <div className="contact-box">
                <h2>Disko Line A/S</h2>
                <p>Postbox 305</p>
                <p>Kussangajaannguaq 11</p>
                <p>3952 Ilulissat</p>
                <p>{t('greenland')}</p>
                <br></br>
                <p><span className="bold">GrNr:</span>  A/S337257</p>
                <p><span className="bold">{t('contactpage:mail')}</span> <a href="mailto:info@diskoline.gl">info@diskoline.gl</a></p>
                <p><span className="bold">{t('contactpage:phone')}</span> <a href="tel:+299945300">+299 94 53 00</a></p>
                <br></br>
                <h3>{t('contactpage:openingHours')}</h3>
                <p><span className="bold">{t('contactpage:weekdays')}</span> 08.00 - 16.00 ({t('contactpage:phoneHours')})</p>
                <p><span className="bold">{t('contactpage:weekdays')}</span> 10.00 - 15.30 ({t('contactpage:officeHours')})</p>
            </div>
            <div className="contact-box">
                <h2>{t('contactpage:emergencyNo')}</h2>
                <p><span className="subheader">{t('contactpage:emergencySubHeader')} </span></p>
                <p><span className="bold">{t('contactpage:phone')}</span> <a href="tel:+299945300">+299 94 53 00</a></p>
                <br></br>
                <h3>{t('contactpage:openingHours')}</h3>
                <p><span className="bold">{t('contactpage:mondayFriday')}</span> 07.00 - 08.00 & 16.00 - 19.00</p>
                <p><span className="bold">{t('contactpage:saturday')}</span> 07.00 - 13.00</p>
                <p><span className="bold">{t('contactpage:sunday')}</span> 07.00 - 15.00</p>
                <br></br>
            </div>
            <div className="contact-box">
                <h2>Disko Line Explorer</h2>
                <p><span className="bold">{t('contactpage:phone')} </span><a href="tel:+299946380">+299 94 63 80</a></p>
                <p><span className="bold">{t('contactpage:mail')} </span><a href="mailto:info@diskolineexplorer.com">info@diskolineexplorer.com</a></p>
                <br></br>
                <h3>{t('contactpage:openingHours')}</h3>
                <p><span className="subheader">{t('contactpage:summerSeason')}</span></p>
                <p><span className="bold">{t('contactpage:mondaySunday')} </span>08:00-18:00 ({t('contactpage:phoneHours')})</p>
                <p><span className="bold">{t('contactpage:mondaySunday')} </span>09:00-18:00 ({t('contactpage:officeHours')})</p>
                <br></br>
                <p><span className="subheader">{t('contactpage:winterSeason')}</span></p>
                <p><span className="bold">{t('contactpage:weekdays')} </span>08:00-16:00 ({t('contactpage:phoneHours')})</p>
                <p><span className="bold">{t('contactpage:mondayThursday')} </span>09:00-16:00 ({t('contactpage:officeHours')})</p>
                <p><span className="bold">{t('contactpage:friday')} </span>10:00-16:00 ({t('contactpage:officeHours')})</p>
                <br></br>
            </div>
                <div className="contact-box">
                <h2>{t('charter')}</h2>
                <p><span className="bold">{t('contactpage:phone')} </span><a href="tel:+299946380">+299 94 63 80</a></p>
                <p><span className="bold">{t('contactpage:mail')} </span><a href="mailto:charter@diskoline.gl">charter@diskoline.gl</a></p>
                <br></br>
                <p>{t('contactpage:charterText')}</p>
            </div>
            </div>
        </div>
    )
}