import { useEffect, useState } from "react";
import BuyTicketTabs from "../../components/BuyTicketTabs";
import { useCart } from "../../components/CartContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "../../components/AuthContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import config from '../../configs/config';
import { faExchange, faTrash } from "@fortawesome/free-solid-svg-icons";
import enums from '../../commons/Enums'

export default function CustomerCart() {
  const { t, i18n } = useTranslation(["common", "buyticket", "toastmessages"]);
  const [customerCartTickets, setCustomerCartTickets] = useState([]);
  const [customerCartFees, setCustomerCartFees] = useState([]);
  const [saleValidData, setSaleValidData] = useState(null);
  const [totalPrice, setTotalPrice] = useState("");
  const { fetchCartItems } = useCart();
  const { authenticated, partnerGroupName, partnerName, checkTokenExpiry } = useAuth();
  const [itemChanged, setItemChanged] = useState(false);
  const navigate = useNavigate();
  const apiUrl = config.default.apiPath;
  const { languagesToIndex } = enums;
  const formatNumber = (number) => {
    // Convert number to string and split into integer and decimal parts
    const parts = number.toFixed(2).toString().split('.');
    // Replace dot with comma as decimal separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    // Return formatted number
    return parts.join(',');
  };

  async function getCustomerCartItems() {
    const sessionID = Cookies.get('sessionID');
    const selectedPartnerGroup = Cookies.get('selectedPartnerGroup');
    const partnerGroupID = selectedPartnerGroup ? selectedPartnerGroup.partnerGroupID : null;
    // Create headers object
    const headers = {
      'Content-Type': 'application/json',
      'LanguageID': languagesToIndex[i18n.language]
    };

    // Check if the user is authenticated
    if (authenticated) {
      checkTokenExpiry();
      const authToken = Cookies.get('authToken');
      // Add the authentication token to the headers
      headers.Authorization = `Bearer ${authToken}`;
    }

    const url = partnerGroupID
      ? `${apiUrl}/Cart/GetCartItems?sessionID=${sessionID}&partnerGroupID=${partnerGroupID}`
      : `${apiUrl}/Cart/GetCartItems?sessionID=${sessionID}`;

    const response = await fetch(url, {
      method: 'GET',
      headers, // Include the headers in the request
    });

    const data = await response.json();
    setCustomerCartTickets(data.cartItems);
    setCustomerCartFees(data.fees);
    setTotalPrice(data.totalPrice);
  }

  async function checkSaleValidTempCustomerCart() {
    try {
      const sessionID = Cookies.get("sessionID");
      const response = await fetch(
        `${apiUrl}/Cart/CheckSaleValidTempCustomerCart?sessionID=${sessionID}`
      );
      const data = await response.json();


      if (data.success === false) {

        // Check the structure of the response data and adjust accordingly
        if (data.data) {
          setSaleValidData(data.data);
        }
      }
    } catch (error) {
      // Handle any errors that occur during the fetch operation here
      console.error("An error occurred during fetch:", error);
    }
  }

  const onlyChildTickets = customerCartTickets.length > 0 &&
    customerCartTickets.every(ticket =>
      ticket.ticketTypes.every(
        ticketType => ticketType.ticketTypeID === 3 || ticketType.ticketTypeID === 4
      )
    );

  useEffect(() => {
    getCustomerCartItems();
    checkSaleValidTempCustomerCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languagesToIndex[i18n.language]]);

  useEffect(() => {
    if (itemChanged) {
      getCustomerCartItems();
      setItemChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemChanged]);

  async function handleRemoveTicket(cartID) {
    const headers = {
      'Content-Type': 'application/json',
    };

    let partnerGroupID = null;

    if (authenticated) {
      checkTokenExpiry();
      const authToken = Cookies.get('authToken');
      // Add the authentication token to the headers
      headers.Authorization = `Bearer ${authToken}`;
    }

    // Check if selectedPartnerGroup exists in cookies
    const selectedPartnerGroupJSON = Cookies.get('selectedPartnerGroup');
    if (selectedPartnerGroupJSON) {
      const selectedPartnerGroup = JSON.parse(selectedPartnerGroupJSON);
      partnerGroupID = selectedPartnerGroup.partnerGroupID;
    }

    // Construct the URL based on whether the user is authenticated and partnerGroupID exists
    const url = authenticated && partnerGroupID
      ? `${apiUrl}/Cart/DeleteCustomerCartItem/${cartID}?partnerGroupID=${partnerGroupID}`
      : `${apiUrl}/Cart/DeleteCustomerCartItem/${cartID}`;

    const response = await fetch(url, {
      method: "DELETE",
      headers, // Include the headers in the request
    });

    if (response.ok) {
      setItemChanged(true);
      fetchCartItems();
    }
  }
  function handleBuyMore() {
    navigate("/buy-ticket/")
  }
  function handleBuyReturnTicket(ticket) {
    var ticketTypes = JSON.stringify(ticket.ticketTypes.map(n=> ({ ticketTypeID: n.ticketTypeID, quantity: n.quantity})));
    var search = { areaID : ticket.areaID, departureStopID: ticket.arrivalStopID, arrivalStopID: ticket.departureStopID, ticketTypes: ticketTypes };
    navigate(`/buy-ticket/${ticket.areaID}?search=${btoa(JSON.stringify(search))}`);
  }
  async function handleContinue() {
    const sessionID = Cookies.get('sessionID');;
    //CHECK SALE VALID
    const response = await fetch(
      `${apiUrl}/Cart/CheckSaleValidTempCustomerCart?sessionID=${sessionID}`
    )
    const data = await response.json()
    if (data.success === true) {
      navigate("/buy-ticket/informations")
    } else {
      checkSaleValidTempCustomerCart();
      const saleValidError = t("toastmessages:checkSaleValid");
      toast.error(saleValidError)
    }
  }
  return (
    <div className="customer-cart">
      <Helmet>
        <title>{t("cart")} | Disko Line {t("greenland")}</title>
      </Helmet>
      <BuyTicketTabs activeTab={2} />
      <div className="customer-cart-content">
        <h1 className="customer-cart-header">{t("cart")}</h1>
        {onlyChildTickets && (
          <div className="only-child-tickets-message">
            <p>
              {t('buyticket:onlyChildTickets')}
            </p>
          </div>
        )}
        {customerCartTickets.length === 0 ? (
          <div className="empty-cart">
            <p>{t("buyticket:emptyCart")}</p>
            <p>{t("buyticket:findYourNextJourney")} <Link to={"/buy-ticket"}>{t("buyticket:here")}</Link></p>
          </div>
        ) : (
          <div className="customer-cart-form">
            {customerCartTickets.map((ticket) => (
              <div className={ticket.ticketTypes.some(ticketType => saleValidData?.cusomerCartIDs.includes(ticketType.customerCartID)) ? "ticket-container unavailable-ticket" : "ticket-container"} key={ticket.id}>
                <div className="journey-info">
                  <p className="date me-2"> {new Date(ticket.departureDate).toLocaleDateString("da-DK", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                  </p>
                  <div className="me-2">
                    <div className="departure-arrival-names">
                      <p className="departure-stop-name">
                        {ticket.departureStopName}
                      </p>
                      {ticket.arrivalStopName && !ticket.isActivity && (
                        <>
                          <p className="arrival-stop-name">
                          &nbsp;- {ticket.arrivalStopName}
                          </p>
                        </>
                      )}
                    </div>
                    {!ticket.isActivity && ( 
                        <div className="buy-return-ticket" onClick={()=> handleBuyReturnTicket(ticket)}>
                        <FontAwesomeIcon icon={faExchange} /><div className="buy-return-ticket-text">{t('buyticket:buyReturnTicket')}</div>
                      </div>
                      )}
                  </div>
                  <div className="departure-arrival-times">
                      <p className="departure-time">
                        {ticket.departureTime.slice(11, 16)}
                      </p>
                      <p className="divider">-</p>
                      <p className="arrival-time">
                        {ticket.arrivalTime.slice(11, 16)}
                      </p>
                    </div>
                    <p className="total-journey-price">
                      {formatNumber(ticket.totalPrice)} DKK
                    </p>
                </div>
                {ticket.ticketTypes.map((ticketType) => (
                  <div key={ticketType.id} className="ticket-types">
                    <div className="ticket-type-name">
                      {ticketType.quantity}x {ticketType.ticketTypeName}
                      <div className="remove-ticket" onClick={() => handleRemoveTicket(ticketType.customerCartID)}><FontAwesomeIcon title={t('buyticket:remove')} icon={faTrash} /> </div>
                    </div>
                    <div>
                      {formatNumber(ticketType.price)} {t('buyticket:dkk/person')}
                    </div>

                  </div>
                ))}

              </div>
            ))}
            {customerCartFees.map((fee) => (
              fee.price > 0 && (
                <div className="fees" key={fee.id}>
                  <p className="fee-name">
                    {fee.name}
                  </p>
                  <p className="fee-price">
                    {formatNumber(fee.price)} DKK
                  </p>
                </div>)
            ))}
            <div className="total-price">
              {t("buyticket:totalPrice")}:
              <p className="price">{formatNumber(totalPrice)} DKK</p>
            </div>
            <div className="continue">
              <button className="btn-secondary" onClick={handleBuyMore}>{t("buyticket:buyMoreTickets")}</button>
              <button className="btn" onClick={handleContinue}>{t("buyticket:continue")}</button>
            </div>
          </div>
        )}
        {authenticated ? <p className="partner-login">{t("buyticket:loggedInAs")} {partnerName} {t("buyticket:of")} {partnerGroupName}</p> : <p className="partner-login">{t("buyticket:loginWith")} <NavLink to="/partner">Partner Login</NavLink></p>}
      </div>
    </div>
  )
}