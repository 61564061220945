import baseService from './BaseService'

let saveLog = async function saveLog(error) {
    try {
        const apiUrl = baseService.getApiUrl();
        const response = await fetch(`${apiUrl}/FrontendLog/SaveFrontendLog`, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({ error: error.stack }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        await response.json();
    } catch (ex) {
        console.error("Error saving frontend log:", ex);
    }
}

var logService = { saveLog }
export default logService;