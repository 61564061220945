import { Helmet } from "react-helmet";
import headerImage from "../../../images/dle-tours/whale-safari/whale-header.jpg";
import inclusionImage from "../../../images/dle-tours/whale-safari/IMG_84425-scaled.jpg";
import video from "../../../videoes/dle-tours/whale-safari.mp4";
import programImage from "../../../images/dle-tours/whale-safari/Icefiord_RAISFOTO_7864.jpg";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function WhaleSafari(){
    const {t} = useTranslation(["common", "diskolineexplorer"]);
    const headerStyle = {
        backgroundImage: `url(${headerImage})`
    }
    const navigate = useNavigate();
    useLayoutEffect(() => {
        // Scroll to the top on load
        window.scrollTo(0, 0); 
      }, []);
      function handleGoBack(){
        navigate("/diskolineexplorer/sailing-trips");
      }
      function handleGoToExplorerArea(){
        navigate("/buy-ticket/93?selectedActivity=172")
      }
    return(
       <div className="diskoline-explorer-tour">
        {/*HEAD*/}
        <Helmet><title>{t('whaleSafari')} | {t('diskoLineExplorer')}</title></Helmet>
        <div className="header">
                    <div className="header-content">
                    <video autoPlay loop webkit-playsinline playsInline>
                    <source src={video} type="video/mp4"></source> 
                    </video>
                    <div className="hero-text">
                        <p className="tagline">Disko Line Explorer</p>
                        <h1>{t('whaleSafari')}</h1>
                    </div>
                    </div>
        </div>
        <div className="content">
                <div className="central_row">
                <div className="breadcrumb"><p><span className="breadcrumb-1" onClick={handleGoBack}>{t('sailingTrips')}</span>/<span className="breadcrumb-2">{t('whaleSafari')}</span></p></div>
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('whaleSafari')}</h2>
                    <p><b>{t('dle:whaleSafariSubHeader')}</b></p>
                    <p>{t('dle:whaleSafariDescription')}</p>
                    <br></br>
                    <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>
                    </div>
                <div className="row">
                    <div className="section">
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dle:tripIncludes')}</h2>
                    <ul>
                        <li>{t('dle:whaleSafariInclusion1')}</li>
                        <li>{t('dle:whaleSafariInclusion2')}</li>
                        <li>{t('dle:whaleSafariInclusion3')}</li>
                        <li>{t('dle:whaleSafariInclusion4')}</li>
                        <li>{t('dle:whaleSafariInclusion5')}</li>
                    </ul>
                    <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>

                    </div>
                    <div className="section">
                        <img src={inclusionImage} alt="Disko Line Explorer" className="img"/>

                    </div>
                </div>
        </div>
            <div className="header header-shadow" style={headerStyle}></div>
        <div className="content">
                <div className="row">
                    <div className="section">
                        <img src={programImage} alt="Disko Line Boat" className="img"/>
                    </div>
                    <div className="section">
                        <p className="tagline">Disko Line Explorer</p>
                        <h2>{t('dle:whaleSafariHeader2')}</h2>
                        <p>{t('dle:whaleSafariDescription2.1')}</p>
                        <br></br>
                        <p>{t('dle:whaleSafariDescription2.2')}</p>
                        <br></br>
                        <p>{t('dle:whaleSafariDescription2.3')}</p>
                        <br></br>
                        <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>
                    </div>
                </div>
                <div className="practical-info">
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dle:practical')} </h2>
                    <br></br>
                    <p><span className="bold">{t('dle:duration')}</span> {t('dle:whaleSafariDuration')}</p>
                    <p><span className="bold">{t('dle:season')}</span> {t('dle:whaleSafariSeason')}</p>
                    <p><span className="bold">{t('dle:note')}</span> {t('dle:whaleSafariNote')} </p>
                    <br>
                    </br><button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')}</button>
        
                </div>
        </div>
       </div>
    )
}