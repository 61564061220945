import { Helmet } from "react-helmet";
//Images
import headerImage from "../../images/headers/csr-header.jpg";
import { useTranslation } from "react-i18next";
export default function CSRPolicy(){
    const {t} = useTranslation(["common", "about"]);
    const headerStyle = {
        backgroundImage: `url(${headerImage})`
    }
    return(
        <div className="csrpolicypage" >
            <Helmet>
                <title>{t('about:aboutCSRPolitik')} | Disko Line Greenland</title>
            </Helmet>
            <div className="header" style={headerStyle}>
                <div className="header-content">
                    <p>Disko Line</p>
                    <h1>{t('about:aboutCSRPolitik')}</h1>
                </div>
            </div>
            <div className="content">
                <div className="section">
                    <h2>{t('about:aboutCSRPolitik')}</h2>
                    <h3>{t('about:aboutCSRValues')}</h3>
                        <p>{t('about:aboutCSRValues1')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRValues2')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRValues3')}</p>
                        <p>{t('about:aboutCSRValues4')}</p>
                    <h3>{t('about:aboutCSRCommitment')}</h3>
                        <p>{t('about:aboutCSRCommitment1')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCommitment2')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCommitment3')}</p>
                        <p>{t('about:aboutCSRCommitment4')}</p>
                        <br></br>
                        <ul>
                            <li><p>{t('about:aboutCSRCommitment5')}</p></li>
                            <li><p>{t('about:aboutCSRCommitment6')}</p></li>
                            <li><p>{t('about:aboutCSRCommitment7')}</p></li>
                            <li><p>{t('about:aboutCSRCommitment8')}</p></li>
                            <li><p>{t('about:aboutCSRCommitment9')}</p></li>
                            <li><p>{t('about:aboutCSRCommitment10')}</p></li>
                            <li><p>{t('about:aboutCSRCommitment11')}</p></li>
                            <li><p>{t('about:aboutCSRCommitment12')}</p></li>
                            <li><p>{t('about:aboutCSRCommitment13')}</p></li>
                            <li><p>{t('about:aboutCSRCommitment14')}</p></li>
                        </ul>
                    <h3>{t('about:aboutCSRHumanRights')}</h3>
                        <p>{t('about:aboutCSRHumanRights1')}</p><br></br>
                        <p>{t('about:aboutCSRHumanRights2')}</p><br></br>
                        <p>{t('about:aboutCSRHumanRights3')}</p>
                    <h3>{t('about:aboutCSRLabour')}</h3>
                        <p>{t('about:aboutCSRLabour1')}</p><br></br>
                        <ul>
                            <li>{t('about:aboutCSRLabour2')}</li>
                            <li>{t('about:aboutCSRLabour3')}</li>
                            <li>{t('about:aboutCSRLabour4')}</li>
                        </ul>
                        <br></br>
                        <p>{t('about:aboutCSRLabour5')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRLabour6')}</p>
                    <h3>{t('about:aboutCSREnvironment')}</h3>
                        <p>{t('about:aboutCSREnvironment1')}</p>
                        <br></br>
                        <p>{t('about:aboutCSREnvironment2')}</p>
                        <br></br>
                        <p>{t('about:aboutCSREnvironment3')}</p>
                        <br></br>
                        <p>{t('about:aboutCSREnvironment4')}</p>
                        <br></br>
                        <ul>
                            <li>{t('about:aboutCSREnvironment5')}</li>
                            <li>{t('about:aboutCSREnvironment6')}</li>
                            <li>{t('about:aboutCSREnvironment7')}</li>
                        </ul>
                        <br></br>
                        <p>{t('about:aboutCSREnvironment8')}</p>
                    <h3>{t('about:aboutCSRAntiCorruption')}</h3>
                        <p>{t('about:aboutCSRAntiCorruption1')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRAntiCorruption2')}</p>
                        <br>
                        </br><p>{t('about:aboutCSRAntiCorruption3')}</p>
                    <h3>{t('about:aboutCSRCompliance')}</h3>
                        <p>{t('about:aboutCSRCompliance1')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCompliance2')}</p>
                </div>
                 {/* ADFÆRDSKODEKS */}
                <hr></hr>
                <div className="section">
                    <h2>{t('about:aboutCSRCodeofConduct')}</h2>
                    <h4>{t('about:aboutCSRCodeofConducttext')}</h4>
                    <h3>{t('about:aboutCSRCodeofConductIntro')}</h3>
                        <p>{t('about:aboutCSRCodeofConductIntro1')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCodeofConductIntro2')}</p>
                    <h3>{t('about:aboutCSRCodeofConductOur')}</h3>
                        <p>{t('about:aboutCSRCodeofConductOur1')}</p>
                    <h3>{t('about:aboutCSRCodeofConductEnvironment')}</h3>
                        <p>{t('about:aboutCSRCodeofConductEnvironment1')}
                        </p><br></br>
                        <p>{t('about:aboutCSRCodeofConductEnvironment2')}</p>
                    <h3>{t('about:aboutCSRCodeofConductHealth')}</h3>
                        <p>{t('about:aboutCSRCodeofConductHealth1')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCodeofConductHealth2')}</p>
                    <h3>{t('about:aboutCSRCodeofConductLabour')}</h3>
                        <p>{t('about:aboutCSRCodeofConductLabour1')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCodeofConductLabour2')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCodeofConductLabour3')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCodeofConductLabour4')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCodeofConductLabour5')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCodeofConductLabour6')}</p>
                    <h3>{t('about:aboutCSRCodeofConductBusiness')}</h3>
                        <p>{t('about:aboutCSRCodeofConductBusiness1')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCodeofConductBusiness2')}</p>
                        <br></br>
                        <p>{t('about:aboutCSRCodeofConductBusiness3')}</p>
                </div>
                <div className="section">
                 <hr></hr>
                    <h2>{t('about:aboutCSRShortVersion')}</h2>
                    <ol>
                        <li>{t('about:aboutCSRShortVersion1')}</li>
                        <li>{t('about:aboutCSRShortVersion2')}</li>
                        <li>{t('about:aboutCSRShortVersion3')}</li>
                        <li>{t('about:aboutCSRShortVersion4')}</li>
                    </ol>
                </div>
            </div>
        </div>
    )
}