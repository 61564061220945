import { Helmet } from "react-helmet";
import headerImage from "../../../images/dle-tours/sermermiut/sermermiut-header.jpg";
import inclusionImage from "../../../images/dle-tours/sermermiut/Icefiord_RAISFOTO_0828.jpg";
import wideImage from "../../../images/dle-tours/sermermiut/sermermiut-wideimage.JPG";
import programImage from "../../../images/dle-tours/sermermiut/sermermiut.jpg";
import { useTranslation } from "react-i18next";
import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SermermiutHike(){
    const {t} = useTranslation(["common", "diskolineexplorer"]);
    const headerStyle = {
        backgroundImage: `url(${headerImage})`
    }
    const navigate = useNavigate();
    useLayoutEffect(() => {
        // Scroll to the top on load
        window.scrollTo(0, 0); 
      }, []);
      function handleGoBack(){
        navigate("/diskolineexplorer/hiking-trips");
      }
      function handleGoToExplorerArea(){
        navigate("/buy-ticket/93?selectedActivity=175")
      }
    return(
       <div className="diskoline-explorer-tour">
        {/*HEAD*/}
        <Helmet><title>{t('sermermiutHike')}| {t('diskoLineExplorer')}</title></Helmet>
        <div className="header header-shadow" style={headerStyle}>
                    <div className="header-content">
                        <p className="tagline">Disko Line Explorer</p>
                        <h1>{t('sermermiutHike')}</h1>
                    </div>
        </div>
        <div className="content">
                <div className="central_row">
                <div className="breadcrumb"><p><span className="breadcrumb-1" onClick={handleGoBack}>{t('hikingTrips')}</span>/<span className="breadcrumb-2">{t('sermermiutHike')}</span></p></div>
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('sermermiutHike')}</h2>
                    <p><b>{t('dle:sermermiutSubHeader')}</b></p>
                    <p>{t('dle:sermermiutDescription')}</p>
                    <br></br>
                    <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>
                    </div>
                <div className="row">
                    <div className="section">
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dle:tripIncludes')}</h2>
                    <ul>
                        <li>{t('dle:sermermiutInclusion1')}</li>
                        <li>{t('dle:sermermiutInclusion2')}</li>
                        <li>{t('dle:sermermiutInclusion3')}</li>
                        <li>{t('dle:sermermiutInclusion4')}</li>
                        <li>{t('dle:sermermiutInclusion5')}</li>
                        <li>{t('dle:sermermiutInclusion6')}</li>
                        <li>{t('dle:sermermiutInclusion7')}</li>
                    </ul>
                    <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>

                    </div>
                    <div className="section">
                        <img src={inclusionImage} alt="Sermermiut from above" className="img"/>

                    </div>
                </div>
        </div>
            <div >
            <img src={wideImage} alt="Sermermiut" className="img video-div"/>
            </div>

        <div className="content">
                <div className="row">
                    <div className="section">
                        <img src={programImage} alt="Disko Line Boat" className="img"/>
                    </div>
                    <div className="section">
                        <p className="tagline">Disko Line Explorer</p>
                        <h2>{t('dle:sermermiutHeader2')}</h2>
                        <p>{t('dle:sermermiutDescription2.1')}</p>
                        <br></br>
                        <p>{t('dle:sermermiutDescription2.2')}</p>
                        <br></br>
                        <p>{t('dle:sermermiutDescription2.3')}</p>
                        <br></br>
                        <button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')} </button>
                    </div>
                </div>
                <div className="practical-info">
                    <p className="tagline">Disko Line Explorer</p>
                    <h2>{t('dle:practical')} </h2>
                    <br></br>
                    <p><span className="bold">{t('dle:duration')}</span> {t('dle:sermermiutDuration')}</p>
                    <p><span className="bold">{t('dle:season')}</span> {t('dle:sermermiutSeason')}</p>
                    <p><span className="bold">{t('dle:note')}</span> {t('dle:sermermiutNote')} </p>
                    <br>
                    </br><button onClick={handleGoToExplorerArea} className="btn">{t('dle:bookTour')}</button>
        
                </div>
        </div>
       </div>
    )
}