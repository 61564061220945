import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../components/AuthContext';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import config from '../../configs/config';


export default function PartnerAccountInformation() {
    const {t} = useTranslation(["common", "partner", "toastmessages"]);
    const {setPartnerName, checkTokenExpiry } = useAuth();
    const [partnerNewName, setPartnerNewName] = useState("");
    const [partnerEmail, setPartnerEmail] = useState("");
    const [partnerAccEmail, setPartnerAccEmail] = useState("");
    const [partnerTel, setPartnerTel] = useState(0);
    const [partnerStayTel, setPartnerStayTel] = useState(0);
    const apiUrl = config.default.apiPath;
    const navigate = useNavigate();


    useEffect(() => {
        async function getCurrentPartnerInformation() {
            checkTokenExpiry();
            const currentSelectedPartner = JSON.parse(Cookies.get("selectedPartnerGroup"));
            const partnerUserID = currentSelectedPartner.partnerUserID;
            const partnerGroupID = currentSelectedPartner.partnerGroupID;
            const response = await fetch(`${apiUrl}/Partner/GetPartnerGroupPartnerUserByIDs/${partnerGroupID}/${partnerUserID}`);
            const data = await response.json();
            setPartnerNewName(data.partnerUser.partnerName);
            setPartnerEmail(data.partnerUser.partnerEmail);
            setPartnerAccEmail(data.partnerUser.partnerAccEmail);
            setPartnerTel(data.partnerUser.partnerTel.toString());
            setPartnerStayTel(data.partnerUser.partnerStayTel);
        }
        getCurrentPartnerInformation();
    }, [checkTokenExpiry, apiUrl]);

    const handleSubmit = async (event) => {
        checkTokenExpiry();
        const authToken = Cookies.get('authToken');
        event.preventDefault();
        const currentSelectedPartner = JSON.parse(Cookies.get("selectedPartnerGroup"));
            const partnerUserID = currentSelectedPartner.partnerUserID;
            const partnerGroupID = currentSelectedPartner.partnerGroupID;
        // Create request body for API call
       try { const requestBody = {
            id: partnerUserID,
            partnerName: partnerNewName,
            partnerEmail: partnerEmail,
            partnerAccEmail: partnerAccEmail,
            partnerPassword: "",
            partnerTel: partnerTel,
            partnerStayTel: partnerStayTel,
            partnerGroupID: partnerGroupID,
        };
        const updateResponse = await fetch(`${apiUrl}/Partner/UpdatePartnerUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(requestBody),
            credentials: 'include'
        });

        if (updateResponse.ok) {
            setPartnerName(requestBody.partnerName)
            navigate('/partner');  
            const changesSucces = t("toastmessages:changesSaved");
            toast.success(changesSucces);
        } else {
            const changesNoSucces = t("toastmessages:changesNotSaved");
            toast.error(changesNoSucces);
        }
    } catch (error) {
        console.error("An error occurred while saving changes:", error);
        const errorOccured = t("toastmessages:errorTryAgain");
          toast.error(errorOccured);
    }
};
    return (
        <div className="partneraccountinformationpage">
            <Helmet>
                <title>{t("partner:accountInformation")} | Disko Line {t("greenland")}</title>
            </Helmet>
            <h1>{t("partner:accountInformation")}</h1>
            <form onSubmit={handleSubmit}>
                <div className='flexed-form'>
                <div className='column-1'>
                <label>
                    <div className='label-and-input'>
                    Email:
                    <input disabled type="text" value={partnerEmail} className='disabled' onChange={(e) => setPartnerEmail(e.target.value)} />
                </div>
                </label>
                <label>
                    <div className='label-and-input'>
                    {t("partner:name")}:
                    <input type="text" value={partnerNewName} onChange={(e) => setPartnerNewName(e.target.value)} />
                    </div>
                </label>
                </div>
                <div className='column-2'>
                <label>
                <div className='label-and-input'>
                    {t("partner:secondary")} Email:
                    <input type="text" value={partnerAccEmail} onChange={(e) => setPartnerAccEmail(e.target.value)} />
                </div>
                </label>
                <label>
                 <div className='label-and-input'>
                 {t("partner:telephone")}:
                 <PhoneInputWithCountrySelect value={partnerTel} onChange={(e) => setPartnerTel(e)} 
                    countryOptionsOrder={['GL', 'DK', 'US', '|', '...']}/>
                 </div>
                </label>
                <label>
                <div className='label-and-input'>
                    {t("partner:stay")} {t("partner:telephone")}:
                    <PhoneInputWithCountrySelect value={partnerStayTel} onChange={(e) => setPartnerStayTel(e)} 
                    countryOptionsOrder={['GL', 'DK', 'US', '|', '...']}/>
                </div>
                </label>
                </div>
                </div>
                <div className="buttons">
                <button onClick={()=> navigate("/partner")} className="btn-secondary">{t("partner:backToMyAccount")}</button>
                <button type="submit" className='btn'>{t("partner:saveChanges")}</button>
            </div>
            </form>
        </div>
    );
}
