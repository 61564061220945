import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from '../../configs/config';
import enums from '../../commons/Enums'

export default function PublicTimetable() {
   const { t, i18n } = useTranslation(["common", "buyticket"]);
   const [areas, setAreas] = useState([]);
   const [globalSetup, setGlobalSetup] = useState([]);
   const navigate = useNavigate();
   const apiUrl = config.default.apiPath;
   const { languagesToIndex } = enums;
   useEffect(() => {
      fetch(`${apiUrl}/Area/GetAreas`)
         .then((response) => response.json())
         .then((data) => {
            setAreas(data);
         })
         .catch((err) => {
            console.log(err.message);
         });
   }, [apiUrl]);
   useEffect(() => {
      fetch(`${apiUrl}/System/GetGlobalSetup`)
         .then((response) => response.json())
         .then((data) => {
            setGlobalSetup(data);
         })
         .catch((err) => {
            console.log(err.message);
         });
   }, [apiUrl])
   function setImageUrl(imgUrl) {
      const ImagePath = globalSetup.hostName + imgUrl;
      return ImagePath;
   }
   function navigateToArea(areaId) {
      navigate(`/practical-information/timetable/${areaId}`);
   }
   return (
      <div className="timetablepage">
         {/*Head*/}
         <Helmet>
            <title>{t("buyTicket")} | {t("diskoLine")} {t("greenland")}</title>
         </Helmet>
         {/*Body*/}
         <div className="select-area-header">
            <h2 className="diskoline-name">{t("diskoLine")}</h2>
            <h2 className="timetable-title">{t("buyticket:selectArea")}</h2>
            <p className="select-area-description">{t("practicalinfo:selectAreaDescription")}</p>
         </div>
         <div className="area-container">
            {areas.map((area) => {
               return (
                  <div className="area-card" key={area.id}>
                     <div className="area-img">
                        <img src={setImageUrl(area.imgUrl)} alt={area.areaTranslations[languagesToIndex[i18n.language]]?.areaName} />
                        <h2 className="area-name">{area.areaTranslations[languagesToIndex[i18n.language]]?.areaName}</h2>
                        <div className="area-img-overlay" onClick={() => navigateToArea(area.id)}>
                        </div>
                     </div>
                  </div>
               );
            })}
         </div>

      </div>
   )
}